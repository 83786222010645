import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'shared-confirm-modal',
  templateUrl: './alerte-modal.component.html',
  styleUrls: ['./alerte-modal.component.scss']
})
export class AlerteModalComponent {

 
  constructor( 
    private dialogRef: MatDialogRef<AlerteModalComponent>, 
    private dialog: MatDialog, 
    private router: Router,
  ) { }

  close(): void {
    this.dialogRef.close();
  }

}
