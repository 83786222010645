import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AuthService } from 'src/app/services/user/auth.service';
import { BookingAddressData } from 'src/app/models/booking/address-data.model';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { Routes } from 'src/app/constants/routes.constant';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { Injectable } from '@angular/core';
import { AccountService } from 'src/app/services/user/account.service';

@Injectable({
  providedIn: 'root',
})
export class AddressBase {
  step: BookingStepsEnum = BookingStepsEnum.Delivery;
  isAuthenticated: boolean;
  hasAllData: boolean;
  hasInstructions: boolean;
  redirectUrl: string;

  address: BookingAddressData = new BookingAddressData();
  addressForm: FormGroup;
  postCode: string;
  isValid: boolean;
  isLoading: boolean = false;
  error: string;
  test: BookingAddressData;
  store: string;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected blancCommandService: BlancCommandService,
    protected commandService: CommandService,
    protected authService: AuthService,
    protected storageService: StorageService,
    protected particleService: ParticleService,
    protected bookingStepsService: BookingStepsService,
    protected accountService: AccountService,
    protected accountSettingService: AccountSettingService
  ) {}

  ngOnInit() {
    this.bookingStepsService.setStep(BookingStepsEnum.Delivery);
    this.isAuthenticated = this.authService.isLoggedIn();
    this.hasAllData = false;
    this.store = this.route.snapshot.queryParams.store;
    this.initData();
    this.initRedirectUrl();
  }

  async initData() {
    const adresse = this.storageService.getObject('adresse');
    if (adresse !== undefined && adresse.addressLine1 !== "" ) {
      this.address = new BookingAddressData(
        adresse.addressLine1,
        adresse.addressLine2,
        adresse.town,
        adresse.postcode,
        adresse.instructions,
        adresse.donateBags
      );
    } else {
      const customerDetailResponse = await this.blancCommandService.executeCommand(CommandEnum.CustomerDetail);
      if (!customerDetailResponse['Failed'] && customerDetailResponse['ReturnObject']['address'].length > 0){
          this.address = new BookingAddressData(
            customerDetailResponse['ReturnObject']['address'][0]['address1'],
            customerDetailResponse['ReturnObject']['address'][0]['address2'],
            customerDetailResponse['ReturnObject']['address'][0]['Town'],
            customerDetailResponse['ReturnObject']['address'][0]['postcode']
          );
      }
    }
    this.storageService.saveObject('adresse', this.address);
    this.initAddressForm();
    this.hasAllData = true;

    // this.hasInstructions = this.address.instructions?.deliverToName.length > 0;
    this.hasInstructions = (this.address.instructions?.deliverToName) ?true:false;
    return this.address;
  }

  initAddressForm(): void {
    let postCode;
    if (this.address.postcode !== null) {
      postCode = this.address.postcode;
    } else {
      if (this.storageService.getPostcode()) {
         postCode = this.storageService.getPostcode();
      } else {
        postCode = '';
      }
     
    }

    if (this.store ||(this.router.url == Routes.DELIVERY_ONLY.ADDRESS)) {
      this.addressForm = new FormGroup({
        addressLine1: new FormControl(this.address.addressLine1, [
          Validators.required,
          Validators.maxLength(50),
        ]),
        addressLine2: new FormControl(this.address.addressLine2, [
          Validators.maxLength(50),
        ]),
        town: new FormControl(this.address.town, [
          Validators.maxLength(50),
        ]),
        postalCode: new FormControl(
          { value: postCode, disabled: false },
          [Validators.maxLength(20)]
        ),
      });
    } else {
      this.addressForm = new FormGroup({
        addressLine1: new FormControl(this.address.addressLine1, [
          Validators.required,
          Validators.maxLength(50),
        ]),
        addressLine2: new FormControl(this.address.addressLine2, [
          Validators.maxLength(50),
        ]),
        town: new FormControl(this.address.town, [
          Validators.maxLength(50),
        ]),
        postalCode: new FormControl(
          { value: postCode, disabled: true },
          [Validators.required, Validators.maxLength(20)]
        ),
      });
    }
    
    this.isValid = this.addressForm.status === FormStatusEnum.Valid;

    this.addressForm.statusChanges.subscribe((status) => {
      this.isValid = status === FormStatusEnum.Valid;
    });
  }

  getFormField(fieldName: string): AbstractControl {
    return this.addressForm.get(fieldName.toString());
  }

  onFormSubmit(): void {
    this.address = this.storageService.getObject('adresse');
    this.address.addressLine1 = this.getFormField('addressLine1').value,
    this.address.addressLine2 = this.getFormField('addressLine2').value,
    this.address.postcode = this.getFormField('postalCode').value,
    this.address.town = this.getFormField('town').value,

    this.storageService.saveObject('adresse', this.address);
    if (this.isAuthenticated) {
      const customerDetails =  this.commandService.executeCommand(CommandEnum.CustomerDetail).then((resp) => {
        const add = {
          AddressID: '',
          address1: this.getFormField('addressLine1').value,
          address2: this.getFormField('addressLine2').value,
          postcode: this.getFormField('postalCode').value,
          longitude: '',
          Latitude: '',
          County: '',
          Country: '',
          Town: this.getFormField('town').value.length !== 0 ? this.getFormField('town').value : 'London',
          name: ''
        };
        resp.ReturnObject.address = [add];
        this.commandService.executeUpdate(CommandEnum.SaveCustomer, resp.ReturnObject)
        .then((resp) => {
          this.isLoading = true;
        });
      });
    }
    //this.router.navigate([this.redirectUrl]);
    const lastItem = this.router.url.substring(this.router.url.lastIndexOf("/"));
    console.log('lastItem',lastItem)
    if (this.store) {
      const customerDetails =  this.commandService.executeCommand(CommandEnum.CustomerDetail).then((resp) => {
        const add = {
          AddressID: '',
          address1: this.getFormField('addressLine1').value,
          address2: this.getFormField('addressLine2').value,
          postcode: this.getFormField('postalCode').value,
          longitude: '',
          Latitude: '',
          County: '',
          Country: '',
          Town: this.getFormField('town').value.length !== 0 ? this.getFormField('town').value : 'London',
          name: ''
        };
        resp.ReturnObject.address = [add];
        this.commandService.executeUpdate(CommandEnum.SaveCustomer, resp.ReturnObject).then((resp) => {
          this.isLoading = true;
          this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT ],{ queryParams: { store: this.store } });
        });
      });
      
    } else {
      if(lastItem == "/address"){
        this.commandService.executeCommand(CommandEnum.CustomerDetail).then((resp) => {
        console.log('this.router.urldddddddddddd',this.router.url)
        if(this.router.url == Routes.DELIVERY_ONLY.ADDRESS ){
          console.log('eeee')
          this.commandService.executeCommand(CommandEnum.GetCustomerCard )
          .then((response) => {
            if(!response.ReturnObject.card || (response.ReturnObject.card && response.ReturnObject.card?.Actif == '0')){
              this.router.navigate([Routes.DELIVERY_ONLY.PAYMENT], {
                queryParams: { paymentStripe: 'add' },
              });
            } else {
              this.router.navigate([Routes.DELIVERY_ONLY.SLOT]);
            }
            
          })
          .catch((err) => console.log(err));
        }else if(resp.ReturnObject.infoCustomer.OnAccount == 1){
          this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
        }else {
          this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT]);
        }
      }).catch((err) => console.log(err));
      }else if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
      }
      else{
        this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
      }
    }
    

  }

  form(): void{
    // 
    const lastItem = this.router.url.substring(this.router.url.lastIndexOf("/"));
    if (this.store) {
      this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT ],{ queryParams: { store: this.store } });
    } else {
      if(lastItem == "/address"){
        
        if(this.router.url == Routes.DELIVERY_ONLY.ADDRESS ){
          
          this.commandService.executeCommand(CommandEnum.GetCustomerCard )
          .then((response) => {
            if(!response.ReturnObject.card || (response.ReturnObject.card && response.ReturnObject.card?.Actif == '0')){
              this.router.navigate([Routes.DELIVERY_ONLY.PAYMENT], {
                queryParams: { paymentStripe: 'add' },
              });
            } else {
              this.router.navigate([Routes.DELIVERY_ONLY.SLOT]);
            }
            
          })
          .catch((err) => console.log(err));
        }else{
          this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT]);
        }
          
      } else if (this.redirectUrl) {
        this.router.navigate([this.redirectUrl]);
      }
      else{
        this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
      }
    }

  }

  initRedirectUrl() {
    const redirectQueryParam = this.route.snapshot.queryParams['redirect'];
    if (this.store) {
      this.router.navigate([Routes.BOOKING_REGULAR.ADDRESS], { queryParams: { store: this.store } });
      this.redirectUrl = Routes.BOOKING_REGULAR.ADDRESS + '?store' + this.store;
    } else {
      this.redirectUrl = redirectQueryParam
        ? redirectQueryParam
        : Routes.BOOKING_REGULAR.SUMMARY;
    }
  }

  redirectToSignPage() {
    if (this.store) {
      this.router.navigate(['/welcome']);
    } else {
      const path = '/booking/address';
      this.router.navigate(['/welcome'], { queryParams: { redirect: path } });
    }
    
  }
}
