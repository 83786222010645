import { Component, OnInit } from '@angular/core';
import { PersonalInfoModalComponent } from '../personal-info-modal/personal-info-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-check-card-modal',
  templateUrl: './check-card-modal.component.html',
  styleUrls: ['./check-card-modal.component.scss']
})
export class CheckCardModalComponent {
  isMobile: boolean;

  constructor(
    private deviceService: DeviceDetectorService,
    public dialogRef: MatDialogRef<PersonalInfoModalComponent>,
    private router: Router,
  ) {
      this.isMobile = this.deviceService.isMobile();
   }

  redirectToPersonalInfo() {
    this.close();

    let navigationExtras: NavigationExtras = {
      queryParams: { 'redirect': Routes.USER.RECURRING },
    };
    if (this.isMobile){
      this.router.navigate([Routes.USER.PAYMENTS], navigationExtras);
    }else {
      this.router.navigate([Routes.USER.PERSONAL_INFO], navigationExtras);
    }
    
  }

  close() {
    this.dialogRef.close();
  }

}
