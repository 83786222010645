<div *ngIf="isLoading" class="spinner-wrapper">
    <shared-spinner></shared-spinner>
</div>

<div *ngIf="!isLoading" class="content animation-fade-in">
    <shared-background-rectangle [height]="400"></shared-background-rectangle>

    <order-details-heading [order]="order"></order-details-heading>

    <div class="body">
        <div class="wrapper">
        <!-- <order-gps-tracking-section [order]="order"></order-gps-tracking-section> -->
    
        <order-details-banner-wrapper [order]="order"></order-details-banner-wrapper>
        <order-edit-desktop></order-edit-desktop>
  
        <booking-summary-instructions [services]="services" id="summary-instructions"></booking-summary-instructions>

        <order-details-items-table [order]="order"></order-details-items-table>
    
        <a *ngIf="order.CanBeCancelled && !isRecurring" (click)="cancelOrder()" class="btn secondary-neutral cancel-btn" [id]="cancelOrdeDesktop">Cancel Booking</a>
      </div>
    </div>
</div>