import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'shared-navigation-top',
  templateUrl: './navigation-top.component.html',
  styleUrls: ['./navigation-top.component.scss']
})
export class NavigationTopComponent {
  @Input() title: string;

  constructor(private _location: Location,
    private router: Router,
  ) { }

  redirect() {
    
    localStorage.removeItem('adresse');
    localStorage.removeItem('orderid');
    this.router.navigate([Routes.USER.LANDING]);
    // this._location.back();

  }

}
