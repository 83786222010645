import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/services/user/auth.service';

import { environment } from './../../../environments/environment';

@Component({
  selector: 'shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isDesktop: boolean;
  isAuthenticated: boolean;
  qrCode: string;
  
  constructor(
    private _deviceDetectorService: DeviceDetectorService,
    protected authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.qrCode = environment.qrCode;
    this.isAuthenticated = this.authService.isLoggedIn();
    this.isDesktop = this._deviceDetectorService.isDesktop();
  }

}
