import { AuthorisationsFormDesktopComponent } from 'src/app/user/authorisations/authorisations-desktop/authorisations-form/authorisations-form.component';
import { StorageService } from './../../services/common/storage.service';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from "rxjs/operators";
import { ParticleService } from './../../services/particle/particle.service';
import { Constant } from './../../models/common/constant.model';
import { ParticleTypeEnum } from './../../models/particle/enums/particle-type.enum';
import { OnInit, Output, EventEmitter, Component, ViewChild, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PmsPreferences, Preference } from 'src/app/models/user/preference.model';
import { PmsIdPreference, PmsTitrePreference, PreferenceCategory } from 'src/app/models/user/enums/preference-category.enum';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { User } from 'src/app/models/user/user.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { AuthorisationsFormMobileComponent } from '../authorisations/authorisations-mobile/authorisations-form/authorisations-form.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthorisationsBase } from '../authorisations/authorisations.base';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
@Component({
  template: ''
})
export class PreferencesBase implements OnInit {
  preference:any;
  error: string;
  enumPreferenceCategory: PreferenceCategory;
  submitSubscription: Subscription;
  modalSubscription: Subscription;
  preferences: any[] = [];
  authorizations: any[] = [];
  minor: any[] = [];
  preferencesForm: FormGroup;
  authorisationsForm: FormGroup;
  receiveOffers: boolean;
  smsMarketing: boolean;
  isValid = false;
  showModal: boolean;
  user: any;
  lessons: boolean;
  accountSubscription: any;
  deliveryInstruction: any;
  // dataInstruction: any;
  hasInstruction = false;
  public deliverToOptions: Constant[];
  public deliverToOptionSelected: string;
  public hasAllData: boolean;
  public instructionsForm: FormGroup;
  public confirmInstruction = false;
  dataInstruction: any;
  PmsPreferences: any;
  @Output() outputChanged: EventEmitter<any> = new EventEmitter<any>();
  public instClear: boolean = false;
  @ViewChild(AuthorisationsFormDesktopComponent) childDescktop: AuthorisationsFormDesktopComponent;
  @ViewChild(AuthorisationsFormMobileComponent) childMobile: AuthorisationsFormMobileComponent;
  isMobile: boolean;
  isSaving = false;
  instructions: Instructions;
  formStatusSubscription: Subscription;
  constructor(

    public accountSettingService: AccountSettingService,
    public formBuilder: FormBuilder,
    public router: Router,
    public commandService: CommandService,
    public confirmModalService: ConfirmModalService,
    private authService: AuthService,
    public alertService: AlertService,
    public particleService: ParticleService,
    protected storageService: StorageService,
    public matDialog: MatDialog,
    public deviceService: DeviceDetectorService,

  ) { }

  async ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.initDataUser();
    this.initDeliveryPreference();
     await this.initForm();
     await this.initPreferences();

    this.modalSubscription = this.confirmModalService.$showModal.subscribe((value) => {
      this.showModal = value;
    });

    this.submitSubscription = this.confirmModalService.$submitForm
      .subscribe(value => {
        if (value) {
          this.onFormSubmit();
           this.ngOnDestroy();
        }
      });
  }

  initForm() {
    this.preferencesForm = this.formBuilder.group({
      0: [false, Validators.required],
      1: [false, Validators.required],
      2: [false, Validators.required],
      3: [false, Validators.required],
      4: [false, Validators.required],
      receiveOffers: [false, Validators.required],
      smsMarketing: [false, Validators.required],
    });
  }

    initPreferences() {
    this.commandService.executeCommand(CommandEnum.GetCustomerPreferences)
      .then(response => {
        for (let i = 0; i < response.ReturnObject.length; i++) {
          if ((response.ReturnObject[i].id_preference === 3) || (response.ReturnObject[i].id_preference === 4) ||(response.ReturnObject[i].id_preference === 10) || (response.ReturnObject[i].id_preference === 5)) {
            this.preferences.push(response.ReturnObject[i]);
          }
          if ((response.ReturnObject[i].id_preference === 7) || (response.ReturnObject[i].id_preference === 2)) {
            this.authorizations.push(response.ReturnObject[i]);
          }
          if (response.ReturnObject[i].id_preference === 1){
            this.minor.push(response.ReturnObject[i]);
          }
        }
        if (this.preferences.length === 0) {
          this.populatePreferences();
         
        }
        this.setFormValues();
        this.setCommunicationValue();
    },
    error => {
    });
  }

  initDeliveryPreference() {
    this.commandService.executeCommand(CommandEnum.GetDeliveryPreference).then(
      (response: any) => {
        if (response.message === 'No Delivery Preference found.') {
          this.instructions = this.storageService.getObject('adresse').instructions;
          if (this.storageService.getString('Instruction') != null) {
            this.SaveInstruction();
          }
        }
        else if (response.ReturnObject && (response.ReturnObject.TypeDelivery.length > 0 || response.ReturnObject.OtherInstruction.length > 0) ) {
          this.lessons = true;
        }
      },
      (error) => {
      }
    );
  }

  SaveInstruction() {
    this.instructions = this.storageService.getObject('adresse').instructions;
    const data = {
      TypeDelivery: this.instructions.deliverToName,
      CodeCountry: this.instructions.countryCode,
      PhoneNumber: this.instructions.phoneNumber,
      OtherInstruction: this.instructions.other,
    };
    this.accountSettingService.updateDeliveryPreferences(data).then(
      (response) => {
        this.accountSettingService.GetDeliveryPreferences().subscribe(
          (response: any) => {
            if (response.returnObject.typeDelivery.length > 0) {
              this.lessons = true;
            }
          });
      },
      (error) => {
      }
    );
  }
  
  initDataUser() {
    this.commandService.executeCommand(CommandEnum.CustomerDetail).then(
      (response: any) => {
        if (response) {
          this.user = response;
          this.receiveOffers = response.ReturnObject.infoCustomer.AcceptMarketing;
          this.smsMarketing = response.ReturnObject.infoCustomer.AcceptSMSMarketing;
          if (response.ReturnObject.infoCustomer.AcceptMarketing === 1) {
            this.receiveOffers = true;
          }
          else {
            this.receiveOffers = false;
          }

          if (response.ReturnObject.infoCustomer.AcceptSMSMarketing === 1) {
            this.smsMarketing = true;
          }
          else {
            this.smsMarketing = false;
          }
          this.setCommunicationValue();
        }
      },
      (error) => {
      }

    );
  }

  getuser() {
    this.commandService.executeCommand(CommandEnum.CustomerDetail).then((user) => {
      this.user = user
      this.receiveOffers = user.receiveOffers
      this.smsMarketing = user.AcceptSMSMarketing
    });
  }

  getFormField(fieldName: string) {
    return this.preferencesForm.get(fieldName.toString());
  }

  onFormSubmit() {
    if (!this.isValid) {
      return;
    }
    this.confirmModalService.setShowModal(false );
    this.SaveInstructionFrom();
    const data = this.preferences.map((preference, index) => {
      const selected = this.preferencesForm.get(index.toString()).value;
      const categoryStr = index;
      const category = PreferenceCategory[categoryStr];
      return { ...preference, selected, category };
    });
    console.log('data', data)
    console.log(this.isSaving);
    let InfoCustomerPreference = [];
    var PmsValue;
    for (let i = 0; i < data.length; i++) {
      PmsValue = '0';
      if (data[i].selected === true) {
        PmsValue = '1';
      } else if (data[i].selected === false) {
        PmsValue = '0';
      }
      this.PmsPreferences = {
        id_preference: PmsIdPreference[data[i].category],
        Titre: PmsTitrePreference[data[i].category],
        Value: PmsValue
      };
      InfoCustomerPreference.push(this.PmsPreferences);
    }
    let a = this.isMobile ? this.childMobile.getData() : this.childDescktop.getData();
    for (let i = 0; i < a.length; i++) {
      InfoCustomerPreference.push(a[i]);
    }
    let x;
    if(this.preferencesForm.get('4').value === true){
      x = {
        id_preference: 1,
        Titre: "Proceed with minor repairs",
        Value: "Always up to £8"
      };
    }else{
      x = {
        id_preference: 1,
        Titre: "Proceed with minor repairs",
        Value: "Never"
      };
  }
  InfoCustomerPreference.push(x);
  var pref = {
    InfoCustomerPreference: InfoCustomerPreference
  }

  this.commandService.executeUpdate(CommandEnum.SaveCustomerPreference, pref).then(
      (response) => {
      },
      (error) => { }
    );

    this.commandService.executeCommand(CommandEnum.CustomerDetail).then((user) => {
      this.receiveOffers = user.receiveOffers
      this.smsMarketing = user.AcceptSMSMarketing
      const nweletters = this.preferencesForm.get('receiveOffers').value;
      const smsMarketing_val = this.preferencesForm.get('smsMarketing').value;
      if (nweletters === false) {
        user.ReturnObject.infoCustomer.AcceptMarketing = 0;
      }
      else if (nweletters === true) {
        user.ReturnObject.infoCustomer.AcceptMarketing = 1;
      }
      if (smsMarketing_val === false) {
        user.ReturnObject.infoCustomer.AcceptSMSMarketing = 0;
      }
      else if (smsMarketing_val === true) {
        user.ReturnObject.infoCustomer.AcceptSMSMarketing = 1;
      }
      this.commandService.executeUpdate(CommandEnum.SaveCustomer, user.ReturnObject).then(
        (response) => {
          this.router.navigate(['/']);
        },
        (error) => {
        }
      );
    });
    // if (!this.isSaving){
       
    // }
    // this.accountSettingService.updatePreferences(data).subscribe(
    //   (response) => {
    //     this.router.navigate(['/']);
    //   },
    //   (error) => {
    //     this.alertService.showError();
    //     console.log(error);
    //   }
    // );
    
  }

  setFormValues() {
   
    
    const data = this.preferences.map((preference, index) => {
      
      if (this.preferences[index].Value === "Yes") {
        this.preferencesForm.get(String(index)).setValue(true);
      }
      if (this.preferences[index].Value === "Always") {
        this.preferencesForm.get(String(index)).setValue(true);
      }
    
    });
    
    for(let i=0; i<this.minor.length;i++){
      if(this.minor[0].id_preference === 1 && this.minor[i].Value === 'Always up to £8'){
        this.preferencesForm.get('4').setValue(true);
      }
    }
    for(let i=0; i<this.preferences.length;i++){
      if(this.preferences[i].id_preference === 3 && this.preferences[i].Value === "Yes"){
        this.preferencesForm.get('0').setValue(true);
      }else if(this.preferences[i].id_preference === 4 && this.preferences[i].Value === "Yes"){
        this.preferencesForm.get('1').setValue(true);
      }else if(this.preferences[i].id_preference === 10 && this.preferences[i].Value === "Yes"){
        this.preferencesForm.get('2').setValue(true);
      }else if(this.preferences[i].id_preference === 5 && this.preferences[i].Value === "Yes"){
        this.preferencesForm.get('3').setValue(true);
      }
    }
    setTimeout(() => {
        this.isValid = this.preferencesForm.status === FormStatusEnum.Valid;
        this.preferencesForm.statusChanges.subscribe((status) => {
          this.isValid = status === FormStatusEnum.Valid;
          this.confirmModalService.setShowModal(this.isValid);
          
        });
    }, 1500);
    
  }

  setCommunicationValue() {
    this.getFormField('receiveOffers').setValue(this.receiveOffers);
    this.getFormField('smsMarketing').setValue(this.smsMarketing);
  }

  populatePreferences() {
    this.preferences = [
      { selected: false, number: 0, category: PreferenceCategory[0] },
      { selected: false, number: 0, category: PreferenceCategory[1] },
      { selected: false, number: 0, category: PreferenceCategory[2] },
      { selected: false, number: 0, category: PreferenceCategory[3] }
    ];
   
  }
  onAddInstruction() {
    this.lessons = true;

  }
  // initdata(){
  //   this.commandService.executeCommand(CommandEnum.OrderAddressInstructionsReadCommand)
  //   .then(resp => {
  //     if (resp.commandResult !== undefined) {
  //       this.addressInstructions = resp.commandResult;
  //     }
  //   })
  //   .catch(err => console.log(err));
  // }

  cancelInstruction() {
    this.hasInstruction = true;
    this.lessons = false;
    //  this.ngOnInit();
  }

  EditInstruction() {
    this.hasInstruction = false;
  }

  confirmInstructions() {
    this.deliveryInstruction = this.instructionsForm.value;
    this.hasInstruction = true;
    if (this.deliveryInstruction.deliverToId)
      this.deliveryInstruction.deliverToName = this.deliverToOptions.find(option => option.value === this.deliveryInstruction.deliverToId).name;
    // this.getInstructionFormField('deliverToId').setValue(this.deliverToOptionSelected);
    const data = {
      TypeDelivery: this.deliveryInstruction.deliverToName,
      CodeCountry: this.deliveryInstruction.countryCode,
      PhoneNumber: this.deliveryInstruction.phoneNumber,
      OtherInstruction: this.deliveryInstruction.other
    };
    this.storageService.saveObject('dataInstruction', data);
    
  /*  this.commandService.executeCommand(CommandEnum.SaveDeliveryPreference, data).then(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );*/
  }

  SaveInstructionFrom() {
    this.dataInstruction = this.storageService.getObject('dataInstruction');
    if (this.dataInstruction) {
      this.commandService.executeCommand(CommandEnum.SaveDeliveryPreference,this.dataInstruction).then(
        (response) => {
          
          // this.ngOnInit();
        },
        (error) => {
        }
      );
    }
 

  }
  canDeactivate(): boolean {
    
    if (this.showModal) {
      this.matDialog.open(ConfirmModalComponent);
      return false;
    }
    return true;
  } 

  ngOnDestroy() {
    this.confirmModalService.setShowModal(false);
    this.confirmModalService.setSubmitForm(false);
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.formStatusSubscription) {
      this.formStatusSubscription.unsubscribe();
    }
  }

}
