import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

import { AuthService } from '../user/auth.service';
import { Routes } from 'src/app/constants/routes.constant';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public authService: AuthService, 
    public router: Router,
    private route: ActivatedRoute,
  ) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {

    if (!this.authService.isLoggedIn()) {
      
      // this.router.navigate([Routes.GUEST.SIGN]);
      if(window.location.href.includes('choose-slots') == true){
        this.router.navigate(['/welcome'], { queryParams: { returnUrl: state.url }});
      }else{
      this.router.navigate([Routes.GUEST.SIGN_IN], { queryParams: { returnUrl: state.url }});
      }
      
      return false;
    }
    return true;
  }
}
