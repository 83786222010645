import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'shared-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

 
  constructor( 
    private dialogRef: MatDialogRef<ConfirmModalComponent>, 
    private confirmModalService: ConfirmModalService,
    private router: Router,
  ) { }

  close(): void {
    this.confirmModalService.setShowModal(false);
    this.dialogRef.close();
    this.router.navigate([Routes.USER.LANDING]);
  }
  
  save(): void {
    this.confirmModalService.setSubmitForm(true);
    this.confirmModalService.setShowModal(false);
    this.dialogRef.close();
   
  }
}
