import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as moment from 'moment';
import { StorageService } from 'src/app/services/common/storage.service';
import { CommandService } from '../common/command.service';
import { PostCodeService } from '../shared/postCode.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CommandResponse } from 'src/app/models/common/command-response.model';
import { IScheduleService } from './contracts/IScheduleService';
import { BlancCommandService } from '../common/blanc-command.service';
import { ActivatedRoute } from '@angular/router';
import { PostcodeModalComponent } from 'src/app/shared/postcode-modal/postcode-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService implements IScheduleService {
  postcode: string;
  postcode1: string;
  redirect: any;

  constructor(
    private http: HttpClient,
    private postCodeService: PostCodeService,
    private storageService: StorageService,
    private commandService: CommandService,
    public dialog: MatDialog,
    private blancCommandService: BlancCommandService,  
  ) {
    this.postcode = this.storageService.getPostcode();
  }

  async getFistAvailable(startDate: moment.Moment) {
    this.postcode  = localStorage.getItem('POSTCODE')
    if(!this.postcode || this.postcode === "undefined") {
      let postcode = await this.blancCommandService.executeCommand(CommandEnum.CustomerDetail);
      this.postcode = postcode['ReturnObject']['address'][0]['postcode'];
      this.storageService.savePostcode(this.postcode);
    }
    var body = {
      fromDate: startDate.format('YYYY-MM-DD'),
      toDate: startDate.format('YYYY-MM-DD'),
      postCode: this.postcode,
    }
    var response = await this.postCodeService.firstAvailableSlot(body);
    return response;
  }

  async slotsForward(date: moment.Moment, days: number, DateSlot: string) {
    this.postcode = localStorage.getItem('POSTCODE');

    if(!this.postcode || this.postcode === "undefined") {
      let postcode = await this.blancCommandService.executeCommand(CommandEnum.CustomerDetail);
      this.postcode = postcode['ReturnObject']['address'][0]['postcode'];
      if (!this.postcode) {
        this.dialog.open(PostcodeModalComponent);
      }
      this.storageService.savePostcode(this.postcode);
    }
    var toDate = moment(date).add(days, 'days');
    var body = {
      fromDate: date.format('YYYY-MM-DD'),
      toDate: toDate.format('YYYY-MM-DD'),
      postCode: this.postcode,
      DateSlot : DateSlot,
    }
    var response = await this.postCodeService.slotsByPeriod(body);
    return response;
  }

  async slotsBackword(date: moment.Moment, days: number ,  DateSlot: string) {
    this.postcode = localStorage.getItem('POSTCODE')
    if(!this.postcode || this.postcode === "undefined") {
      let postcode = await this.blancCommandService.executeCommand(CommandEnum.CustomerDetail);
      this.postcode = postcode['ReturnObject']['address'][0]['postcode'];
      if (!this.postcode) {
        this.dialog.open(PostcodeModalComponent);
      }
      this.storageService.savePostcode(this.postcode);
    }
    var fromDate = moment(date).subtract(days, 'days');
    var body = {
      fromDate: fromDate.format('YYYY-MM-DD'),
      toDate: date.format('YYYY-MM-DD'),
      postCode: this.postcode,
      DateSlot : DateSlot,
    }
    var response = await this.postCodeService.slotsByPeriod(body);
    return response;
  }

  async getSlots(startDate: moment.Moment, endDate: moment.Moment): Promise<CommandResponse> {
    this.postcode  = localStorage.getItem('POSTCODE')
    var body = {
      fromDate: startDate.format('YYYY-MM-DD'),
      toDate: endDate.format('YYYY-MM-DD'),
      postCode: this.postcode,
    }
    var response = await this.postCodeService.slotsByPeriod(body);
    return response;
  }
}
