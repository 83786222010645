import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AddressBase } from '../base/address.base';
import { StorageService } from 'src/app/services/common/storage.service';
import { CommandService } from 'src/app/services/common/command.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { AccountService } from 'src/app/services/user/account.service';
import { Routes } from 'src/app/constants/routes.constant';

@Component({
  selector: 'booking-address-desktop',
  templateUrl: './address-desktop.component.html',
  styleUrls: ['./../address.component.scss', './address-desktop.component.scss']
})
export class AddressDesktopComponent extends AddressBase implements OnInit {
  store: string;
  BookingAddressSave: string;
  constructor(
    router: Router,
    route: ActivatedRoute,
    blancCommandService: BlancCommandService,
    commandService: CommandService,
    
    authService: AuthService,
    storageService: StorageService,
    particleService: ParticleService,
    bookingStepsService: BookingStepsService,
    accountService : AccountService,
    accountSettingService:AccountSettingService

  ) {
    super(router, route, blancCommandService, commandService, authService, storageService, particleService, bookingStepsService , accountService, accountSettingService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store = this.route.snapshot.queryParams.store;
    console.log(this.router.url)
    let verify = localStorage.getItem('first-journey');
    let smsMarketing = localStorage.getItem('smsMarketing');
    if(verify === 'true'){
      if(this.router.url === '/booking/address?redirect=%2Fbooking%2Fsummary'){
        this.BookingAddressSave ="first-journey-booking-redirectToSummary-editAddress-next";
      }else if (this.router.url === '/booking/address'){
        this.BookingAddressSave ="first-journey-booking-Address-next";
      }
     
    }else if( smsMarketing){
      this.BookingAddressSave ="sms-marketing-booking-redirectToSummary-editAddress-next";
    }else{
      this.BookingAddressSave ="returning-user-booking-redirectToSummary-editAddress-next";
    }
  }

  setInstructions(instructions: any):void {
    this.address.instructions = instructions;
  }

  setDonateBags(bags: number): void {
    this.address.donateBags = bags;
  }
  skipStep() {
    this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT ],{ queryParams: { store: this.store } });
  }
}
