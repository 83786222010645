import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';

import { Authorisation } from 'src/app/models/user/authorisation.model';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { User } from 'src/app/models/user/user.model';
import { HttpClient } from '@angular/common/http';
import { PersonalInfo } from 'src/app/models/user/personal-info.model';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { InfoCustomer } from 'src/app/models/user/info-customer.model';
import { Subscription } from 'rxjs';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  template: ''
})
export class AuthorisationsBase implements OnInit {
  authorizations: any[] = [];
  preferences: any[];
  authorisationsForm: FormGroup;
  redirectUrl: string;
  user: User;
  @Output() outputChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() isInBookingProcess: boolean;
  modalSubscription: Subscription;
  submitSubscription: Subscription;
  isValid = false;
  showModal: boolean;

  constructor(
    public commandService: CommandService,
    public http: HttpClient,
    public formBuilder: FormBuilder,
    public confirmModalService: ConfirmModalService,
    public accountSettingService: AccountSettingService,
    public router: Router,
    public alertService: AlertService,
    public matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // if (this.accountSettingService.getLoginPreference()) === false){
    //   updateUser.loginCount = JSON.stringify({ preference: true });
    //   console.log(updateUser);
    //   this.accountSettingService.updateLoginCount(updateUser).subscribe(
    //   (response) => {
    //   },
    //   (error) => { });
    // }
    this.initForm();
    this.initAuthorizations();
    this.modalSubscription = this.confirmModalService.$showModal.subscribe((value) => {
      this.showModal = value;
      console.log( this.showModal);
    });
    this.authorisationsForm.valueChanges.subscribe(val => {
      this.outputChanged.emit({ authorisationsFormChanged: this.authorisationsForm.pristine });
    });
    this.redirectUrl = this.isInBookingProcess ? '/booking/payment' : '/';

    
    this.submitSubscription = this.confirmModalService.$submitForm
      .subscribe(value => {
        if (value) {
          this.populateAuthorizations();
          
          this.ngOnDestroy();
          
        }
      });
    this.authorisationsForm.reset();
  }


  initForm() {
    this.authorisationsForm = this.formBuilder.group({
      0: [false, Validators.required],
      1: [false, Validators.required],
    });

  }

  onFormSubmit() {
    
  }

  initAuthorizations() {
    let  self = this
    this.commandService.executeCommand(CommandEnum.GetCustomerPreferences).then(
      (response: any) => {
        this.preferences = response.ReturnObject;
        for (let i = 0; i < response.ReturnObject.length; i++) {
          if ((response.ReturnObject[i].id_preference === 7) || (response.ReturnObject[i].id_preference === 2)) {
            this.authorizations.push(response.ReturnObject[i]);
          }
        }
        this.setFormValues();
        this.isValid = this.authorisationsForm.status === FormStatusEnum.Valid;
        this.modalSubscription = this.authorisationsForm.statusChanges.subscribe((status) => {
          this.isValid = status === FormStatusEnum.Valid ;
          console.log(this.isValid)
          console.log( this.confirmModalService)
          // this.error = null;
          
        this.confirmModalService.setShowModal(this.isValid );
        //  this.confirmModalService.setSubmitForm(true);
          
          
        })
        if (this.authorizations.length === 0) {
          this.populateAuthorizations();
          
        }
        
      },
      (error) => {
      }
    );
  }

  setFormValues() {
    for (let i = 0; i < this.authorizations.length; i++) {
      if (this.authorizations[0].Value === "Always") {
        this.authorisationsForm.get("1").setValue(true);
      }
      else { this.authorisationsForm.get("1").setValue(false); }
      if (this.authorizations[1].Value === "Always") {
        this.authorisationsForm.get("0").setValue(true);
      } else { this.authorisationsForm.get("0").setValue(false); }
    }
  }

  getData(): any {
    let value;
    let InfoCustomerPreference = [];
    for (let i = 0; i < this.authorizations.length; i++) {
      if ((this.authorizations[i].id_preference === 7) && (this.authorisationsForm.get('0').value === true)) {
        value = "Always"
      }
      if ((this.authorizations[i].id_preference === 2) && (this.authorisationsForm.get('1').value === true)) {
        value = "Always"
      }
      if ((this.authorizations[i].id_preference === 7) && (this.authorisationsForm.get('0').value === false)) {
        value = "Never"
      }
      if ((this.authorizations[i].id_preference === 2) && (this.authorisationsForm.get('1').value === false)) {
        value = "Upon request"
      }
      let PmsPreferences = {
        id_preference: this.authorizations[i].id_preference,
        Titre: this.authorizations[i].Titre,
        Value: value
      };
      InfoCustomerPreference.push(PmsPreferences);
    }
    return InfoCustomerPreference;
  }

  getUser(): Promise<User> {
    if (!this.user) {
      return this.http.get<User>('settings/details').toPromise()
        .then(user => {
          this.user = user;
          return user;
        });
    } else {
      return new Promise<User>((resolve, reject) => resolve(this.user));
    }
  }

  populateAuthorizations() {
    this.authorizations = [
      { id_preference: 2, Titre: "No care labels", Value: "Upon request" },
      { id_preference: 7, Titre: "Cleaning partner", Value: "Never" },
    ];
  }

  canDeactivate(): boolean {
    console.log('hoppa')
    if (this.showModal) {
      this.matDialog.open(ConfirmModalComponent);
      return false;
    }
    return true;
  } 
  ngOnDestroy() {
    this.confirmModalService.setShowModal(false);
    this.confirmModalService.setSubmitForm(false);
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
 