<p class="msg_paused" *ngIf="pause && !recurring.pause && !isMobile">Your recurring booking is paused from {{PauseDateFrom | date : "dd/MM"}} to {{PauseDateTo | date : "dd/MM"}}</p>
<p class="msg_paused" *ngIf="pause && recurring.pause && !isMobile">Your recurring booking will pause from {{PauseDateFrom | date : "dd/MM"}} to {{PauseDateTo | date : "dd/MM"}}</p>
<div *ngIf="!isLoading" class="content animation-fade-in">
  <button (click)="edit()" class="btn secondary edit-btn" *ngIf="!recurring.pause">Edit</button>
  <button class="btn secondary-danger cancel edit-btn" (click)="openCancelDialog()" *ngIf="!recurring.pause && !isMobile">Cancel</button>
  <div class="text-wrapper">
    <p>Your recurring booking is </p>
    <!-- && !recurring.pause -->
    <p *ngIf="! twice " class="green">once a week</p>
    <p *ngIf="twice" class="green">twice a week</p>
    <!-- <p *ngIf="recurring.pause" class="green">paused</p> -->
    <!-- <p  *ngIf="pause && !passed_date " class="green">paused {{PauseDateFrom | date : "dd/MM"}} - {{PauseDateTo | date : "dd/MM"}}</p> -->
  </div>

  <div class="slots-wrapper" [ngClass]="{'two-slots': twice}">
    <div class="slot" >
      <p>{{pickupDayAsString}}s</p>
      <span *ngIf="!isDesktop">{{time}}</span>
      <span *ngIf="isDesktop">{{time}}</span>
    </div>
 
    <div class="slot" *ngIf="twice">
      <p>{{pickupDayAsString2}}s</p>
      <span *ngIf="!isDesktop">{{time2}}</span>
      <span *ngIf="isDesktop">{{time2}}</span>
    </div>
  </div>

  <div *ngIf="!recurring.pause" class="actions">
    <button class="btn secondary-danger cancel" (click)="openCancelDialog()" *ngIf="!recurring.pause && isMobile">Cancel</button>
    <button class="btn primary" (click)="openPauseDialog()" *ngIf="!pause">Pause</button>
    <button class="btn secondary-danger cancel" (click)="resume()" *ngIf="pause && !isMobile">Cancel pause</button>
  </div>
</div>

<div *ngIf="recurring.pause" class="paused-state">
  <div class="overlay"></div>

  <div class="actions">
    <p *ngIf="!isMobile">Your recurring booking is paused.</p>
    <p *ngIf="isMobile">Your recurring booking is paused from {{PauseDateFrom | date : "dd/MM"}} to {{PauseDateTo | date : "dd/MM"}}</p>
    <!-- <button class="btn secondary-danger cancel" (click)="openCancelDialog()">Cancel</button> -->
    <button class="btn primary" (click)="resume()">
      <ng-container *ngIf="!loading">Resume</ng-container>
      <shared-spinner *ngIf="loading" color="white" ></shared-spinner>
    </button>
  </div>
</div>

<div *ngIf="!recurring.pause" class="paused-state">
  <div class="actions" *ngIf="isMobile && pause">
    <p>Your recurring booking will pause from {{PauseDateFrom | date : "dd/MM"}} to {{PauseDateTo | date : "dd/MM"}}</p>
    <!-- <button class="btn secondary-danger cancel" (click)="openCancelDialog()">Cancel</button> -->
    <button class="btn secondary-danger cancel" (click)="resume()">Cancel pause</button>
  </div>
</div>