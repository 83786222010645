import { StatusOrder } from './../../../models/order/enums/status-order.enum';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { AnyARecord } from 'dns';
import { OrderStatusEnum } from 'src/app/models/order/enums/order-status.enum';

import { OrderDetails } from 'src/app/models/order/order-details.model';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';

@Component({
  selector: 'order-details-items-table',
  templateUrl: './items-table.component.html',
  styleUrls: ['./items-table.component.scss']
})
export class ItemsTableComponent implements OnInit {

  // @Input() order: OrderDetails;
  @Input() order: any;
  isRecurring: boolean = false;
  isScheduled: boolean = false;
  statusOrder = new StatusOrder;
  credit : any;

  constructor(private elRef: ElementRef, private blancCommandService: BlancCommandService) { }

  async ngOnInit() {
    this.isRecurring =  this.order.Status === 'RECURRING';
    this.isScheduled =  this.order.Status === 'SCHEDULED';

    this.order.items.forEach(item => {
      var allStatus = this.statusOrder.getCurrentStatusOrder();
      item.infoitems_Status=allStatus[item.infoitems_Status.toUpperCase()]?allStatus[item.infoitems_Status.toUpperCase()]:item.infoitems_Status;

    });
    let CustomerDetailBlanc =  await this.blancCommandService.executeCommand(CommandEnum.CustomerDetail);
    this.credit = CustomerDetailBlanc['ReturnObject'].infoCustomer.credit;
  }

}
