import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { MatDialog } from '@angular/material/dialog';
import { ItemModalComponent } from './item-modal/item-modal.component';
import { InstructionModalComponent } from './instruction-modal/instruction-modal.component';
import { Particle } from 'src/app/models/particle/particle.model';
import { ServiceItem } from 'src/app/models/particle/service-item.model';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { ServiceComponent } from '../service.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageService } from 'src/app/services/common/storage.service';

@Component({
  selector: 'booking-service-mobile',
  templateUrl: './service-mobile.component.html',
  styleUrls: ['./service-mobile.component.scss']
})
export class ServiceMobileComponent extends ServiceComponent {

  
  constructor(
    router: Router,
    route: ActivatedRoute,
    particleService: ParticleService,
    dialog: MatDialog,
    commandService: CommandService,
    alertService: AlertService,
    storageService: StorageService,
    deviceDetectorService: DeviceDetectorService
  ) {
    super(router, route, particleService, dialog, commandService, alertService, storageService, deviceDetectorService);
  }

  async ngOnInit(){
    await super.ngOnInit();

    
  }

  //Open Modal after selection fetch additional instructions.
  async viewItems() {
    let modalRef = this.dialog.open(ItemModalComponent, {
      minWidth: '100vw',
      maxWidth: '100vw',
      maxHeight: '70vh',
      width: '100%',
      panelClass: 'modal-wrapper',
      position: { bottom: '0' },
      data: { item: this.serviceModel.parentItem },
    });

    modalRef.afterClosed().subscribe(async (result) => {
      if (result && result.data) {
        let item = new ServiceItem();
        item.selected = result.data;

        let additionalInstructions = await this.fetchAdditionalInstructions(
          item.selected
        );
        item.additionalInstructions = additionalInstructions;

        this.serviceModel.items.push(item);
      }
    });
  }

  viewInstructions = (item: ServiceItem) => {
    let modalRef = this.dialog.open(InstructionModalComponent, {
      minWidth: '100vw',
      maxWidth: '100vw',
      maxHeight: '70vh',
      width: '100%',
      panelClass: 'modal-wrapper',
      position: { bottom: '0' },
      data: {
        item: item.selected,
        selectedInstructions: [...item.instructions],
      },
    });

    modalRef.afterClosed().subscribe((result) => {
      if (result) {
        var element = this.serviceModel.items.find(
          (x) => x.selected === result.item
        );
        if (element) {
          element.instructions = result.selectedInstructions;
        }
      }
    });
  };

  removeItem(item: ServiceItem) {
    this.serviceModel.items.splice(this.serviceModel.items.indexOf(item), 1);
  }

  removeInstruction(item: ServiceItem, instruction: Particle) {
    let selectedItem = this.serviceModel.items[
      this.serviceModel.items.indexOf(item)
    ];
    selectedItem.instructions.splice(
      selectedItem.instructions.indexOf(instruction),
      1
    );
  }

  storeBrand($event: ServiceItem) {
    let item = this.serviceModel.items.find(x => x.selected.id == $event.selected.id)[0];
    item = $event;
  }

}
