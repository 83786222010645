<booking-progress-bar-desktop [step]="step"></booking-progress-bar-desktop>

<shared-spinner *ngIf="!hasAllData"></shared-spinner>

<div *ngIf="hasAllData" class="content summary-desktop animation-fade-in">
    <header>
        <shared-back-button></shared-back-button>
        <h1>Order summary</h1>
        <p>Please check your information carefully and click "Confirm booking" to proceed.</p>
    </header>

    <section *ngIf="pickupSlot && deliverySlot" class="slots box" id="slots">
        <h4>Delivery details</h4>
        <div class="Delivery-details">
            <div class="wrapper">
                <div class="heading">
                    <h4>Pick up</h4>
                    <a routerLink="/booking/slots" [id]="EditPickup" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green"><span>Edit</span></a>
                </div>
                <h4 class="pickup-hour">{{pickupSlot?.from | hourFormatPms}} - {{pickupSlot?.to | hourFormatPms}}</h4>
                <p class="pickup-date">{{pickupSlot?.date | toMoment | dateFormat:dateFormatDesktop}}</p>

            </div>

            <div class="wrapper-delivery">
                <div class="heading">
                    <h4>Delivery</h4>
                    <a routerLink="/booking/slots" [id]="EditDelivery" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green"><span>Edit</span></a>
                </div>
                <h4 class="delivery-hour">{{deliverySlot?.from | hourFormatPms}} - {{deliverySlot?.to | hourFormatPms}}</h4>
                <p class="delivery-date">{{deliverySlot?.date | toMoment | dateFormat:dateFormatDesktop}}</p>

            </div>
        </div>
        <div *ngIf="address" class="Delivery-details" id="address">
            <div class="wrapper">
                <div class="heading">
                    <h4>Address</h4>
                    <a routerLink="/booking/address" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green" [id]="EditAddress"><span>Edit</span></a>
                </div>
                <p class="address-line-1">{{address.addressLine1}}</p>
                <p class="address-line-2">{{address.addressLine2}}</p>
                <p class="address-line-2">{{address.town}} {{address.postcode}}</p>

            </div>

            <div class="wrapper">
                <div class="delevery-instruction">
                    <div class="heading">
                        <h4>Delivery instructions</h4>
                        <a (click)="toggleShown()" class="icon arrow-down" id="toggle-button"></a>
                    </div>
                    <a routerLink="/booking/address" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green" [id]="EditInstructions">
                        <p>Edit</p>
                    </a>
                    <div class="more" class="more animation-slide-down" [ngClass]="{'shown': isShown}">
                        <div *ngIf="hasInstructions" class="instruction">
                            <div class="heading" *ngIf="instructions.other !== ''">
                                <p class="name">Instructions</p>
                            </div>
                            <div class="left" *ngIf="instructions.other !== ''">
                                <p class="item-name black">{{instructions.other}}</p>
                            </div>
                            <div class="heading">
                                <p class="name">Preferred contact</p>
                            </div>
                            <div class="left">
                                <p class="name">Pick up and deliver to</p>
                                <span>{{instructions.deliverToName}}</span>
                            </div>
                            <div class="left" *ngIf="instructions.phoneNumber !== ''">
                                <p class="name">Their phone number</p>
                                <span class="item-name">{{instructions.countryCode}} {{instructions.phoneNumber}}</span>
                            </div>
                        </div>
                        <div *ngIf="!hasInstructions" class="instruction">
                            <p>You haven't added any delivery instructions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="service box">
        <h4>Order details</h4>
        <div class="Order-details">
            <div class="wrapper">
                <p>All items will be carefully examined by our cleaning experts, but you can let us know about any specific instructions you may have.</p>
            </div>
            <div class="wrapper">
                <booking-summary-instructions [services]="services" id="summary-instructions"></booking-summary-instructions>
            </div>
        </div>
    </section>

    <section class="payment box" id="payment">
        <h4>Payment details</h4>
        <div *ngIf="payment" class="Payment-details">
            <div class="wrapper">
                <p>Please note your card will only be charged after we have collected your items and inspected them in our Atelier.</p>
            </div>
            <user-payment-card-view></user-payment-card-view>
        </div>
        <div class="Payment-details">
            <div *ngIf="!Voucher_code ;else tempv" class="promo-code">
                <div *ngIf="!addVoucher ;else temp" class="promo-code">
                    <p (click)="onAddvoucher()">+</p>
                    <span (click)="onAddvoucher()">Add promo code</span>
                </div>
                <ng-template class="booking-voucher" #temp>
                    <booking-voucher-field class="booking-voucher-field"></booking-voucher-field>
                </ng-template>
            </div>
            <ng-template class="booking-voucher" #tempv>
                <booking-voucher-field class="booking-voucher-field" [voucher]="Voucher"></booking-voucher-field>
            </ng-template>
           

            <div class="card-secure">
                <!-- <img src="assets/icons/lock.svg" height="13" width="17">
                <p>Your card details are securely encrypted and stored.</p> -->
            </div>
        </div>
        <div class="btn-booking">
            <button (click)="confirmBooking()" type="submit" [id]="confirmBookingBtn" class="btn primary with-arrow absolute">Confirm booking</button>
        </div>
    </section>


    <!-- <section *ngIf="address" class="address box" id="address">
    <div class="wrapper">
      <h4>Address</h4>
      <p class="address-line-1">{{address.addressLine1}}</p>
      <p class="address-line-2">{{address.addressLine2}}  {{address.postcode}}</p>
      <a routerLink="/booking/address" [queryParams]="{'redirect': '/booking/summary'}" class="btn href green" id="edit-address-btn"><span>Edit</span></a>
    </div>

    <button (click)="confirmBooking()" type="submit" class="btn primary absolute" id="confirm-booking-btn">Confirm booking</button>
  </section> -->
    <!--
  <section *ngIf="payment" class="payment box" id="payment">
    <h4>Payment details</h4>
    <div class="wrapper">
      <p>Please note your card will only be charged after we have collected your items and inspected them in our Atelier.</p>
    </div>
    <div class="wrapper">
    <user-payment-card-view></user-payment-card-view>
    </section>
  </section> -->

    <!-- <section *ngIf="services.length > 0" class="instructions" id="summary-instructions">
    <booking-summary-instructions [services]="services"></booking-summary-instructions>
  </section> -->
</div>
