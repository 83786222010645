<div class="content animation-fade-in">
    <header>
        <h1>Thank you!</h1>
        <p>You should receive an email confirming your booking shortly.</p>
        <button class="btn primary green calendar-btn" *ngIf="!iosAppLogin" id="addBtn" (click)="getSlotsEventData()">Add to
            calendar</button>
        <button class="btn secondary-neutral " (click)="ViewCurrentOrders()">View current orders</button>
    </header>

    <h2>What’s next?</h2>


    <ul class="services-info">
        <li>
            <div class="line grey"></div>
            <span class="dot"></span>
            <div class="image-wrapper">
                <img src="assets/icons/truck-with-bag.svg">
            </div>
            <p>Prepare your bag for collection and make sure to be available during your 2h pickup slot.</p>
        </li>
        <li>
            <span class="dot"></span>
            <div class="image-wrapper">
                <img src="assets/icons/shirt-wth-magnifier.svg" class="image">
            </div>
            <p>Your payment will only be processed after we have examined all items. We will email you an itemised bill.</p>
        </li>
        <li>
            <span class="dot"></span>
            <div class="image-wrapper">
                <img src="assets/icons/truck-with-shirt.svg" class="image">
            </div>
            <p>On the agreed drop off date & time, our driver will deliver your processed items back to you.</p>
        </li>
    </ul>
</div>

<booking-sustainability-section></booking-sustainability-section>

<booking-contact-section *ngIf="isMobile"></booking-contact-section>
<shared-footer *ngIf="!isMobile"></shared-footer>
