<div class="content">
    <shared-background-rectangle></shared-background-rectangle>

    <header>
        <h1>Recurring booking</h1>
        <!-- <p>Why not save yourself some time and let us take the weekly laundry off your to-do list. Let us know the slot that works for you.</p> -->
    </header>

    <div class="box" style="margin-top: 40px;">
        <div *ngIf="isLoading" class="spinner-wrapper">
            <shared-spinner></shared-spinner>
        </div>

        <ng-container *ngIf="!isLoading" [ngSwitch]="state" >
            <user-recurring-form *ngSwitchCase="stateEnum.Form"></user-recurring-form>
            <user-recurring-view *ngSwitchCase="stateEnum.View" [isDesktop]="true"></user-recurring-view>
            <shared-spinner *ngSwitchCase="stateEnum.Loading"></shared-spinner>
        </ng-container>
    </div>
</div>