import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HourFormatPipe } from './hour-format.pipe';
import { PaginatePipe } from './paginate.pipe';
import { ToMomentPipe } from './to-moment.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { PostcodePipe } from './postcode.pipe';
import { HourFormatPmsPipe } from './hour-format-pms.pipe';
import { HourStartFormatPipe } from './hour-start-format.pipe';

@NgModule({
  declarations: [DateFormatPipe, HourFormatPipe, HourFormatPmsPipe , HourStartFormatPipe, PaginatePipe, ToMomentPipe, PostcodePipe],
  imports: [
    CommonModule
  ],
  exports: [ CommonModule, DateFormatPipe, HourFormatPipe, HourFormatPmsPipe, HourStartFormatPipe, PaginatePipe, ToMomentPipe, PostcodePipe],
})
export class PipesModule {}