<div class="custom-table">
    <div class="custom-row heading-row">
        <div class="custom-column status">Status</div>
        <div class="custom-column brand">Brand</div>
        <div class="custom-column type">Item</div>
        <div class="custom-column price">Price</div>
        <!-- <div class="custom-column more"></div> -->
    </div>

    <ng-container *ngIf="isScheduled|| isRecurring">
        <div class="scheduled-info" id="scheduled-state">
            <span>Once your items arrive in our Atelier, they will be individually examined by our processing experts
                and your
                itemised invoice will show here.</span>
        </div>
    </ng-container>

    <ng-container *ngIf="!isScheduled || !isRecurring">
        <div *ngFor="let item of order.items; let i = index" id="not-scheduled-state" class="custom-row">
            <div class="custom-column first-column status">
                <span class="status tag">{{item.infoitems_Status | lowercase}}</span>
            </div>
            <div id="brandNone" *ngIf="item.brand ==='';else elseif" class="custom-column brand">-</div>
            <ng-template #elseif>
                <div class="custom-column brand">{{item.brand}}</div>
            </ng-template>
            <div class="custom-column type">{{item.typeitem}}</div>
            <div class="custom-column price">£{{item.priceTotal| number:'.2-2'}}</div>
            <div *ngIf="item.infoitems_Status == 'Pending' " class="custom-column price"> - </div>
            <!-- <div *ngIf="item.statusAsString == 'Cancelled' " class="custom-column price"> 0 </div> -->
            <!-- <div class="custom-column more">
        <a *ngIf="item.instructions" (click)="this.shown[i] = !this.shown[i]" id="show-instructions-btn"
          class="icon arrow-down" [ngClass]="{'opened': this.shown[i]}"></a>
      </div> -->
            <!-- <div *ngIf="shown[i]" class="custom-row instructions" id="instructions">
        <p>Instructions</p>
        <span *ngFor="let instruction of item.instructions" class="empty tag instruction">{{instruction}}</span>
      </div> -->
        </div>

        <br>
        <hr width="80%" color="#C4C4C4" size="1">
        <div class="detail-order-bloc">
            <div class="custom-info-container">
                <div class="custom-info left detail-order">Sub-total</div>
                <div class="custom-info right detail-order">£{{order.Subtotal| number:'.2-2'}}</div>
            </div>
            <div class="custom-info-container">
                <div class="custom-info left detail-order">Discount</div>
                <div class="custom-info right detail-order">£{{order.AccountDiscount+order.OrderDiscount | number:'.2-2'}}</div>
            </div>
            <br>
            <div class="custom-info-container">
                <div class="left">Total (incl. VAT)</div>
                <div class="right">£{{order.Total| number:'.2-2'}}</div>
            </div>
        </div>
        <hr width="80%" color="#C4C4C4" size="1">
        <div class="detail-order-bloc">           
            <div class="custom-info-container">
                <div class="custom-info left detail-order">Total (excl. VAT)</div>
                <div class="custom-info right detail-order">£{{order.TotalExcVat| number:'.2-2'}}</div>
            </div>
            <br>
            <div class="custom-info-container">
                <div class="custom-info left detail-order">Credit used </div>
                <div class="custom-info right detail-order">£{{credit | number:'.2-2'}}</div>
            </div>
            <br>
            <div class="custom-info-container order-balance">
                <div class=" left" style="font-size:16px">Order Balance To Pay</div>
                <div class=" right" style="font-size:16px">£{{order.TotalDue | number:'.2-2'}}</div>
            </div>
        </div>
    </ng-container>
</div>