export class StatusOrder {
    currentOrder ={ 
        'ASSEMBLING' :'IN PROCESS',
        'FAILED DELIVERY' :'Failed Delivery',
        'IN PROCESS' :'IN PROCESS',
        'CANCELLED' :'CANCELLED',
        'LATE' :'LATE',
        'OFFLOADED' :'IN PROCESS',
        'ON VAN' :'ON VAN',
        'ON VAN - RETURN CH' :'IN PROCESS',
        'ON VAN - RETURN MB' :'IN PROCESS',
        'ON VAN - RETURN NH' :'IN PROCESS',
        'ON VAN - RETURN SK' :'IN PROCESS',
        'READY' :'IN PROCESS',
        'READY IN STORE' :'READY IN STORE',
        'RECURRING' :'SCHEDULED',
        'SCHEDULED' :'SCHEDULED'
    };

    pastOrder = {
        'FULFILLED' : 'FULFILLED',
        'DONATED TO CHARITY' : 'DONATED TO CHARITY',
        'DELIVERED' : 'DELIVERED',
        'ARCHIVED' : 'ARCHIVED'
    }
    getCurrentStatusOrder() {
        return this.currentOrder;
    }
    getPastStatusOrder() {
        return this.pastOrder;
    }
}

