<shared-header [isBooking]="true"></shared-header>
<div class="content animation-fade-in">
    <header>
      <h1>Thank you!</h1>
      <p>You should receive an email confirming your booking shortly.</p>
      <a class="btn primary green calendar-btn" id="addBtn" (click)="getSlotsEventData()">Add to calendar</a>
      <button class="btn secondary-neutral " (click)="ViewCurrentOrders()">View current orders</button>
    </header>

    <ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
        <section class="mobile-version" id="mobile-version">
          <img src="assets/icons/eco.svg">
          <span>Want to find out more about our natural,</span>
          <span>non-toxic cleaning process?</span>
          <span>Read about the benefits of <a href="https://blancliving.co/pages/wetcleaning" target="_blank">wet cleaning</a></span>
          <span>and <a href="https://blancliving.co/pages/liquid-co2-dry-cleaning" target="_blank">liquid CO2 cleaning</a>, and find out why</span>
          <span>conventional dry cleaning is <a href="https://blancliving.co/pages/perc-toxic-drycleaning" target="_blank">neither dry</a></span>
          <span class="mb-20"><a href="https://blancliving.co/pages/perc-toxic-drycleaning" target="_blank">or clean</a>.</span>
          <span><strong>If there is anything we can help with,</strong></span>
          <span><strong>you can check our <a href="https://blancliving.co/pages/faq" target="_blank">FAQ</a> or <a href="mailto:info@blancliving.co">contact us</a>.</strong></span>
        </section>
      </ng-template>


      <ng-template #isDesktop>
        <section class="desktop-version" id="desktop-version">
          <div class="wrapper">
            <img src="assets/icons/eco.svg">

            <div class="text">
              <span>Want to find out more about our natural, non-toxic cleaning process? Read about the benefits of <a href="https://blancliving.co/pages/wetcleaning" target="_blank">wet cleaning</a> and <a href="https://blancliving.co/pages/liquid-co2-dry-cleaning" target="_blank">liquid CO2 cleaning</a>, and find out why conventional dry cleaning is <a href="https://blancliving.co/pages/perc-toxic-drycleaning" target="_blank">neither dry or clean</a>.</span>
              <span><strong>If there is anything we can help with, you can check our <a href="https://blancliving.co/pages/faq" target="_blank">FAQ</a> or <a href="mailto:info@blancliving.co">contact us</a>.</strong></span>
             </div>
          </div>
        </section>
      </ng-template>
  </div>

  <section *ngIf="isMobile" class="contact animation-fase-in">
    <p class="green">Need to talk to us?</p>
    <p>+44 (0)20 8004 2630</p>
    <div class="wrapper">
      <img src="assets/icons/email.svg">
      <p class="email-us"><a href="mailto:info@blancliving.co">Email us</a></p>
    </div>
  </section>

  <shared-footer *ngIf="!isMobile"></shared-footer>

