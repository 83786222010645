<form [formGroup]="preferencesForm" class="box animation-fade-in">
    <p class="green">Preferences</p>
    <ul>
        <li class="preference-category">
            <p class="name">Finish instructions</p>
            <div class="preference-wrapper">
                <div class="bloc-preference">
                    <!-- <span>Shirts with crease </span> -->
                    <span>Shirts premium finish </span>
                    <p class="shorter" *ngIf="preferencesForm.get('0').value">Supplement: £1.30/ shirt
                    </p>
                    <p class="sous_shorter" *ngIf="preferencesForm.get('0').value"> I want all my shirts to be hand-finished (premium) with a crease on the sleeves.
                    </p>
                </div>
                <mat-slide-toggle formControlName="0"></mat-slide-toggle>
            </div>



            <div class="preference-wrapper">
                <div class="bloc-preference">
                    <span>Shirts folded </span>
                    <p class="shorter" *ngIf="preferencesForm.get('1').value">Supplement: £1.20/ shirt
                    </p>
                    <p class="sous_shorter" *ngIf="preferencesForm.get('1').value"> I want all my shirts to be delivered folded in a compostable shirt bag.
                    </p>
                </div>
                <mat-slide-toggle formControlName="1"></mat-slide-toggle>
            </div>

            <div class="preference-wrapper">
                <div class="bloc-preference">
                    <span>Garments folded </span>
                    <p class="shorter" *ngIf="preferencesForm.get('2').value">Supplement: £1.00/ garment
                    </p>
                    <p class="sous_shorter" *ngIf="preferencesForm.get('2').value"> I want all my items to be delivered folded in a reusable bag.
                    </p>
                </div>
                <mat-slide-toggle formControlName="2"></mat-slide-toggle>
            </div>

            <div class="preference-wrapper">
                <div class="bloc-preference">
                    <span class="shorter">Debobbling</span>
                    <p class="shorter" *ngIf="preferencesForm.get('3').value">Supplement: £2 to £10/ item
                    </p>
                    <p class="sous_shorter" *ngIf="preferencesForm.get('3').value"> I want all my coats and jumpers to be debobbled when required, without having to specify it in special instructions.
                    </p>
                </div>
                <mat-slide-toggle formControlName="3"></mat-slide-toggle>
            </div>

                  <div class="preference-wrapper">
        <div class="bloc-preference">
          <span>Minor repairs </span>
          <p class="sous_shorter" *ngIf="preferencesForm.get('4') && preferencesForm.get('4').value">
            I authorise BLANC to proceed with minor repairs up to £8 (such as
            fixing small tears, missing buttons or moth holes) that are not
            mentioned in my instructions.
          </p>
        </div>
        <mat-slide-toggle formControlName="4"></mat-slide-toggle>
      </div>

        </li>
        <user-authorisations-form-desktop (outputChanged)="setAuthorisation($event)"></user-authorisations-form-desktop>

        <li class="preference-category">
            <div *ngIf="!lessons else loading">
                <p class="name">Delivery instructions</p>
                <div class="bloc-preference">
                    <div class="preference-wrapper">
                        <span>You don't have any instructions yet</span>
                    </div>
                </div>

                <button (click)="onAddInstruction()" class="btn secondary-neutral">Add instructions</button>
            </div>
            <ng-template #loading>
                <app-preference-instruction-modal class="instruction-modal" (outputChanged)="setOutput($event)"></app-preference-instruction-modal>
            </ng-template>
        </li>

        <li class="preference-category">
            <p class="name">Communications</p>
            <div class="preference-wrapper">
                <div class="bloc-preference">
                    <span class="shorter">Receive monthly newsletter</span>
                    <p class="shorter">I want to receive the monthly
                    </p>
                    <p class="sous_shorter"> BLANC Edit, including eco-living tips, and exclusive partner offers.
                    </p>
                </div>
                <mat-slide-toggle formControlName="receiveOffers" class="form-control"></mat-slide-toggle>
            </div>
            <div class="preference-wrapper">
                <div class="bloc-preference">
                    <span class="shorter">SMS Marketing</span>
                    <p class="shorter">Customer agrees to receiving Marketing SMS from us.</p>
                </div>
                <mat-slide-toggle formControlName="smsMarketing" class="form-control"></mat-slide-toggle>
            </div>

        </li>
    </ul>

    <button [disabled]="!(!preferencesForm.pristine||!authChanged || (lessons && instIsValid) )|| isSaving" class="btn primary absolute" type="submit" (click)="onFormSubmit()">  
        <ng-container *ngIf="!isSaving">Save</ng-container>
        <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
    </button>
</form>