import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';
import { StorageService } from 'src/app/services/common/storage.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { PostcodeModalComponent } from 'src/app/shared/postcode-modal/postcode-modal.component';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'order-list-current-empty',
  templateUrl: './list-current-empty.component.html',
  styleUrls: ['./list-current-empty.component.scss']
})
export class ListCurrentEmptyComponent implements OnInit {
  @Input() hasOrderHistory: boolean;

  newBooking: string;
  isMobile: any;
  constructor(
    private accountSettingService: AccountSettingService,
    private blancCommandService: BlancCommandService,
    private storage: StorageService,
    private router: Router,
    public dialog: MatDialog,
    protected deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    if (this.deviceService.isMobile()){
      this.newBooking = "menu-mobile-new-booking";
    }else{
      this.newBooking = "returning-user-your-order-new-booking";
    }
    
  }
  

  redirectToBooking() {
    this.blancCommandService.executeCommand(CommandEnum.CustomerDetail)
    .then(response => {
      const address = response['ReturnObject']['address'];
      if (address.length !== 0) {
        if (address[0]['postcode'] === '') {
          this.dialog.open(PostcodeModalComponent);
        } else {
          this.storage.savePostcode(address[0]['postcode']);
          this.router.navigate([Routes.BOOKING_REGULAR.PROVISION]);
          //this.router.navigate([Routes.BOOKING_REGULAR.SLOTS]);
        }
      } else {
        this.dialog.open(PostcodeModalComponent);
      }
    });
  }
}
