import { AuthService } from './../../../../services/user/auth.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit, Inject, Input } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { InstructionsBase } from '../../base/instructions.base';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { SaveInstructionModalComponent } from '../../save-instruction-modal/save-instruction-modal.component';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { BookingAddressData } from 'src/app/models/booking/address-data.model';
import { StorageService } from 'src/app/services/common/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { AddressBase } from '../../base/address.base';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { RequestPickup } from 'src/app/models/spot/requestPickup.model';
import { concat } from 'rxjs';

@Component({
  selector: 'booking-instructions-modal',
  templateUrl: './instructions-modal.component.html',
  styleUrls: ['./instructions-modal.component.scss'],
})
export class InstructionsModalComponent
  extends InstructionsBase
  implements OnInit
{
  addressInstructions: Instructions = new Instructions();
  address: BookingAddressData;
  public commandService: CommandService;
  redirectUrl: string;
  code: string;
  store: string;

  constructor(
    public accountSettingService: AccountSettingService,
    protected particleService: ParticleService,
    protected route: ActivatedRoute,
    protected router: Router,
    // protected dialogRef: MatDialogRef<InstructionsModalComponent>,
    public dialog: MatDialog,
    commandService: CommandService,
    protected storageService: StorageService,
    public formBuilder: FormBuilder,
    protected authService: AuthService
  ) {
    super(
     accountSettingService,
     particleService,
     commandService,
     storageService,
     router,
     formBuilder,
     authService,    
    );
    this.commandService = commandService;
  }

  async ngOnInit() {
    this.store = this.route.snapshot.queryParams.store;
    await super.ngOnInit();
    this.address = this.storageService.getObject('adresse');
    if(this.address){
      if(this.address.instructions.deliverToName.length > 0){
        this.getFormField('deliverToId').setValue(this.address.instructions.deliverToName)
      }else{    
        this.getFormField('deliverToId').setValue('N/A');
      }
      this.address.instructions = this.instructions;
      this.storageService.saveObject('adresse', this.address);
      this.getFormField('other').setValue(this.address.instructions.other)
      this.code = this.address.instructions.countryCode.split('+')[1];
    } else {
      console.log('');
    }

   
  }

  async onFormSubmit() {
    if (!this.isValid) {
      return;
    }
    this.instructions = this.instructionsForm.value;
    this.instructions.deliverToName = this.deliverToOptions.find(
      (option) => option.name === this.instructions.deliverToId
    ).name;
    if (this.store) {
      this.openInstructionsDialogStore(this.instructions);
    } else {
      this.openInstructionsDialog(this.instructions);
    }
  }

  async openInstructionsDialog(instructions) {
    const dialogRef = this.dialog.open(SaveInstructionModalComponent, {
      data: instructions,
    });

    dialogRef.afterClosed().subscribe((preference) => {
      this.hasInstructions = true;
      if (preference) {
        this.storageService.saveString('Instruction', 'PREFERENCES');
        const data = {
          TypeDelivery: this.instructions.deliverToName,
          CodeCountry: this.instructions.countryCode,
          PhoneNumber: this.instructions.phoneNumber,
          OtherInstruction: this.instructions.other,
        };
        this.accountSettingService.updateDeliveryPreferences(data).then(
          (response) => {},
          (error) => {
            console.log(error);
          }
        );
      }
      // if order only
      this.address = this.storageService.getObject('adresse'); 
      this.address.instructions = this.instructions;
      this.storageService.saveObject('adresse', this.address);
      if(localStorage.getItem('orderid')){
        var param = {
          infoOrder_id : +localStorage.getItem('orderid')
        }    
        this.commandService.executeCommand(CommandEnum.GetDeliveryByOrder, param)
          .then(res => {
            let requestPickup = new RequestPickup();
            const pickupDate = res.ReturnObject.PickupDate?.substr(5);
            const year = res.ReturnObject.PickupDate.substr(0, 4);
            const deliveryDate = res.ReturnObject.DeliveryDate.substr(5);
            requestPickup.PickupStartTime = res.ReturnObject.PickupTimeSlot.split('-')[0];
            requestPickup.PickupEndTime = res.ReturnObject.PickupTimeSlot.split('-')[1];
            requestPickup.PickupDate = pickupDate + '-' + year;;
            requestPickup.DeliveryStartTime = res.ReturnObject.DeliveryTimeSlot.split('-')[0];
            requestPickup.DeliveryEndTime = res.ReturnObject.DeliveryTimeSlot.split('-')[1];
            requestPickup.DeliveryDate = deliveryDate + '-' + year;;
            requestPickup.VisitType = 'Both Pickup and Delivery';
            requestPickup.PickupCodeCountry = this.address.instructions.countryCode;
            requestPickup.PickupPhoneNumber = this.address.instructions.phoneNumber
            requestPickup.PickupTypeDelivery = this.address.instructions.deliverToName;
            requestPickup.DeliveryCodeCountry = this.address.instructions.countryCode;
            requestPickup.DeliveryPhoneNumber = this.address.instructions.phoneNumber;
            requestPickup.DeliveryTypeDelivery = this.address.instructions.deliverToName;
            requestPickup.Comments = this.address.instructions.other;
            requestPickup.DeliveryComments =this.address.instructions.other;
            requestPickup.GarmentInstruction = res.ReturnObject.GarmentInstruction ? JSON.stringify(res.ReturnObject.GarmentInstruction) : '';

            this.commandService.executeCommand(CommandEnum.RequestPickup, requestPickup)
              .then(response => {
                if(this.router.url.endsWith('3')){
                  this.router.navigate([Routes.ORDER.DETAILS + response.ReturnObject.infoOrder_id+'/'+'Pick up & Delivery details']);
                }
            
                if(this.router.url.endsWith('5')){
                  this.router.navigate([Routes.BOOKING_REGULAR.ADDRESS]);
                }
              })           
            })
      }
      location.reload();
    })
    if(localStorage.getItem('orderid')){
      var paramDelivry = {
        DeliveryaskID : localStorage.getItem('Deliveryask_ID')
      }
      const suppDelivery = await this.commandService.executeCommand(CommandEnum.DeleteDelivery, paramDelivry);
      var paramPickup = {
        PickupID : localStorage.getItem('Pickup_ID')
      }
      const suppPickup = await this.commandService.executeCommand(CommandEnum.DeletePickup, paramPickup);
    }               
  }
  async openInstructionsDialogStore(instructions) {
    if (instructions) {
      this.storageService.saveString('Instruction' , 'PREFERENCES');
      const data = {
        TypeDelivery: this.instructions.deliverToName,
        CodeCountry: this.instructions.countryCode,
        PhoneNumber: this.instructions.phoneNumber,
        OtherInstruction: this.instructions.other,
      };
      if (this.isAuthenticated){
        this.accountSettingService.updateDeliveryPreferences(data).then(
          (response) => {
            this.router.navigate([Routes.BOOKING_REGULAR.ADDRESS],{ queryParams: { store: this.store } }); 
          },
          (error) => {
          }
        );
      } 
    }
    else {
      console.log('');
    }
  }

  async clearInstruction() {
    let id_order = localStorage.getItem('orderid');
    this.instructions = new Instructions();
    this.address = this.storageService.getObject('adresse');
    this.address.instructions = new Instructions();
    this.hasInstructions = false;

    if(id_order){
      var paramDelivry = {
        DeliveryaskID : localStorage.getItem('Deliveryask_ID')
      }
      const suppDelivery = await this.commandService.executeCommand(CommandEnum.DeleteDelivery, paramDelivry);
      var paramPickup = {
        PickupID : localStorage.getItem('Pickup_ID')
      }
      const suppPickup = await this.commandService.executeCommand(CommandEnum.DeletePickup, paramPickup);
    }
    this.storageService.saveObject('adresse', this.address);
        
    if(id_order){
      var param = { 
        infoOrder_id : +id_order
      }   
      this.commandService.executeCommand(CommandEnum.GetDeliveryByOrder, param)
        .then(res => {  
          let requestPickup = new RequestPickup();
          const pickupDate = res.ReturnObject.PickupDate.substr(5);
          const year = res.ReturnObject.PickupDate.substr(0, 4);
          const deliveryDate = res.ReturnObject.DeliveryDate.substr(5);
          requestPickup.PickupStartTime = res.ReturnObject.PickupTimeSlot.split('-')[0];
          requestPickup.PickupEndTime = res.ReturnObject.PickupTimeSlot.split('-')[1];
          requestPickup.PickupDate = pickupDate + '-' + year;;
          requestPickup.DeliveryStartTime = res.ReturnObject.DeliveryTimeSlot.split('-')[0];
          requestPickup.DeliveryEndTime = res.ReturnObject.DeliveryTimeSlot.split('-')[1];
          requestPickup.DeliveryDate = deliveryDate + '-' + year;;
          requestPickup.VisitType = 'Both Pickup and Delivery';
          requestPickup.PickupCodeCountry = this.address.instructions.countryCode;
          requestPickup.PickupPhoneNumber = this.address.instructions.phoneNumber
          requestPickup.PickupTypeDelivery = this.address.instructions.deliverToName;
          requestPickup.DeliveryCodeCountry = this.address.instructions.countryCode;
          requestPickup.DeliveryPhoneNumber = this.address.instructions.phoneNumber;
          requestPickup.DeliveryTypeDelivery = this.address.instructions.deliverToName;
          requestPickup.Comments = this.address.instructions.other;
          requestPickup.DeliveryComments = this.address.instructions.other;
          requestPickup.GarmentInstruction = res.ReturnObject.GarmentInstruction ? JSON.stringify(res.ReturnObject.GarmentInstruction) : '';

          this.commandService.executeCommand(CommandEnum.RequestPickup, requestPickup)
            .then(response => {
              if(this.router.url.endsWith('3')){
                this.router.navigate([Routes.ORDER.DETAILS + response.ReturnObject.infoOrder_id+'/'+'Pick up & Delivery details']);
              }
              if(this.router.url.endsWith('5')){
                this.router.navigate([Routes.BOOKING_REGULAR.ADDRESS]);
              }
            })
        })
    }
    if (this.store) {
      this.router.navigate([Routes.BOOKING_REGULAR.ADDRESS],{ queryParams: { store: this.store } });
    } else {
      if(this.router.url.endsWith('y')){
        this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
      }
      if(this.router.url.endsWith('address')){
        this.router.navigate([Routes.BOOKING_REGULAR.ADDRESS]);
      }
    }
    
  }
  
  initRedirectUrl() {
    const redirectQueryParam = this.route.snapshot.queryParams['redirect'];
    this.redirectUrl = redirectQueryParam
      ? decodeURIComponent(redirectQueryParam)
      : this.address.addressLine1
      ? Routes.BOOKING_REGULAR.SERVICES_LANDING
      : Routes.BOOKING_REGULAR.ADDRESS;
  }
}
