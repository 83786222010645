import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'user-authorisations',
  templateUrl: './authorisations.component.html',
  styleUrls: ['./authorisations.component.scss'],
})
export class AuthorisationsComponent implements OnInit {
  isMobile: boolean;
  showModal: boolean;
  modalSubscription: Subscription;
  constructor(
    private deviceService: DeviceDetectorService,
    private confirmModalService: ConfirmModalService,
    public matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.modalSubscription = this.confirmModalService.$showModal.subscribe((value) => {
      this.showModal = value;
      console.log(this.showModal)
    });
  }

  canDeactivate(): boolean {
    if (this.showModal) {
      this.matDialog.open(ConfirmModalComponent);
      return false;
    }
    return true;
  } 

  
}
