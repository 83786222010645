import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { ParticleItem } from 'src/app/models/particle/particle-item.model';
import { Guid } from 'guid-typescript';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { ServiceWrapperModel } from 'src/app/models/particle/service-wrapper.model';
import { ServiceViewTypeEnum } from 'src/app/models/booking/enums/service-view-type.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Routes } from 'src/app/constants/routes.constant';
import { CardService } from 'src/app/services/user/card.service';
import { PaymentMethodEnum } from 'src/app/models/booking/enums/payment-method.enum';
import { CardResponse } from 'src/app/models/user/card-response.model';
import { StorageService } from 'src/app/services/common/storage.service';
import { RequestPickup } from 'src/app/models/spot/requestPickup.model';
import * as moment from 'moment';

@Component({
  selector: 'booking-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  isMobile: boolean;

  services: ParticleItem[];
  models: Record<string, ServiceWrapperModel>;
  isLoading: boolean = true;
  backRoute: string = Routes.BOOKING_REGULAR.SUMMARY;
  card: CardResponse;
  isSaving = false;
  editGarment: string;
  redirect: string;
  show_donate = false;
  order_response : any;
  constructor(
    protected particleService: ParticleService,
    protected commandService: CommandService,
    protected storageService: StorageService,
    protected router: Router,
    protected cardService: CardService,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected deviceDetectorService: DeviceDetectorService
  ) { }

  async ngOnInit() {
    let orderid =  localStorage.getItem('orderid');
    this.editGarment = this.storageService.getString('redirectFromGarment');
    if(this.editGarment=="editGarment"){
      this.backRoute = Routes.ORDER.DETAILS + orderid +'/'+'Pick up & Delivery details';
    }
    this.isMobile = this.deviceDetectorService.isMobile();
    this.fetchServices();
    await this.fetchSavedServices();
  }

  fetchServices(): void {
    this.particleService
      .getParticle(ParticleTypeEnum.Item, Guid.EMPTY)
      .subscribe(
        (response) => {
          this.services = response.map(x => new ParticleItem(x));
          this.isLoading = false;
          this.show_donate = true;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  async fetchSavedServices() {
    const garmentInstructions = this.storageService.getObject('GarmentInstructionsFull');
    if (garmentInstructions !== undefined && garmentInstructions.models !== undefined) {
      this.models = garmentInstructions.models;
    }else {
      this.models = {};
    }
  }

  getCount(item: ParticleItem) {
    if (this.models) {
      const foundItem = this.models[item.id];
      if (foundItem) {
        return foundItem.itemsCount + foundItem.bagCount;
      }
    }
    return 0;
  }

  getText(item: ParticleItem) {
    const count = this.getCount(item);
    if (item.parsedValue.viewType === ServiceViewTypeEnum.Default) {
      return `${count} instruction(s) added`;
    }
    if (item.parsedValue.viewType === ServiceViewTypeEnum.WashAndFold) {
      return `${count} bag(s) added`;
    }
  }

  async navigateToNext() {
    this.isSaving = true;

    if (this.editGarment=="editGarment") {
      this.editGarmentFromOrderDetail(this.storageService.getObject('GarmentInstructionsFull'));
     return;
   }
    if (!this.authService.isLoggedIn()) {
      let path = '/booking/payment';
      
      this.router.navigate(['/welcome'], { queryParams: { redirect: path } });
      return;
    }
    this.router.navigate([ Routes.BOOKING_REGULAR.SUMMARY]);
    return;
    // this.getRedirectUrl();
  }

  // getRedirectUrl() {
  //    this.cardService.getCard()
  //    .then(card => {
  //      this.card = card;
  //      if (this.card != null ){
  //        const paymentInfo = {
  //          paymentMethodInformation: this.card,
  //          method: PaymentMethodEnum.Card
  //        };

  //        this.commandService.executeCommand(CommandEnum.OrderPaymentSaveCommand, paymentInfo)
  //          .then(response => this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]))
  //          .catch(err => this.commandService.handleCommandTokenError(err));
  //      } else {
  //        this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT]);
  //      }
  //    })
  //    .catch(err => {
  //     this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT]);
  //   });
  // }
  async editGarmentFromOrderDetail(GarmentInstructionsFull) {
    this.order_response = this.storageService.getObject('order_response');
    const address = this.storageService.getObject('adresse');

    const GarmentInstructions = {};
        let GarmentInstructionFull = this.storageService.getObject('GarmentInstructionsFull');
        if (GarmentInstructionFull !== undefined) {
          GarmentInstructionFull = GarmentInstructionFull.models;
          for (const [key, value] of Object.entries(GarmentInstructionFull)) {
            const service = value as ServiceWrapperModel;
            if (service.itemsCount > 0) {
              GarmentInstructions[service.parentItem.nameInSpot] = [];
              service.items.forEach(item => {
                
                const instructions = {
                  Item: item.selected.nameInSpot,
                  Instructions: [],
                  Comment: item.additionalInstructions[0] ? item.additionalInstructions[0].parsedValue.text : '',
                  Brand: (item.brand && item.brand?.name) ? item.brand.name : ''
                };
                for (const i of item.instructions) {
                  instructions.Instructions.push(i.name);
                }
                GarmentInstructions[service.parentItem.nameInSpot].push(instructions);
              });
            } else {
              if (service.bagCount > 0) {
                const instructions = {
                  Instructions: []
                };
                instructions.Instructions.push(`${service.bagCount} Bag`);
                GarmentInstructions['Wash'] = [];
                GarmentInstructions['Wash'].push(instructions);
              }
            }
          }
        }

        if(address.donateBags != 0){
          GarmentInstructions['DonateBags'] = `${address.donateBags} Bag`;
        }

        
    let requestPickup = new RequestPickup();   
  
    requestPickup.PickupStartTime = this.order_response.PickupTimeSlot.split('-')[0];
    requestPickup.PickupEndTime = this.order_response.PickupTimeSlot.split('-')[1].substr(1, 6);
    requestPickup.DeliveryStartTime = this.order_response.DeliveryTimeSlot.split('-')[0];
    requestPickup.DeliveryEndTime = this.order_response.DeliveryTimeSlot.split('-')[1].substr(1, 6);
    requestPickup.PickupDate =  moment(new Date(this.order_response.PickupDate)).format('MM-DD-YYYY');
    requestPickup.DeliveryDate = moment(new Date(this.order_response.DeliveryDate)).format('MM-DD-YYYY');
    requestPickup.VisitType = 'Both Pickup and Delivery';
    requestPickup.PickupCodeCountry = this.order_response.PickupCodeCountry;
    requestPickup.PickupPhoneNumber = this.order_response.PickupPhoneNumber;
    requestPickup.PickupTypeDelivery = this.order_response.PickupTypeDelivery;
    requestPickup.DeliveryCodeCountry = this.order_response.DeliveryCodeCountry;
    requestPickup.DeliveryPhoneNumber = this.order_response.DeliveryPhoneNumber;
    requestPickup.DeliveryTypeDelivery = this.order_response.DeliveryTypeDelivery;
    requestPickup.Comments = '';
    requestPickup.DeliveryComments = this.order_response.DeliveryComment;
    // requestPickup.GarmentInstruction = order_response.GarmentInstruction;
    requestPickup.GarmentInstruction = GarmentInstructionFull !== undefined ? JSON.stringify(GarmentInstructions) : '';
    
    
    var paramDelivry = {
      DeliveryaskID : this.order_response.DeliveryaskID
    }
    // delete delivery
    await this.commandService.executeCommand(CommandEnum.DeleteDelivery, paramDelivry);
    var paramPickup = {
      PickupID : this.order_response.PickupID
    }
    // delete pickup
    await this.commandService.executeCommand(CommandEnum.DeletePickup, paramPickup);
    

    let result = await this.commandService.executeCommand(CommandEnum.RequestPickup, requestPickup);
    let orderId = result.ReturnObject.infoOrder_id;
    this.storageService.removeItem("GarmentInstructionsFull");
    this.router.navigate([Routes.ORDER.DETAILS + orderId +'/'+'Pick up & Delivery details']);
  }
}
