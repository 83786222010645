import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonConstants } from 'src/app/constants/common';
import { Routes } from 'src/app/constants/routes.constant';
import { ForgotPasswordComponent } from 'src/app/guest/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from 'src/app/guest/reset-password/reset-password.component';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { OrderTrackingTypeEnum } from 'src/app/models/order/enums/order-tracking-type.enum';
import { LogoButtonEnum } from 'src/app/models/shared/enums/logo-button.enum';
import { SignInRequest } from 'src/app/models/user/sign-in-request.model';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { CookieService } from 'src/app/services/common/cookie.service';
import { PwaService } from 'src/app/services/common/pwa.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { PwaPopupComponent } from 'src/app/shared/pwa-popup/pwa-popup.component';
import { AddressBase } from '../address/base/address.base';
import { BookingBase } from '../booking.base';

@Component({
  selector: 'app-sign-in-up',
  templateUrl: './sign-in-up.component.html',
  styleUrls: ['./sign-in-up.component.scss']
})
export class SignInUpComponent  implements OnInit {
  
  step: BookingStepsEnum = BookingStepsEnum.Address;
  isMobile: boolean;
  logoTypeEnum: typeof LogoButtonEnum = LogoButtonEnum;
  redirect: string;
  redirectUrl: string;
  isLoading: boolean;
  valid: boolean;
  error: string;
  passwordTextType: boolean;
  email: string;
  token: string;

  SignUp: string;
  SignIn: string;
  ForgetPwd: string;
  
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required, this.usernameValidator]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  constructor(
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private _pwaService: PwaService,
    private _deviceDetectorService: DeviceDetectorService,
    private accountSettingService: AccountSettingService,
    public cookieService: CookieService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent, PwaPopupComponent>,
  ) {
    _pwaService.checkForUpdates();
  }

 
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: Event) {
    console.log(e)
    e.preventDefault();
    this._pwaService.setDefferdPrompt(e);
  }
  ngOnInit(): void {
    console.log(this.router.url)
    this.isMobile = this.deviceService.isMobile();
    this.redirect = this.route.snapshot.queryParams['redirect'];
    this._pwaService.BehaviordeferredPrompt.subscribe(value =>{
      if (this._deviceDetectorService.isMobile() && !this._pwaService.isOpenedInApp()
      && ((value === true && this._deviceDetectorService.os !== CommonConstants.DEVICES_OS.iOS)
     // || (value === false && this._deviceDetectorService.os === CommonConstants.DEVICES_OS.iOS)
      ) ) {
        this.dialog.open(PwaPopupComponent);
      }
    })

    this.route.queryParamMap.subscribe((params : any) => {
    if(params.params.token && params.params.id){
      this.dialog.open(ResetPasswordComponent , {
        data: {
          id: params.id,
          token : params.token,
              },
            });
          }
        }
     );

    this.redirectUrl = this.redirect ? this.redirect : '/';

    this.loginForm.statusChanges.subscribe((status) => {
      this.error = null;
      this.valid = status === FormStatusEnum.Valid;
    });
    if(this.router.url.includes('/signinup')){
      this.SignUp = "first-journey-sign-in-up-next";
      this.SignIn = "first-journey-sign-in-up";
      this.ForgetPwd = "first-journey-forget-pwd";
    }else{
      this.SignUp = "sign-in-up-next";
      this.SignIn = "sign-in";
      this.ForgetPwd = "forget-pwd";
    }
  }

  usernameValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const phoneNumberRegex = RegExp(`^(\\+\\d{10,16})$`);

    if (control.value !== '' && !phoneNumberRegex.test(control.value) && Validators.email(control) !== null) {
      return { invalid: true };
    }

    return null;
  }

  showPassword() {
    this.passwordTextType = !this.passwordTextType;
  }

  getFieldFromForm(fieldName: string) {
    return this.loginForm.get(fieldName);
  }

  async onFormSubmit() {

    await this.login({
      username: this.getFieldFromForm('username').value,
      password: this.getFieldFromForm('password').value,
    });
  }

  async login(request: SignInRequest) {
    this.isLoading = true;

    this.auth
      .login(request)
      .then(async (status) => {
        if (status.authenticated) {

          if (this.redirectUrl.split('/')[1] === 'order')
          {
            const data = await this.accountSettingService.getOrderByOrderPmsId(this.redirectUrl.split('/')[2]);

            if (data !== 'not found' ){
              this.redirectUrl =  Routes.ORDER.DETAILS  + data.orderid + '/' + OrderTrackingTypeEnum[data.type];
            } else {
              this.redirectUrl = Routes.ORDER.LIST;
            }
          }
          this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
        } else {
          this.error = status.message;
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  forgotPassword() {
    this.dialog.open(ForgotPasswordComponent, {
      data: {
        email: this.getFieldFromForm('username').value,
      },
    });
  }
  redirectSignUp(){
    this.router.navigate([Routes.GUEST.SIGN_UP]);
  }
}


