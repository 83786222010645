import { OrderTrackingTypeEnum } from './../../models/order/enums/order-tracking-type.enum';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  CookieService
} from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/user/auth.service';
import { SignInRequest } from 'src/app/models/user/sign-in-request.model';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { LogoButtonEnum } from 'src/app/models/shared/enums/logo-button.enum';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { Routes } from 'src/app/constants/routes.constant';
import { FromQrCodeModalComponent } from '../sign/from-qr-code-modal/from-qr-code-modal.component';
import { PwaPopupComponent } from 'src/app/shared/pwa-popup/pwa-popup.component';
import { PwaService } from 'src/app/services/common/pwa.service';
import { CommonConstants } from 'src/app/constants/common';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignInComponent implements OnInit {
  isMobile: boolean;
  logoTypeEnum: typeof LogoButtonEnum = LogoButtonEnum;
  redirect: string;
  redirectUrl: string;
  isLoading: boolean;
  valid: boolean;
  error: string;
  passwordTextType: boolean;
  email: string;
  token: string;
  returnUrl: string;
  paramsEmail = '';
  paramsPassword = '';
  paramsRelease = '';
  isIosAppLogged = false;
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required, this.usernameValidator]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });
  LoggedIn: boolean = false;
  signInBtn: string;
  constructor(
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private _pwaService: PwaService,
    private commandService: CommandService,
    //private _deviceDetectorService: DeviceDetectorService,
    private accountSettingService: AccountSettingService,
    public cookieService: CookieService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent, PwaPopupComponent>
  ) {
    _pwaService.checkForUpdates();
    if (this.deviceService.browser ==  "Unknown") {
      setInterval(() => this.WebViewControl(), 3000);
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: Event) {
    e.preventDefault();
    this._pwaService.setDefferdPrompt(e);
  }

  ngOnInit(): void {
    console.log(this.router.url.split('/')[1])

    var routerUrl = this.router.url.split('/')[1];
    if(routerUrl === 'sign-in?redirect=%2Fbooking%2Fchoose-slots'){
      this.signInBtn="sms-marketing-sign-in";
      localStorage.setItem('smsMarketing', 'true');
    }else if (routerUrl === 'sign-in?redirect=%2Fdeliveryonly%2Fslot'){
      this.signInBtn="sms-delivery-only-sign-in";
    } else{
      this.signInBtn="returning-user-sign-in-user-sign-in";
    }
    

    this.isIosAppLogged =   this.route.snapshot.queryParams.email !== undefined && this.route.snapshot.queryParams.password !== undefined;
    this.paramsEmail = this.route.snapshot.queryParams.email;
    this.paramsPassword = this.route.snapshot.queryParams.password;
    this.paramsRelease = this.route.snapshot.queryParams.version;
    
    if (this.paramsRelease) {
      localStorage.setItem('version', this.paramsRelease);
    }
    
    this.isMobile = this.deviceService.isMobile();
    this.redirect = this.route.snapshot.queryParams['redirect'];
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    
    this._pwaService.BehaviordeferredPrompt.subscribe(value =>{
      if (this.deviceService.isMobile() && !this._pwaService.isOpenedInApp()
      && ((value === true && this.deviceService.os !== CommonConstants.DEVICES_OS.iOS)
     // || (value === false && this._deviceDetectorService.os === CommonConstants.DEVICES_OS.iOS)
      ) ) {
        this.dialog.open(PwaPopupComponent);
      }
    })

    this.route.queryParamMap.subscribe((params : any) => {
    if(params.params.token && params.params.id){
      this.dialog.open(ResetPasswordComponent , {
        data: {
          id: params.id,
          token : params.token,
              },
            });
          }
        }
     );

    this.redirectUrl = this.redirect ? this.redirect : '/';

    if (this.paramsEmail && this.paramsPassword) {
      const signInRequest = {
        username: this.paramsEmail,
        password: this.paramsPassword,
        iosAppLogin: 'true'
      };
      this.login(signInRequest);
    }
    
    
    this.loginForm.statusChanges.subscribe((status) => {
      this.error = null;
      this.valid = status === FormStatusEnum.Valid;
      if( this.deviceService.os === CommonConstants.DEVICES_OS.iOS){
        // this.valid=this.getFieldFromForm('password').value.length>0 && this.getFieldFromForm('username').hasError('invalid');
        this.valid = this.getFieldFromForm('password').value.length >= 6 && !this.getFieldFromForm('username').hasError('invalid');

      }
    });
  }

  WebViewControl(){
    if(this.LoggedIn == false){
        this.loginForm.patchValue({
        username: (<HTMLInputElement>document.getElementsByName("username")[0]).value,
        password: (<HTMLInputElement>document.getElementsByName("password")[0]).value,
      });
    }
  }

  usernameValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const phoneNumberRegex = RegExp(`^(\\+\\d{10,16})$`);

    if (control.value !== '' && !phoneNumberRegex.test(control.value) && Validators.email(control) !== null) {
      return { invalid: true };
    }

    return null;
  }

  showPassword() {
    this.passwordTextType = !this.passwordTextType;
  }

  getFieldFromForm(fieldName: string) {
    return this.loginForm.get(fieldName);
  }

  async onFormSubmit() {

    if (this.deviceService.browser ==  "Unknown") {
      await this.login({
        username: (<HTMLInputElement>document.getElementsByName("username")[0]).value,
        password: (<HTMLInputElement>document.getElementsByName("password")[0]).value,
      });
    } else {
      await this.login({
        username: this.getFieldFromForm('username').value,
        password: this.getFieldFromForm('password').value
      });

    }

  }

  async login(request: SignInRequest) {
    
    this.isLoading = true;
    this.auth
      .login(request)
      .then(async (status) => {
        if (status.authenticated) {
          this.LoggedIn = true;
          if (this.redirectUrl.split('/')[1] === 'order')
          {
            // const data = await this.accountSettingService.getOrderByOrderPmsId(this.redirectUrl.split('/')[2]);
            // if (data !== 'not found' ){
            //   this.redirectUrl =  Routes.ORDER.DETAILS  + data.orderid + '/' + OrderTrackingTypeEnum[data.type];
            // } else {
            //   this.redirectUrl = Routes.ORDER.LIST;
            // }

            // if (this.orderid) {

            var param = {
              infoOrder_id: +this.redirectUrl.split('/')[2],
            }
            this.commandService.executeCommand(CommandEnum.OrderDetailsCommand, param)
              .then(result => {

                if (!OrderTrackingTypeEnum[result.ReturnObject.TypeDelivery] ) {
                  result.ReturnObject.TypeDelivery = 'STORE';
                }
                this.redirectUrl = Routes.ORDER.DETAILS + this.redirectUrl.split('/')[2] + '/' + OrderTrackingTypeEnum[result.ReturnObject.TypeDelivery];
                this.router.navigate([this.redirectUrl]);
              })
          }
          if (this.redirectUrl) {            
            if(this.redirectUrl.search('choose-slots') != -1){
              this.redirectUrl = Routes.BOOKING_REGULAR.SLOTS;
              this.router.navigate([this.redirectUrl]);
            } else if(!this.returnUrl){
              
              this.redirectUrl = this.redirectUrl;
              this.router.navigate([this.redirectUrl]);
            }else {
              
              if(this.returnUrl.search('payments')){
                this.commandService.executeCommand(CommandEnum.CustomerDetail)
                .then(result => {
                  if(result['ReturnObject']['infoCustomer']['CustomerID'] == this.returnUrl.split('&')[1].split('=')[1]){
                    
                    this.router.navigate([Routes.USER.PAYMENTS]);
                  }else {
                    
                    this.redirectUrl = Routes.USER.LANDING;
                    this.router.navigate([this.redirectUrl]);
                  }
                  
                })
              }else {
                this.redirectUrl = this.returnUrl;
                this.router.navigate([this.redirectUrl]);
              }  
              
            }

          } else {
            if (this.isMobile) {
              console.log('eeeeeeeeeeeeeeeeeeeee')
              if (this.redirectUrl.search('deliveryonly') != -1) {
                this.redirectUrl = Routes.DELIVERY_ONLY.SLOT
              } else {
                if(this.returnUrl.search('payments')){
                  this.commandService.executeCommand(CommandEnum.CustomerDetail)
                  .then(result => {
                    if(result['ReturnObject']['infoCustomer']['CustomerID'] == this.returnUrl.split('&')[1].split('=')[1]){
                      this.redirectUrl = this.returnUrl;
                      console.log('tessst',this.redirectUrl)
                    }else {
                      this.redirectUrl = Routes.USER.LANDING;
                    }
                    
                  })
                }else {
                  this.redirectUrl = this.returnUrl;
                }  
                this.router.navigate([this.redirectUrl]);
              } 
            } else {
              if (this.redirectUrl.search('deliveryonly') != -1) {
                this.redirectUrl = Routes.DELIVERY_ONLY.SLOT
              } else {
                this.redirectUrl = Routes.ORDER.LIST;
              } 
              this.router.navigate([this.redirectUrl]);
            }
            
          }

          // }
          //this.router.navigate([this.redirectUrl]);
        } else {
          this.error = status.message;
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  forgotPassword() {
    this.dialog.open(ForgotPasswordComponent, {
      data: {
        email: this.getFieldFromForm('username').value,
      },
    });
  }
}
