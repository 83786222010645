import { StorageService } from './../../services/common/storage.service';
import { Voucher } from './../../models/obsolete/order';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { Constant } from 'src/app/models/common/constant.model';
import { CardService } from 'src/app/services/user/card.service';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { SummaryAddress } from 'src/app/models/booking/summary-address.model';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { ServiceWrapperModel } from 'src/app/models/particle/service-wrapper.model';
import { CommandService } from 'src/app/services/common/command.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { HttpStatusCode } from 'src/app/models/common/enums/http-status-code';
import { AlertService } from 'src/app/services/common/alert.service';
import { CardResponse } from 'src/app/models/user/card-response.model';
import { OrderEmailModel } from 'src/app/models/booking/order-email.model';
import { Routes } from 'src/app/constants/routes.constant';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { VoucherFieldComponent } from '../payment/voucher-field/voucher-field.component';
import { User } from 'src/app/models/user/user.model';
import { RequestPickup } from 'src/app/models/spot/requestPickup.model';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { CookieService } from 'src/app/services/common/cookie.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root',
})
export class SummaryBase implements OnInit {
  dateFormat: string = 'ddd DD MMM ';
  dateFormatDesktop: string = 'dddd[,] MMMM D ';
  step: BookingStepsEnum = BookingStepsEnum.Summary;
  email: string;
  hasAllData: boolean = false;
  deliverySlot: TimeSlot;
  pickupSlot: TimeSlot;
  instructions: Instructions;
  hasInstructions: boolean;
  address: SummaryAddress;
  payment: any;//CardResponse
  services: ServiceWrapperModel[] = [];
  Voucher_code: boolean;
  Voucher: any;
  addVoucher: boolean;
  vocher: any;
  user: User;
  deliverToOptions: Constant[] = [];
  requestPickup: RequestPickup;
  endpoint: string;
  confirmBookingBtn : string;
  EditAddress : string;
  EditDelivery : string;
  EditPickup : string;
  EditInstructions: string;

  constructor(
    public accountSettingService: AccountSettingService,
    protected auth: AuthService,
    protected router: Router,
    private cookieService: CookieService,
    protected commandService: CommandService,
    protected alertService: AlertService,
    protected bookingStepsService: BookingStepsService,
    private authService: AuthService,
    public cardService: CardService,
    protected particleService: ParticleService,
    protected storageService: StorageService,
    protected blancCommandService: BlancCommandService,
    private http: HttpClient,  
    public matDialog: MatDialog,
  ) {
    this.endpoint = environment.api;
   }

  async ngOnInit() {

    this.bookingStepsService.setStep(BookingStepsEnum.Summary);
    // await this.initDeliverToOptions();
    //await this.orderConnect();
    await this.initData();
    this.hasAllData = true;

    let verify = localStorage.getItem('first-journey');
    let smsMarketing = localStorage.getItem('smsMarketing')
    if ( verify === 'true'){
      this.EditAddress = "first-journey-booking-summary-editAddress";
      this.EditDelivery = "first-journey-booking-summary-editDelivery";
      this.EditPickup= "first-journey-booking-summary-editPickup";
      this.confirmBookingBtn = "first-journey-booking-summary-confirmBooking";
      this.EditInstructions = "first-journey-booking-summary-editInstructions";
    }else if (smsMarketing){
      this.EditAddress = "sms-marketing-booking-summary-editAddress";
      this.EditDelivery = "sms-marketing-booking-summary-editDelivery";
      this.EditPickup= "sms-marketing-booking-summary-editPickup";
      this.confirmBookingBtn = "sms-marketing-booking-summary-confirmBooking";
      this.EditInstructions = "sms-marketing-booking-summary-editInstructions";
    }else{
      this.EditAddress = "returning-user-booking-summary-editAddress";
      this.EditDelivery = "returning-user-booking-summary-editDelivery";
      this.EditPickup= "returning-user-booking-summary-editPickup";
      this.confirmBookingBtn = "returning-user-booking-summary-confirmBooking";
      this.EditInstructions = "returning-user-booking-summary-editInstructions";
    }
    console.log(verify);
  }
  onAddvoucher() {
    this.addVoucher = true;
  }

  async orderConnect() {
    try {
      await this.commandService.executeCommand(CommandEnum.OrderConnectCommand);
    }
    catch (error) {
      console.log('OrderUserConnect Failed: ', error);
    }
  }

  async initData() {
    const customerDetailResponse = await this.commandService.executeCommand(CommandEnum.CustomerDetail);
    const DeliveryPreferenceResponse = await this.commandService.executeCommand(CommandEnum.GetDeliveryPreference);

    this.Voucher = this.storageService.getObject('voucher') !== undefined ? this.storageService.getObject('voucher') : null;
    if (this.Voucher !== 'null') {
      this.Voucher_code = false;
    }
    const arrayService = [];
    let servicesObj = this.storageService.getObject('GarmentInstructionsFull');

    if (servicesObj !== undefined) {
      servicesObj = servicesObj.models;
      for (const key in servicesObj) {
        if (servicesObj.hasOwnProperty(key)) {
          if ( (servicesObj[key].itemsCount === 0) && (servicesObj[key].bagCount === 0) ){
            continue;
          }else {
            arrayService.push(servicesObj[key]);
          }
        }
      }
      this.services = arrayService;
    }
    // this.cardService.getCard()
    //   .then(card => {
    //     this.payment = card;
    //   });
    this.blancCommandService.executeCommand(CommandEnum.GetCustomerCard)
      .then(response => {
        let card = response['ReturnObject']['card'];
        
        if (card && card.Actif  != '0') {
          card.cardNumber = card.cardNumber.substr(-5);
          this.payment = card;      
        } 
      })
    this.pickupSlot = this.storageService.getObject('pickup');
    this.deliverySlot = this.storageService.getObject('delivery');
    console.log('here summary',this.storageService.getObject('adresse'))
    if (this.storageService.getObject('adresse')?.addressLine1) {

      this.address = new SummaryAddress(
        this.storageService.getObject('adresse')?.addressLine1,
        this.storageService.getObject('adresse')?.addressLine2,
        this.storageService.getObject('adresse')?.postcode,
        this.storageService.getObject('adresse')?.town,
      );
    }else{
        this.address = new SummaryAddress(
          customerDetailResponse.ReturnObject.address[0].address1,
          customerDetailResponse.ReturnObject.address[0].address2,
          customerDetailResponse.ReturnObject.address[0].postcode,
          customerDetailResponse.ReturnObject.address[0].Town
        );
    }
    if (this.storageService.getObject('adresse')
      && this.storageService.getObject('adresse').instructions?.deliverToName) {

      this.instructions = new Instructions(
        this.storageService.getObject('adresse').instructions?.deliverToId,
        this.storageService.getObject('adresse').instructions?.deliverToName?.toLowerCase(),
        this.storageService.getObject('adresse').instructions?.countryCode,
        this.storageService.getObject('adresse').instructions?.phoneNumber,
        this.storageService.getObject('adresse').instructions?.other,
      );
    }else if(DeliveryPreferenceResponse.ReturnObject && DeliveryPreferenceResponse.ReturnObject.CustomerID ){
      this.instructions = new Instructions(
        DeliveryPreferenceResponse.ReturnObject.CustomerID,
        DeliveryPreferenceResponse.ReturnObject.TypeDelivery,
        DeliveryPreferenceResponse.ReturnObject.CodeCountry,
        DeliveryPreferenceResponse.ReturnObject.PhoneNumber,
        DeliveryPreferenceResponse.ReturnObject.OtherInstruction,
      );    
    }

    this.hasInstructions = (this.instructions?.deliverToName) ? true : false;

  }
  async initDeliverToOptions() {
    const response = await this.particleService.getParticle(ParticleTypeEnum.DeliverTo).toPromise();
    this.deliverToOptions = response;
  }
  async saveUsedVoucher($voucher) {
    
    const body = {
      voucherCode : $voucher,
      userEmail: this.cookieService.getCookie('logged_email'),
    };
    let addVoucherCommandResult: any;
    return await this.http.post(this.endpoint + 'saveUsedVoucher', body).toPromise();
  }
  async confirmBooking() {
    const customerDetailResponse = await this.commandService.executeCommand(CommandEnum.CustomerDetail);
    this.commandService.executeCommand(CommandEnum.GetCustomerCard )
    .then(async(response) => {
      if(!response.ReturnObject.card && customerDetailResponse.ReturnObject.infoCustomer.OnAccount == 0 || (response.ReturnObject.card && response.ReturnObject.card?.Actif == '0' && customerDetailResponse.ReturnObject.infoCustomer.OnAccount == 0)){
        this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT], {
          queryParams: { paymentStripe: 'add' },
        });
      } else {
        
        const address = this.storageService.getObject('adresse');
        // if ( !address?.addressLine1 ) {
        //   this.alertService.showError('error');
        // }
        // saving address for guest booking new users
        if (customerDetailResponse.ReturnObject.address.length === 0 && address?.addressLine1 ) {
        // if ( !customerDetailResponse.Failed && address?.addressLine1 ) {
          const add = {
            AddressID: '',
            address1: address.addressLine1,
            address2: address.addressLine2,
            postcode: address.postcode,
            longitude: '',
            Latitude: '',
            County: '',
            Country: '',
            Town: address.town !== '' ? address.town : 'London',
            name: ''
          };
          customerDetailResponse.ReturnObject.address = [add];
          await this.commandService.executeUpdate(CommandEnum.SaveCustomer, customerDetailResponse.ReturnObject);
        }

        const GarmentInstructions = {};
        let GarmentInstructionFull = this.storageService.getObject('GarmentInstructionsFull');
        if (GarmentInstructionFull !== undefined) {
          GarmentInstructionFull = GarmentInstructionFull.models;
          for (const [key, value] of Object.entries(GarmentInstructionFull)) {
            const service = value as ServiceWrapperModel;
            if (service.itemsCount > 0) {
              GarmentInstructions[service.parentItem.nameInSpot] = [];
              service.items.forEach(item => {
                
                const instructions = {
                  Item: item.selected.nameInSpot,
                  Instructions: [],
                  Comment: item.additionalInstructions[0].parsedValue ? item.additionalInstructions[0].parsedValue.text : '',
                  Brand: (item.brand && item.brand?.name) ? item.brand.name : ''
                };
                for (const i of item.instructions) {
                  instructions.Instructions.push(i.name);
                }
                GarmentInstructions[service.parentItem.nameInSpot].push(instructions);
              });
            } else {
              if (service.bagCount > 0) {
                const instructions = {
                  Instructions: []
                };
                instructions.Instructions.push(`${service.bagCount} Bag`);
                GarmentInstructions['Wash'] = [];
                GarmentInstructions['Wash'].push(instructions);
              }
            }
          }
        }
        this.hasAllData = false;
        this.requestPickup = new RequestPickup();
        const pickup = this.storageService.getObject('pickup');
        if ( !pickup ) {
          this.alertService.showError('Please choose your pickup date.');
        }
        const delivery = this.storageService.getObject('delivery');
        if ( !delivery ) {
          this.alertService.showError('Please choose your delivery date.');
        }
        const voucher = this.storageService.getObject('voucher');

        if(address.donateBags != 0){
          GarmentInstructions['DonateBags'] = `${address.donateBags} Bag`;
        }

        if (voucher !== undefined) {
          GarmentInstructions['Voucher'] = `${voucher}`;
        }

        const deliveryInstructions = address.instructions;
        this.requestPickup.PickupStartTime = pickup.from;
        this.requestPickup.PickupEndTime = pickup.to;
        const pickupDate = pickup.date.substr(5);
        const year = pickup.date.substr(0, 4);
        const deliveryDate = delivery.date.substr(5);
        this.requestPickup.PickupDate = pickupDate + '-' + year;
        this.requestPickup.DeliveryStartTime = delivery.from;
        this.requestPickup.DeliveryEndTime = delivery.to;
        this.requestPickup.DeliveryDate = deliveryDate + '-' + delivery.date.substr(0, 4);
        this.requestPickup.VisitType = 'Both Pickup and Delivery';
        this.requestPickup.PickupCodeCountry = deliveryInstructions.countryCode;
        this.requestPickup.PickupPhoneNumber = deliveryInstructions.phoneNumber;
        this.requestPickup.PickupTypeDelivery = deliveryInstructions.deliverToName;
        this.requestPickup.DeliveryCodeCountry = deliveryInstructions.countryCode;
        this.requestPickup.DeliveryPhoneNumber = deliveryInstructions.phoneNumber;
        this.requestPickup.DeliveryTypeDelivery = deliveryInstructions.deliverToName;
        this.requestPickup.Comments = '';
        this.requestPickup.DeliveryComments = deliveryInstructions.other;
        this.requestPickup.GarmentInstruction = GarmentInstructionFull !== undefined ? JSON.stringify(GarmentInstructions) : (voucher) ? JSON.stringify(GarmentInstructions) : '';
        
        const body = {
          voucher : GarmentInstructions['Voucher'],
          DonateBags : GarmentInstructions['DonateBags'],
          address : address,
          requestPickup : this.requestPickup
        };
        let saveLogConfirm: any;
        saveLogConfirm = await this.http.post(this.endpoint + 'saveLogConfirm', body).toPromise();

        this.blancCommandService.executeCommand(CommandEnum.RequestPickup, this.requestPickup)
        .then(response => {
          if (!response['Failed']){
            this.storageService.removeItem('POSTCODE');
            this.storageService.removeItem('adresse');
            this.storageService.removeItem('delivery');
            this.storageService.removeItem('pickup');
            this.storageService.removeItem('GarmentInstructionsFull');
            this.storageService.removeItem('first-journey');
            this.storageService.removeItem('smsMarketing');
            this.storageService.removeItem('voucher');
            const orderId = response['ReturnObject']['infoOrder_id'];
            if (voucher) {
              this.saveUsedVoucher(voucher);
            }
            
            this.router.navigate([Routes.BOOKING_REGULAR.THANK_YOU], { queryParams: { orderId } });
          } else {
            if (response['Message'] == 'Customer already has a delivery scheduked for the provided date.') {
            // this.alertService.showError('Delivery already scheduled for selected date. Please edit your slots to select a date that we are not already coming to you.');
              this.matDialog.open(AlerteModalComponent);
            } else {
              this.alertService.showError(response['Message']);
            }
          }
        })
        .catch(err => {
          this.alertService.showError(err);
        });
        this.hasAllData = true;
      }
        
    })
  }
}
