import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BookingBase } from '../booking.base';

import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { Routes } from 'src/app/constants/routes.constant';
import { CardService } from 'src/app/services/user/card.service';
import { CardResponse } from 'src/app/models/user/card-response.model';
import { PaymentMethodEnum } from 'src/app/models/booking/enums/payment-method.enum';

@Component({
  selector: 'booking-authorisations',
  templateUrl: './authorisations.component.html',
  styleUrls: ['./authorisations.component.scss']
})
export class AuthorisationsComponent extends BookingBase {
  card: CardResponse;

  constructor(protected deviceService: DeviceDetectorService,   protected commandService: CommandService,protected cardService: CardService, protected router: Router) {
    super(deviceService);
  }

  hasAuthorisationsAsync() {
     return this.commandService.executeCommand(CommandEnum.HasAuthorizationsCommand);
  }

  // redirectToPayment() {
  //   this.cardService.getCard()
  //   .then(card => {
  //     this.card = card;
  //     if(this.card != null ){
  //       let paymentInfo = {
  //         paymentMethodInformation: this.card,
  //         method: PaymentMethodEnum.Card
  //       };
    
  //       this.commandService.executeCommand(CommandEnum.OrderPaymentSaveCommand, paymentInfo)
  //         .then(response => 
  //         this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]))
  //         .catch(err => this.commandService.handleCommandTokenError(err));
  //     } else {
  //       this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT]);
  //     }
  //   })
  //   return;
  // }
}
