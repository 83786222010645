<div class="close-btn-wrapper">
  <div (click)="closeDialog()" class="icon close" id="close-btn"></div>
</div>

<div class="content">
  <h3>Pause your recurring booking</h3>

  <div class="text-wrapper">
    <p class="ff-300">You can pause your recurring booking anytime - please note the cut-off time to cancel your recurring booking is 4pm on the day before the scheduled date.</p>
    <p>Simply let us know the dates when you’d like your recurring booking to be paused here below:</p>
  </div>

  <form *ngIf="pauseForm" [formGroup]="pauseForm" (ngSubmit)="onFormSubmit()">
    <div class="field-wrapper">
      <p class="label ff-400">Pause starts</p>
      <mat-form-field>
        <input matInput [matDatepicker]="PauseDateFrom" [min]="pauseStartMin" formControlName="PauseDateFrom" readonly>
        <mat-datepicker touchUi="true" #PauseDateFrom></mat-datepicker>
        <div (click)="PauseDateFrom.open()" class="calendar-button">
          <p class="ff-300">{{ (getFormControlValue('PauseDateFrom') === defaultDate) ? '' : (getFormControlValue('PauseDateFrom') | toMoment | dateFormat:dateFormat) }}</p>
        </div>
      </mat-form-field>
    </div>

    <div class="field-wrapper">
      <p class="label ff-400">Pause end</p>
      <mat-form-field>
        <input matInput [matDatepicker]="PauseDateTo" [min]="pauseEndMin" formControlName="PauseDateTo" readonly>
        <mat-datepicker touchUi="true" #PauseDateTo></mat-datepicker>
        <div (click)="PauseDateTo.open()" class="calendar-button">
          <p class="ff-300">{{ (getFormControlValue('PauseDateTo') === defaultDate) ? '' : (getFormControlValue('PauseDateTo') | toMoment | dateFormat:dateFormat) }}</p>
        </div>
      </mat-form-field>
    </div>

    <button [disabled]="pauseForm.invalid || loading" class="btn submit secondary-danger" type="submit">
      <ng-container *ngIf="!loading">Save</ng-container>
      <shared-spinner *ngIf="loading" color="red" ></shared-spinner>
    </button>
    
  </form>
</div>
