<shared-spinner *ngIf="!hasAllData"></shared-spinner>

<div *ngIf="hasAllData" class="content">
    <div class="heading">
        <shared-back-button></shared-back-button>
        <h1>Delivery instructions</h1>
        <div (click)="clearInstruction()" class="bin"><mat-icon>delete</mat-icon></div>

        <p>Please let us know about any information our drivers need to know to collect and deliver your items.</p>
    </div>

    <form [formGroup]="instructionsForm" (submit)="onFormSubmit()">
        <div class="field-wrapper deliver-to">
            <p>Pick up and deliver to</p>
            <mat-form-field class="deliver-to">
                <mat-select formControlName="deliverToId" disableOptionCentering>
                    <mat-option *ngFor="let option of deliverToOptions; let i = index" [value]="option.value">
                        {{option.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="error" *ngIf="getFormField('deliverToId').hasError('required') && instructionsForm.controls.deliverToId.touched">
              Field is required
          </div>
        </div>

        <div class="field-wrapper code">
            <shared-country-code-picker (onPick)="setCountryCodeValue($event)" [countryCode]="code"></shared-country-code-picker>
        </div>

        <div class="field-wrapper custom-input-wrapper phone">
            <label class="phone-label">Their phone number</label>
            <input matinput type="tel" formControlName="phoneNumber" class="phone-input">
            <div class="error" *ngIf="getFormField('phoneNumber').hasError('required') && instructionsForm.controls.phoneNumber.touched">
                Field is required
            </div>
            <div class="error" *ngIf="getFormField('phoneNumber').hasError('pattern') && instructionsForm.controls.phoneNumber.touched">
                Phone number is invalid
            </div>
        </div>

        <div class="others-instructions">
            <p>Other instructions</p>
            <textarea formControlName="other" maxlength="150" matInput cdkTextareaAutosize placeholder="Ring the bell / knock, leave on doorstep ..."></textarea>
            <div class="counter">
                <p>{{instructionsForm.controls.other.value.length}} / 150</p>
            </div>
        </div>

        <button type="submit" class="btn secondary" [id]="orderMobileEditDeliveryInstSave">Save instructions</button>
    </form>
</div>
