import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingAddressData } from 'src/app/models/booking/address-data.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { Routes } from 'src/app/constants/routes.constant';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-save-instruction-modal',
  templateUrl: './save-instruction-modal.component.html',
  styleUrls: ['./save-instruction-modal.component.scss']
})
export class SaveInstructionModalComponent implements OnInit {
  address: BookingAddressData;
  preference: boolean;
  order_only: boolean;
  redirectUrl: string;
  orderEditAddressSaveAsPreference: string;
  orderEditAddressSaveForThisOrderOnly: string;
  redirect: string;
  isMobile: boolean;

  constructor(
    public accountSettingService: AccountSettingService,
    public storageService: StorageService,
    public commandService: CommandService,
    protected route: ActivatedRoute,
    protected dialogRef: MatDialogRef<SaveInstructionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected router: Router,
    protected deviceService: DeviceDetectorService
  ) { dialogRef.disableClose = true;}

  ngOnInit(): void {
    this.initRedirectUrl();
    let first = localStorage.getItem('first-journey');
    this.isMobile = this.deviceService.isMobile();
    const redirect = this.router.url.split('?').pop();
    console.log(this.router.url.split('/')[2])
    let smsMarketing = localStorage.getItem('smsMarketing')
    if(smsMarketing){
      this.orderEditAddressSaveAsPreference = "sms-marketing-editAddress-saveAsPreference";
      this.orderEditAddressSaveForThisOrderOnly= "sms-marketing-editAddress-saveForThisOrderOnly";
    }else if (first){

      this.orderEditAddressSaveAsPreference = "first-journey-booking-adress-saveAsPreferences";
      this.orderEditAddressSaveForThisOrderOnly= "first-journey-booking-adress-saveForThisOrderOnly";
    }else{
      if(this.isMobile){
        this.orderEditAddressSaveAsPreference = "order-mobile-editAddress-saveAsPreference";
        this.orderEditAddressSaveForThisOrderOnly= "order-mobile-editAddress-saveForThisOrderOnly";
      }else{
        this.orderEditAddressSaveAsPreference = "order-desk-editAddress-saveAsPreference";
        this.orderEditAddressSaveForThisOrderOnly= "order-desk-editAddress-saveForThisOrderOnly";
      }
    }
   
  }

  closeDialog() {
    this.dialogRef.close(this.preference);
    if(this.router.url.endsWith('y')){
      this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
    }
    if(this.router.url.endsWith('address')){
      this.router.navigate([Routes.BOOKING_REGULAR.ADDRESS]);
    }else if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
    }
  }

  SavePreference() {
    this.preference = true;
    this.order_only = false;
    setTimeout(() => {
      this.dialogRef.close();
    }, 3000);

  }

  SaveOrderOnly() {
    this.order_only = true;
    this.preference = false;
    setTimeout(() => {
      this.dialogRef.close();
    }, 3000);
  }

  initRedirectUrl() {
    const redirectQueryParam = this.route.snapshot.queryParams['redirect'];
    this.redirectUrl = redirectQueryParam
      ? redirectQueryParam
      : Routes.BOOKING_REGULAR.SUMMARY;
  }
}
