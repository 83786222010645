import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule } from '@angular/common/http';
import { UserModule } from '../user/user.module';
import { ToastrModule } from 'ngx-toastr';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';

import { BookingComponent } from './booking.component';
import { BookingRoutingModule } from './booking-routing.module';
import { ProgressBarMobileComponent } from './progress-bar-mobile/progress-bar-mobile.component';
import { ProgressBarDesktopComponent } from './progress-bar-desktop/progress-bar-desktop.component';
import { HeaderComponent } from './header/header.component';
import { SlotsComponent } from './slots/slots.component';
import { DeliveryComponent } from '../booking/slots/delivery/delivery.component';
import { PickUpComponent } from '../booking/slots/pick-up/pick-up.component';
import { ScheduleComponent } from './slots/schedule/schedule.component';
import { AddressComponent } from './address/address.component';
import { AddressMobileComponent } from './address/address-mobile/address-mobile.component';
import { AddressDesktopComponent } from './address/address-desktop/address-desktop.component';
import { InstructionsComponent } from './address/address-desktop/instructions/instructions.component';
import { InstructionsModalComponent } from './address/address-mobile/instructions-modal/instructions-modal.component';
import { DonateDesktopComponent } from './address/address-desktop/donate/donate.component';
import { DonateModalComponent } from './address/address-mobile/donate-modal/donate-modal.component';
import { AuthorisationsDesktopComponent } from './authorisations/authorisations-desktop/authorisations-desktop.component';
import { AuthorisationsMobileComponent } from './authorisations/authorisations-mobile/authorisations-mobile.component';
import { PaymentDesktopComponent } from './payment/payment-desktop/payment-desktop.component';
import { PaymentMobileComponent } from './payment/payment-mobile/payment-mobile.component';
import { LandingComponent } from './instructions/landing/landing.component';
import { ServicesComponent } from './instructions/services/services.component';
import { ServiceComponent } from './instructions/services/service/service.component';
import { SummaryComponent } from './summary/summary.component';
import { SummaryDesktopComponent } from './summary/summary-desktop/summary-desktop.component';
import { SummaryMobileComponent } from './summary/summary-mobile/summary-mobile.component';
import { SummaryInstructionsComponent } from './summary/summary-instructions/summary-instructions.component';
import { ItemModalComponent } from './instructions/services/service/service-mobile/item-modal/item-modal.component';
import { InstructionModalComponent } from './instructions/services/service/service-mobile/instruction-modal/instruction-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GiveInstructionComponent } from './instructions/services/service/service-mobile/give-instruction/give-instruction.component';
import { PaymentComponent } from './payment/payment.component';
import { VoucherFieldComponent } from './payment/voucher-field/voucher-field.component';
import { WashFoldComponent } from './instructions/services/service/wash-fold/wash-fold.component';
import { AuthorisationsComponent } from './authorisations/authorisations.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { SustainabilitySectionComponent } from './thank-you/sustainability-section/sustainability-section.component';
import { ContactSectionComponent } from './thank-you/contact-section/contact-section.component';
import { ProvisionComponent } from './provision/provision.component';
import { ServicesMobileComponent } from './instructions/services/services-mobile/services-mobile.component';
import { ServiceMobileComponent } from './instructions/services/service/service-mobile/service-mobile.component';
import { ServicesDesktopComponent } from './instructions/services/services-desktop/services-desktop.component';
import { ServiceDesktopComponent } from './instructions/services/service/service-desktop/service-desktop.component';
import { MenuItemComponent } from './instructions/services/service/service-desktop/menu-item/menu-item.component';
import { BrandSearchMobileComponent } from './brand-search/brand-search-mobile/brand-search-mobile.component';
import { BrandSearchDesktopComponent } from './brand-search/brand-search-desktop/brand-search-desktop.component';
import { InstructionsListModalComponent } from './instructions/services/service/service-desktop/instructions-list-modal/instructions-list-modal.component';
import { AddressBase } from './address/base/address.base';
import { SaveInstructionModalComponent } from './address/save-instruction-modal/save-instruction-modal.component';
import { SummaryDeleveryInstructionComponent } from './summary/summary-delevery-instruction/summary-delevery-instruction.component';
import { SignInUpComponent } from './sign-in-up/sign-in-up.component';
import { PreferencesStoreComponent } from './preferences-store/preferences-store.component';


@NgModule({
  declarations: [
    BookingComponent,
    HeaderComponent,
    ProgressBarDesktopComponent,
    ProgressBarMobileComponent,
    SlotsComponent,
    DeliveryComponent,
    PickUpComponent,
    ScheduleComponent,
    AddressComponent,
    AddressMobileComponent,
    AddressDesktopComponent,
    InstructionsComponent,
    InstructionsModalComponent,
    DonateModalComponent,
    DonateDesktopComponent,
    AuthorisationsDesktopComponent,
    AuthorisationsMobileComponent,
    PaymentDesktopComponent,
    PaymentMobileComponent,
    LandingComponent,
    ServicesComponent,
    ServiceComponent,
    ItemModalComponent,
    InstructionModalComponent,
    SummaryComponent,
    SummaryDesktopComponent,
    SummaryMobileComponent,
    SummaryInstructionsComponent,
    GiveInstructionComponent,
    WashFoldComponent,
    PaymentComponent,
    VoucherFieldComponent,
    AuthorisationsComponent,
    ThankYouComponent,
    SustainabilitySectionComponent,
    ContactSectionComponent,
    ProvisionComponent,
    ServicesMobileComponent,
    ServiceMobileComponent,
    ServicesDesktopComponent,
    ServiceDesktopComponent,
    MenuItemComponent,
    BrandSearchMobileComponent,
    BrandSearchDesktopComponent,
    InstructionsListModalComponent,
    SaveInstructionModalComponent,
    SummaryDeleveryInstructionComponent,
    SignInUpComponent,
    PreferencesStoreComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSelectModule,
    MatAutocompleteModule,
    HttpClientModule,
    UserModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 3000,
    }),
    DeviceDetectorModule,
    PipesModule,
    SharedModule,
    BookingRoutingModule,
    MatCheckboxModule,
    MatIconModule
  ],
  exports: [
    CommonModule,
    BookingComponent,
    BookingRoutingModule,
    ScheduleComponent,
    InstructionsModalComponent,
    ProgressBarMobileComponent,
    InstructionsComponent,
    SlotsComponent,
    ProgressBarDesktopComponent,
    ProgressBarMobileComponent,
    SummaryInstructionsComponent
  ],
  providers: [

    {
      provide: AddressBase,
    },DatePipe
  ]
})
export class BookingModule { }
