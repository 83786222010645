<nav class="nav-bottom">
    <div class="wrapper">
        <a routerLink="/" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}" [id]="menuMobileHome">
            <div class="icon-text-wrapper">
                <div class="icon home"></div>
                <span>Home</span>
            </div>
        </a>
        <a [routerLink]="orderListRoute" routerLinkActive="is-active" [id]="menuMobileMyOrders">
            <div class="icon-text-wrapper">
                <div class="icon orders-small"></div>
                <span>My orders</span>
            </div>
        </a>
        <a (click)="redirectToBooking()" [id]="menuMobileBooking" routerLinkActive="is-active">
            <div class="icon-text-wrapper">
                <div class="icon truck"></div>
                <span>Booking</span>
            </div>
        </a>
        <a (click)="toggleShown()" id="more-btn">
            <div class="icon-text-wrapper">
                <div class="icon more"></div>
                <span>More</span>
            </div>
        </a>
    </div>
</nav>

<nav class="nav-more animation-show-nav" [ngClass]="{'shown': isShown}" id="nav">
    <div class="overlay" (click)="toggleShown()" id="overlay"></div>

    <menu>
        <div class="heading">
            <img src="assets/images/logo-blanc-b-white.png" alt="logo blanc">
            <h3>{{greetings}}</h3>
            <a (click)="signOut()" [id]="menuMobileSignout"><span>Log out</span></a>
        </div>

        <ul>
            <li (click)="redirectToRecurring()" >
                <div class="icon recurrence"></div>
                <div class="bl-new-tag" *ngIf="showBadgeNew"><span>NEW</span></div>
                <p [id]="menuMobileRecurring">Recurring booking</p>
            </li>
            <li (click)="redirectTo('/personal-info')" >
                <div class="icon profile" ></div>
                <p [id]="menuMobilePersonnalInfo">Personal information</p>
            </li>
            <li (click)="redirectTo('/payments')" >
                <div class="icon credit-card-green"></div>
                <p [id]="menuMobilePayments">Payment information</p>
            </li>
            <li (click)="redirectTo('/preferences'); updatePreferencePercentage()" >
                <div class="icon preferences"></div>
                <p [id]="menuMobilePreferences">Preferences</p>
            </li>
            <!-- <li (click)="redirectTo('/refer')">
        <div class="icon gift"></div>
        <p>Refer a friend</p>
      </li> -->
            <li (click)="redirectTo('/help-feedback')">
                <div class="icon help"></div>
                <p  [id]="menuMobileHelpFeedback">Help & feedback</p>
            </li>
            <li *ngIf="isAdmin" (click)="redirectTo('/admin')" class="highlighted" id="admin-btn">
                <div class="icon logo-green"></div>
                <p>Admin panel</p>
            </li>
        </ul>

        <div class="contact-info bottom-fixed with-arrow absolute">
            <img src="assets/icons/phone-secondary.svg" alt="blanc contact">
            <div class="text-wrapper">
                <p>Need to talk to us?</p>
                <p>call +44 (0)20 8004 2630</p>
            </div>
        </div>
    </menu>
</nav>
