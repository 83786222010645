<div *ngIf="isLoading" class="spinner-wrapper"><shared-spinner></shared-spinner></div>

<ul *ngIf="!isLoading" class="current-orders" id="current-orders">
	<li *ngFor="let order of orderList.orders">
		<a [routerLink]="orderLink(order)" [id]="orderselected" [ngClass]="css">
			<div class="order-heading">
				<span class="name"
					*ngIf="((order.Status | lowercase) != 'scheduled') && ((order.Status| lowercase) != 'recurring')">Order
					{{order.id}}</span>
				<span class="name"></span>
				<span class="tag status" [class.red]="order.isPending">
					{{((order.Status == 'RECURRING') ? 'scheduled' : order.Status) | lowercase}}
				</span>
			</div>
			<div class="order-body">
				<container-element [ngSwitch]="order.Status">
					<ng-container *ngSwitchDefault>
						<div class="text-wrapper" *ngIf="order.TypeDelivery != 'STORE'">
							<p class="date" *ngIf="order.DatePickup && order.DeliveryDate">
								{{order.typeAsk }}: {{((order.typeAsk == 'Pickup') ? order.DatePickup : order.DeliveryDate) | date : 'MMM d'}}
							</p>
							<p class="date" *ngIf="!order.DatePickup && !order.DeliveryDate"></p>
							<p class="date" *ngIf="order.PickupTimeSlot && order.DeliveryTimeSlot && order.DeliveryTimeSlot != ' - '">
								{{((order.typeAsk == 'Pickup') ? order.PickupTimeSlot : order.DeliveryTimeSlot) | hourFormat}}
							</p>
							<p class="date" *ngIf="!order.PickupTimeSlot && !order.DeliveryTimeSlot"></p>

						</div>
						<div class="text-wrapper" *ngIf="order.TypeDelivery == 'STORE'">
							<p class="date">{{ order.typeAsk }}: {{  order.DateDeliveryAsk  | date : 'MMM d'}} </p>
							<p>From 6PM</p>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="12">
						<div class="text-wrapper">
							<p>2 authorisations needed</p>
							<span class="red">Review</span>
						</div>
						<div class="icon arrow-right-red"></div>
					</ng-container>
				</container-element>
			</div>
		</a>
	</li>
</ul>