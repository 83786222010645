import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthorisationsBase } from '../../authorisations.base';
import { Authorisation } from 'src/app/models/user/authorisation.model';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { HttpClient } from '@angular/common/http';
import { CommandService } from 'src/app/services/common/command.service';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'user-authorisations-form-mobile',
  templateUrl: './authorisations-form.component.html',
  styleUrls: ['./authorisations-form.component.scss']
})
export class AuthorisationsFormMobileComponent extends AuthorisationsBase {
  authorizations: Authorisation[];
  authorisationsForm: FormGroup;
  redirectUrl: string;

  @Input() isInBookingProcess: boolean;

  constructor(
    commandService: CommandService,
    http: HttpClient,
    formBuilder: FormBuilder,
    confirmModalService: ConfirmModalService,
    accountSettingService: AccountSettingService,
    router: Router,
    alertService: AlertService,
    matDialog: MatDialog,
    
  ) {
    super(commandService,http, formBuilder,confirmModalService, accountSettingService,router, alertService, matDialog);
  }

}
