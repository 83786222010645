<ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
  <header>
    <div class="wrapper">
      <shared-logo-button [logoType]="logoTypeEnum.LogoGreen"></shared-logo-button>
    
      <a *ngIf="!store" href="https://blancliving.co/pages/dry-cleaning-prices" target="blanc" class="prices-btn">
        <img src="assets/icons/price-lighter.svg" alt="price tag">
        <span>Prices</span>
      </a>
    </div>

    <div class="contact-us" *ngIf="!store">
      <a routerLink="/help-feedback">Contact us</a>
    </div>
  </header>
</ng-template>


<ng-template #isDesktop>
  <header [ngClass]="{'booking': isBooking}">
    <shared-logo-button [logoType]="logoTypeEnum.LogoGreenWithText"></shared-logo-button>      
    
    <div class="wrapper">
      <a href="https://blancliving.co/pages/dry-cleaning-prices" target="blanc" class="prices-btn" [ngClass]="{'booking': isBooking}">
        <img src="assets/icons/price-lighter.svg" alt="price tag">
        <span>Prices</span>
      </a>
    
      <!-- <a *ngIf="!isAuthenticated  && !hideBtn" (click)="redirectToSignIn()" class="btn primary sign-btn" [id]="signIn">Sign in</a> -->
      <a *ngIf="isAuthenticated" (click)="signOut()" class="btn primary sign-btn" [id]="menuDeskSignOut">Sign out</a>
    </div>
  </header>
</ng-template>