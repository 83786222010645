import { CommonConstants } from 'src/app/constants/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Recurring } from './../../../models/user/recurring.model';
import { Component, OnInit,Output } from '@angular/core';

import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { OrderTrackingTypeEnum } from 'src/app/models/order/enums/order-tracking-type.enum';
import { ListService } from '../list.service';
import { StatusOrder } from 'src/app/models/order/enums/status-order.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { Routes } from 'src/app/constants/routes.constant';
import { StorageService } from 'src/app/services/common/storage.service';


@Component({
  selector: 'order-list-tabs',
  templateUrl: './list-tabs.component.html',
  styleUrls: ['./list-tabs.component.scss']
})
export class ListTabsComponent implements OnInit {

  isLoading: boolean = true;
  statistics:any;
  recurring: Recurring;
  fromParent:any
  fromParentPast: any;
  status : string
  statusOrder = new StatusOrder;
  redirectUrl: string;

  // constructor(private commandService: CommandService, public listservice :ListService,  public _deviceDetectorService: DeviceDetectorService) { }
  constructor(
    private commandService: CommandService,
    private route: ActivatedRoute,
    private router: Router,
    private storage: StorageService,
    public listservice: ListService
  ) { }
  ngOnInit() {
    
    this.commandService.executeCommand(CommandEnum.CustomerDetail)
    .then(response => {
      const address = response['ReturnObject']['address'];
      if (address.length !== 0) {
        this.storage.savePostcode(address[0]['postcode']);
      }
    });
    this.commandService.executeCommand(CommandEnum.OrderListCommand)
    .then(data => {
      var allStatus = this.statusOrder.getCurrentStatusOrder();
      var allStatusPast = this.statusOrder.getPastStatusOrder();
      var tCurrent = new Array ;
      var tPast = new Array ;
      var date_format, day , month;

      data.ReturnObject.forEach(function(item){

        if (allStatus[item.Status.toUpperCase()]) {
          item.Status = allStatus[item.Status.toUpperCase()];

          if (!OrderTrackingTypeEnum[item.TypeDelivery] ) {
                item.TypeDelivery = 'STORE';             
          }

          tCurrent.push(item);
        } else if(allStatusPast[item.Status.toUpperCase()]){
          var ua = navigator.userAgent.toLowerCase(); 
          var created_at = item.created_at;
          //if (ua.indexOf('safari') > -1 ||  ua === undefined ){
            created_at = item.created_at.replace(' ' ,'T');
          //}
          
          item.Status = allStatusPast[item.Status.toUpperCase()];
          date_format = new Date(created_at);
          
          month = date_format.getMonth() + 1;
          if (month.toString().length === 1)
          {
            month = "0" + month.toString();
          } else {
            month = month.toString();
          }

          if (date_format.getDate().toString().length === 1)
          {
            day = "0" + date_format.getDate().toString();
          }else {
            day = date_format.getDate().toString();
          }
          
          item.created_at = day + '.' + month + '.' + date_format.getFullYear().toString().substr(-2);
          tPast.push(item);
        }
      });

      this.fromParent = tCurrent;
      this.fromParentPast = tPast;

      this.statistics = tCurrent;
      this.listservice.setOrder(true); 
      this.isLoading = false;
    })
      
  }
  
}
