import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ScheduleService } from 'src/app/services/booking/schedule.service';
import { SlotService } from 'src/app/services/booking/slot.service';
import { TimeSlotTypeEnum } from 'src/app/models/booking/enums/time-slot-type.enum';
import { SlotsByPeriod } from 'src/app/models/booking/slots-by-period.model';
import { Slot } from 'src/app/models/booking/slot.model';
import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { DirectionEnum } from 'src/app/models/booking/enums/direction.enum';
import { ScheduleBase } from './schedule.base';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/services/common/alert.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { CommandService } from 'src/app/services/common/command.service';
import { PostCodeService } from 'src/app/services/shared/postCode.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonConstants } from 'src/app/constants/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'booking-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent extends ScheduleBase implements OnDestroy {
  @Input()
  type: TimeSlotTypeEnum = TimeSlotTypeEnum.Delivery;
  offset = 0;
  stepsEnum: typeof TimeSlotTypeEnum = TimeSlotTypeEnum;

  selectedSlot: TimeSlot;

  dates: moment.Moment[] = [];
  dates_delivery: moment.Moment[] = [];
  period_delivery: SlotsByPeriod = { days: [] };
  isMobile: boolean;
  period: SlotsByPeriod = { days: [] };
  pickupSlotSubscription: Subscription;
  deliverySlotSubscription: Subscription;
  deliverFromSubscription: Subscription;
  loading = false;
  loadingPickup = false;
  check = true;
  firstSlot: string;
  firstSlotAvai: any;
  _isIos: boolean;
  currentUrl: string;
  // pickupslot:TimeSlot;
  // deliveryslot:TimeSlot;
  constructor(
    private datePipe: DatePipe,
    protected schedules: ScheduleService,
    private route: ActivatedRoute,
    protected alertService: AlertService,
    protected commandService: CommandService,
    protected postCodeService: PostCodeService,
    protected storageService: StorageService,
    protected slotService: SlotService,
    private router: Router,
    protected deviceService: DeviceDetectorService,
    ) {
    super(schedules, slotService);
    this.isMobile = this.deviceService.isMobile();
    this.storageService.removeItem('period');
  }

  async ngOnInit() {
    
    if (this.router.url.search('deliveryonly') != -1) { 
      this.loading = true;
    }
    let redirect = this.route.snapshot.queryParams['redirect'];
    if( redirect && redirect.search('order/edit/finilize') == 1 && redirect.substr(-1) == '4' ){
      console.log(redirect.substr(-1) )
      const response = this.storageService.getObject('order_response');
      if (response && response.DeliveryDate) {
        const pickupObject = {
          from: response.PickupTimeSlot.split('-')[0].substr(0, 5),
          to: response.PickupTimeSlot.split('-')[1].substr(1, 6),
          date: response.PickupDate,
          ignore : true
        };
        
        await this.calculateSlotd(pickupObject);
      }
     
    }else {
      await this.initSlots(null);
    }

    if (this.deviceService.os === CommonConstants.DEVICES_OS.iOS) {
      this._isIos = true;
    } else {
      this._isIos = false;
    }
    super.ngOnInit();
    this.storageService.saveObject('paged',1);
    this.storageService.saveObject('paged_delivery',1);
    this.loadingPickup = false;
    let pickupslots;
    if (this.type === TimeSlotTypeEnum.Pickup) {
      
      this.pickupSlotSubscription = this.slotService.pickupSlot.subscribe(async (slot) => {
        this.loadingPickup = true;
        if (!slot) {
          return;
        }
        if (slot) { 
          this.selectedSlot = slot;
          this.selectedSlot.from = this.get24HourTime(this.selectedSlot.from);
          this.selectedSlot.to = this.get24HourTime(this.selectedSlot.to);
        
          if (!this.selectedSlot.from) {
            this.loadingPickup = false;
          }
        }
        // if (!slot?.ignore) {
        //   await this.initSlots(fa);
        //   this.loadingPickup = false;
        // }
      });
    }
    if (this.type === TimeSlotTypeEnum.Delivery) {
      this.deliverySlotSubscription = this.slotService.deliverySlot.subscribe(async (slot) => {
        this.loadingPickup = true;
        if (!slot) {
          return;
        }
        if (slot ) {
          this.selectedSlot = slot;
          this.selectedSlot.from = this.get24HourTime(this.selectedSlot.from);
          this.selectedSlot.to = this.get24HourTime(this.selectedSlot.to);
          
          this.loading = true;
          if (!this.selectedSlot.from && !this.selectedSlot.to ) {
            this.loadingPickup = false;
          }
        }
        // if (!slot?.ignore) {
        //   //await this.initSlots(this.selectedSlot?.date);
        //   await this.initSlots(fa);
        // }
      });
      this.deliverFromSubscription = this.slotService.deliverFrom.subscribe(async (slot) => {
        if (slot) {
          this.loading = false;
          this.loadingPickup = false;
    
        }
        this.calculateSlotd(slot)
      });
    }
    var date = moment().add(1, 'days');
    
    if (this.type == TimeSlotTypeEnum.Pickup) {
      var allSlots = this.storageService.getObject('period_pickup');
    } else if (this.type == TimeSlotTypeEnum.Delivery) {
      var allSlots = this.storageService.getObject('period_delivery');
    }
    if (!Array.isArray(allSlots.days)&& allSlots) {
      allSlots = {days:[]};
    }
    this.firstSlotAvailable(allSlots, date.format("YYYY-MM-DD"));
    if (this.firstSlotAvai) {
      // await this.initSlots(this.toMoment(this.firstSlotAvai.date));
      const first = allSlots.days.find((obj) => {
        return obj == this.firstSlotAvai;
      })
      let i = allSlots.days.indexOf(first);
      if (this.type == TimeSlotTypeEnum.Pickup) {
        this.period.days = allSlots.days.slice(i, i + 4)
        this.dates = [];
              
        this.period.days.forEach(element => {
          var date = moment(element.date);
          this.dates.push(date);
        });
      } else if (this.type == TimeSlotTypeEnum.Delivery) {
        this.period_delivery.days = allSlots.days.slice(i, i + 4)
        this.dates_delivery = [];
          this.check = false;    
        this.period_delivery.days.forEach(element => {
          var date = moment(element.date);
          this.dates_delivery.push(date);
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.pickupSlotSubscription?.unsubscribe();
    this.deliverySlotSubscription?.unsubscribe();
    this.deliverFromSubscription?.unsubscribe();
  }
  firstSlotAvailable(daysSlot,fdate) {
    var self = this;
    let i;
    if (fdate) {
      const first = daysSlot.days.find((obj) => {
        return obj.date === fdate;
      })
      i = daysSlot.days.indexOf(first);
    } else {
      i = 0
    }
    if(daysSlot.days.length > 0){
      for (let index = i; index < daysSlot.days.length; index++) {
        if (daysSlot.days[index]) {
          for (let j = 0; j < daysSlot.days[index].slots.length; j++) {
            if (daysSlot.days[index].slots[j].available == true) {
              self.firstSlot = daysSlot.days[index].date
              self.firstSlotAvai = daysSlot.days[index];
              return ;
            }
          }
        } else {
          console.log('no date!')
        }
      }
    } else {
      return; 
    }
  }
  lastDate(): moment.Moment {
    var date = this.period.days[this.period.days.length - 1].date;
    var moment = this.toMoment(date);
    return moment;
  }
  firstDay(): moment.Moment {
    var date = this.period.days[0].date;
    var moment = this.toMoment(date);
    return moment;
  }
  async moveForward() {
    // var lastDate = this.lastDate();
    var index, pas;
    if (this.isMobile) {
      pas = 1;
    } else {
      pas = 4;
    }
    if (this.type == TimeSlotTypeEnum.Pickup) {
      var paged = this.storageService.getObject('paged');
      var period = this.storageService.getObject('period_pickup');
      if (paged == '0' || !paged) {
        index = 1;
        this.storageService.saveObject('paged', index);
      } else {
        index = parseInt(paged);
        this.storageService.saveObject('paged', index + 1);
      }
      // let last = this.toMoment(period.days[index * pas].date);
      this.period.days = period.days.slice(index*pas, index*pas + pas)
      this.dates = [];
      
      this.period.days.forEach(element => {
        var date = moment(element.date);
        this.dates.push(date);
      });
    } else if (this.type == TimeSlotTypeEnum.Delivery) {
      var paged_delivery = this.storageService.getObject('paged_delivery');
      var period = this.storageService.getObject('period_delivery');
      if (paged_delivery == '0' || !paged_delivery) {
        index = 1;
        this.storageService.saveObject('paged_delivery', index);
      } else {
        index = parseInt(paged_delivery);
        this.storageService.saveObject('paged_delivery', index + 1);
      }
      // let last = this.toMoment(period.days[index * pas].date);
      this.period_delivery.days = period.days.slice(index*pas, index*pas + pas)
      this.dates_delivery = [];
      
      this.period_delivery.days.forEach(element => {
        var date = moment(element.date);
        this.dates_delivery.push(date);
      });
    }
    // await this.fetchBetween(last, this.daysToShow, DirectionEnum.Forward);
  }
  async moveBackward() {
    var index, pas;
    if (this.isMobile) {
      pas = 1;
    } else {
      pas = 4;
    }
    if (this.type == TimeSlotTypeEnum.Pickup) {
      var paged = this.storageService.getObject('paged');
      var period = this.storageService.getObject('period_pickup');
      if (paged) {
        index = parseInt(paged);
        this.storageService.saveObject('paged',index-1)
      } else {
        index = 1;
        this.storageService.saveObject('paged',index)
      }
      // let last = this.toMoment(period.days[index * pas].date);
      this.period.days = period.days.slice(index*pas - pas, index*pas)
      this.dates = [];
      
      this.period.days.forEach(element => {
        var date = moment(element.date);
        this.dates.push(date);
      });
    } else if (this.type == TimeSlotTypeEnum.Delivery) {
      var paged_delivery = this.storageService.getObject('paged_delivery');
      var period = this.storageService.getObject('period_delivery');
      if (paged_delivery) {
        index = parseInt(paged_delivery);
        this.storageService.saveObject('paged_delivery',index-1)
      } else {
        index = 1;
        this.storageService.saveObject('paged_delivery',index)
      }
      // let last = this.toMoment(period.days[index * pas].date);
      this.period_delivery.days = period.days.slice(index*pas - pas, index*pas)
      this.dates_delivery = [];
      
      this.period_delivery.days.forEach(element => {
        var date = moment(element.date);
        this.dates_delivery.push(date);
      });
    }
  }
 
  async initSlots(start) {
    if (start) {
      // var startDate = moment(start);
      // await this.fetchBetweenSlots(start, this.daysToShow, DirectionEnum.Forward);
      
      await this.fetchBetweenSlots(start, 40, DirectionEnum.Forward);
    } else {
      let slot = this.storageService.getObject('selected_pickup_slot');
      if (!slot) {
        let order_response = this.storageService.getObject('order_response');
        // slot = order_response.date
      }
      if (slot && this.type == TimeSlotTypeEnum.Delivery) {
        await this.fetchBetweenSlots( this.toMoment(slot), 20 , DirectionEnum.Forward);
      } else {
        if(this.router.url.search('deliveryonly') != -1){
          var nb_days = await this.calculateSlotd_deliveryonly();
          var tomorrow = moment().add(nb_days, 'days');
          await this.fetchBetweenSlots(tomorrow, 20 , DirectionEnum.Forward);
        }else{
          var tomorrow = moment().add(1, 'days');
          await this.fetchBetweenSlots(tomorrow, 20 , DirectionEnum.Forward);
        }
      }  
    }
  }
  
  async fetchBetweenSlots(date: moment.Moment, daysToDisplay, direction: DirectionEnum) {
    var commandResponse;
    switch (direction) {
      case DirectionEnum.Backward:
        commandResponse = await this.schedules.slotsBackword(date, daysToDisplay , this.selectedSlot?.date);
        this.offset = 0;
        break;
      case DirectionEnum.Forward:
        
        commandResponse = await this.schedules.slotsForward(date, daysToDisplay, this.selectedSlot?.date);
        this.offset = 0;
        break;
    }
    
    // let testSlots = this.storageService.getObject('period')
    // if (!testSlots) {
    if (this.type == TimeSlotTypeEnum.Pickup) {
      this.period = commandResponse;
      this.storageService.saveObject('period_pickup', commandResponse);
      this.dates = [];
      this.period.days.forEach(element => {
        var date = moment(element.date);
        this.dates.push(date);
      });
    } else if (this.type == TimeSlotTypeEnum.Delivery) {
      this.period_delivery = commandResponse;
      this.storageService.saveObject('period_delivery', commandResponse);
      this.dates_delivery = [];
      this.period_delivery.days.forEach(element => {
        var date = moment(element.date);
        this.dates_delivery.push(date);
      });
    }
    // }
  }
  get24HourTime(amPmString: string) {
    var output = [amPmString.slice(0, amPmString.indexOf("M") - 1), " ", amPmString.slice(amPmString.indexOf("M") - 1)].join('');
    return this.datePipe.transform("1/1/2013 " + output, 'HH:mm').toString();
  }
  selected(date: string, slot: Slot) {

    var result = (
      this.selectedSlot?.date === date &&
      this.selectedSlot?.from === slot.from &&
      this.selectedSlot?.to === slot.to)
    return result;
  }
  select(date: string, slot: Slot) {
    if (slot && slot.available) {
      this.selectedSlot = { date: date, from: slot.from, to: slot.to, ignore: true };
      if (this.type === TimeSlotTypeEnum.Pickup) {
        this.slotService.setPickupSlot(this.selectedSlot);
        this.slotService.setDeliveryFromSlot(this.selectedSlot);
      }
      if (this.type === TimeSlotTypeEnum.Delivery) {
        this.slotService.setDeliverySlot(this.selectedSlot);
        this.slotService.setDeliveryFromSlot(null);
      }
    }
  }
  getSundays(date:string) {
    var sun = new Array();
    var d = new Date(date);
    var d1 = new Date(date);
    var d2 = new Date(d1.setMonth(d1.getMonth()+1));
    var dates = [d,d2];
    var day,month;
    dates.forEach(function(date){
      var getTot = new Date(date.getMonth(),date.getFullYear(), 0).getDate();
      for(var i=1;i<=getTot;i++){    //looping through days in month
          var newDate = new Date(date.getFullYear(),date.getMonth(),i)
          if(newDate.getDay()==0){   //if Sunday
            if (moment(newDate).isAfter(new Date() ) ) {
              if (i < 10) {
                day = '0' + i;  
              }else{
                day = i;
              }
              if ((date.getMonth()+1) < 10) {
                month = '0' + (date.getMonth() + 1);
              }else{
                month = date.getMonth() + 1;
              }
              sun.push( new Date(date.getFullYear() + '-' + month + '-' + day).getTime() );
            } 
          }
      }
    })
    return sun;
  }
  async calculateSlotd(slot){
    var date = moment();
          
    if (slot) {
      date = this.toMoment(slot.date);
    }

    var day = date.format("ddd");
  
    var allSlots = this.storageService.getObject('period_delivery');
    if (!allSlots || (allSlots && !Array.isArray(allSlots.days))) {
      allSlots = {days:[]};
    }
    var holidays = new Array;
    var holi,pickupslots;
    await this.postCodeService.getHolidays().then(
    (results) => {
      pickupslots = this.slotService.pickupSlot.getValue();
      
      holidays = this.getSundays(pickupslots.date);
      results['ReturnObject'].forEach(function(item){
        holidays.push( new Date (item.date).getTime())
      })

      if(holidays){
        var date_d = new Date (pickupslots.date).getTime();
        var iteration = 0;
        do {
          date_d = date_d + (1000 * 60 * 60 * 24);
          if ( !holidays.includes(date_d) ) {
            iteration++;
          }
        } while (iteration !== 3 );
      }

      holi = ( date_d - new Date (pickupslots.date).getTime() ) / (1000 * 60 * 60 * 24);
      });
    
    console.log('holi', holi)
    if(holi>3){
      var fDate = this.addDays(date, holi);
    } else {
      if(day == "Sat" || day == "Fri" ){
        var fDate = this.addDays(date, 4);
      } 
      else {
        var fDate = this.addDays(date, 3);
      }
    }
    this.storageService.saveObject('selected_pickup_slot',fDate);
    this.firstSlotAvailable(allSlots, fDate.format("YYYY-MM-DD"));
    if (this.firstSlot) {
      var fa =  this.toMoment(this.firstSlot);
    } else {
      var fa = fDate;
    }
    await this.initSlots(fa);
    this.loading = true;
    this.check = false;
  }

  async calculateSlotd_deliveryonly(){
    var date = moment();    
    var day = date.format("ddd");
    var allSlots = {days:[]};
    var holidays = new Array;
    var holi,pickupslots;

    await this.postCodeService.getHolidays().then(
    (results) => {
      
      holidays = this.getSundays(new Date().toString());
      results['ReturnObject'].forEach(function(item){
        holidays.push( new Date (item.date).getTime())
      })

      if(holidays){
        var date_d = new Date ().getTime();
        var iteration = 0;
        do {
          date_d = date_d + (1000 * 60 * 60 * 24);
          if ( !holidays.includes(date_d) ) {
            iteration++;
          }
        } while (iteration !== 3 );
      }

      holi = ( date_d - new Date ().getTime() ) / (1000 * 60 * 60 * 24);
      });
    console.log('holi', holi)
    if(holi>3){
      return holi;
    } else {
      return 3;
    }
  }
}
