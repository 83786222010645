import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { CardService } from 'src/app/services/user/card.service';
import { CardResponse } from 'src/app/models/user/card-response.model';
import { CardStateEnum } from 'src/app/models/user/enums/card-state.enum';
import { CardTypeEnum } from 'src/app/models/user/enums/card-type.enum';
import { Routes } from 'src/app/constants/routes.constant';
import { AuthService } from 'src/app/services/user/auth.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { User } from 'src/app/models/user/user.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { UserComponent } from '../../user.component';
import { AnyARecord } from 'dns';
import { CardResponseStripe } from 'src/app/models/user/card-stripe.model';

@Component({
  selector: 'user-payment-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss'],
})
export class CardViewComponent implements OnInit {
  isLoading: boolean = true;
  isInBooking: boolean;
  isMobile: boolean;
  cardType: typeof CardTypeEnum = CardTypeEnum;
  btnCssClasses: string;
  // card: CardResponse;
  card: CardResponseStripe;
  user;
  editPaymentCard: string;

  constructor(
    private cardService: CardService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private blancCommandService: BlancCommandService,
  ) {
    this.isMobile = this.deviceService.isMobile();
    if (this.router.url.indexOf(Routes.BOOKING_REGULAR.ROOT) > -1) {
      this.isInBooking = true;
    }
  }

  async ngOnInit() {
    this.btnCssClasses = this.isMobile && !this.isInBooking ? ' fixed-top' : ' absolute-right';
    await this.getuser();
    await this.initCard();

    let verify = localStorage.getItem('first-journey')
    let smsMarketing = localStorage.getItem('smsMarketing')
    if ( verify === 'true'){
      this.editPaymentCard = "first-journey-booking-summary-editPayment";
    }else if (smsMarketing){
      this.editPaymentCard = "sms-marketing-booking-summary-editPayment";
    }else{
      this.editPaymentCard = "returning-user-booking-summary-editPayment";
    }
  }

  // getCardInfoType = () => this.card ? this.card.infoType : null;
  getCardInfoType = () => this.card ? this.card.type : null;

  async initCard() {

    this.blancCommandService.executeCommand(CommandEnum.GetCustomerCard)
    .then(response => {
      let card = response['ReturnObject']['card'];
      if (card.Actif != '0') {
        this.card = card;
        this.card.cardNumber = card.cardNumber.substr(-5);
      } 
    })
    .catch(err => {
      this.cardService.setState(CardStateEnum.AddCard);
      
    })
    .finally(() => { this.isLoading = false; });
    
  }

  edit() {
    if (this.router.url.indexOf(Routes.BOOKING_REGULAR.SUMMARY) > -1) {
      this.router.navigate([Routes.BOOKING_REGULAR.PAYMENT]);
    } else {
      this.cardService.setState(CardStateEnum.AddCard);
      localStorage.setItem('Edit', JSON.stringify({
        'edit': 'true'
      }));
    }
  }
  getuser(){
    this.blancCommandService.executeCommand(CommandEnum.CustomerDetail)
    .then(response => {
      const user = {
        firstName : response['ReturnObject']['infoCustomer']['FirstName'],
        lastName : response['ReturnObject']['infoCustomer']['LastName']
      };
      this.user = user;
    });
  }
}