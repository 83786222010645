<div class="banner">
    <div class="left-wrapper">

        <p class="label">Item(s)</p>
        <p class="count" *ngIf = "order.items.length == 0">-</p>
        <p class="count" *ngIf = "order.items.length != 0">{{order.items.length}}</p>
    </div>

    <img src="assets/icons/line-dashed-white.svg" class="line">
    <div class="right-wrapper">
        <p class="label">Total (incl. VAT & Discounts)</p>
        <p *ngIf = "order.Total == 0" class="price">-</p>
        <p *ngIf = "order.Total != 0" class="price">£{{order.Total}}</p>
    </div>

</div>