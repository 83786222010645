import { OrderStatusEnum } from 'src/app/models/order/enums/order-status.enum';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { SummaryAddress } from 'src/app/models/booking/summary-address.model';
import { CommandService } from 'src/app/services/common/command.service';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { EditEnum } from 'src/app/models/booking/enums/edit.enum';
import { Routes } from 'src/app/constants/routes.constant';
import { SlotService } from 'src/app/services/booking/slot.service';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { HttpStatusCode } from 'src/app/models/common/enums/http-status-code';
import { OrderTrackingTypeEnum } from 'src/app/models/order/enums/order-tracking-type.enum';
import { OrderDetails } from 'src/app/models/order/order-details.model';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'src/app/services/common/storage.service';
import { BookingAddressData } from 'src/app/models/booking/address-data.model';
import { AdminRoutingModule } from 'src/app/admin/admin-routing.module';
import * as moment from 'moment';
import { ServiceWrapperModel } from 'src/app/models/particle/service-wrapper.model';
import { ParticleService } from 'src/app/services/particle/particle.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public editEnum: typeof EditEnum = EditEnum;
  services: ServiceWrapperModel[] = [];
  editable: boolean;
  orderId: string;
  dateFormat: string = 'ddd DD/MM';
  deliverySlot: any;
  pickupSlot: any;
  deliverTo: string;
  address: SummaryAddress;
  instructions: Instructions = new Instructions();
  disabled: boolean;
  hasInstructions: boolean;
  ordertype: string;
  pickup: string;
  delivery: string;
  Instore: boolean;
  Edit_delivery: boolean;
  Edit_pickup: boolean;
  Edit_deliveryInstruction: boolean;
  working = false;
  DeliveryDate : Date;
  PickupDate : Date;
  order : OrderDetails;
  isRecurring : boolean = false;
  myDate : any;
  myDate_pickup : any
  Edit_delivery_PickupUpcoming : boolean = false;
  cookieService: CookieService;

  orderMobileEditPickup: string = "order-mobile-editPickup";
  orderMobileEditDelivery: string = "order-mobile-editDelivery";
  orderMobileEditAddress: string = "order-mobile-editAddress";
  orderMobileEditDeliveryInst: string = "order-mobile-editDeliveryInst";

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected commandService: CommandService,
    protected slotService: SlotService,
    protected stepService: BookingStepsService,
    protected storageService: StorageService,
    protected particleService: ParticleService,
  ) { }

  async ngOnInit() {
    this.orderId = this.route.snapshot.paramMap.get('id');
    localStorage.setItem('orderid',this.orderId);
    this.ordertype = this.route.snapshot.paramMap.get('type');
    await this.initData();
  }

  // TODO: Add proper tests.DELIVERED TO STORE
  async initData() {
    if (this.ordertype == OrderTrackingTypeEnum['STORE']) {
      this.pickup = "Drop-off";
      this.delivery = "Collection";
      this.Instore = true;
    }
    else if (this.ordertype == OrderTrackingTypeEnum['DELIVERY']) {
      this.pickup = "Pick up";
      this.delivery = "Delivery";
      this.Instore = false;
    }
    else if (this.ordertype == OrderTrackingTypeEnum['Drop-off and Delivery details']) {
      this.pickup = "Drop-off";
      this.delivery = "Delivery";
      this.Instore = false;
    } else {
      this.pickup = "Drop-off";
      this.delivery = "Collection";
      this.Instore = true;
    }

    var param = {
      infoOrder_id : +this.orderId,
    }
    if (this.Instore === false) {
      const deliveryOrder = await this.commandService.executeCommand(CommandEnum.GetDeliveryByOrder, param);
      
      this.DeliveryDate = deliveryOrder.ReturnObject?.DeliveryDate;
      this.PickupDate = deliveryOrder.ReturnObject?.PickupDate;
      this.deliverySlot = deliveryOrder.ReturnObject.DeliveryTimeSlot;
      this.pickupSlot = deliveryOrder.ReturnObject.PickupTimeSlot;
      if (this.storageService.getObject('adresse') && this.storageService.getObject('adresse').instructions?.deliverToName) {

        this.instructions = new Instructions(
          this.storageService.getObject('adresse').instructions?.deliverToId,
          this.storageService.getObject('adresse').instructions?.deliverToName?.toLowerCase(),
          this.storageService.getObject('adresse').instructions?.countryCode,
          this.storageService.getObject('adresse').instructions?.phoneNumber,
          this.storageService.getObject('adresse').instructions?.other,
        );
      } else if(deliveryOrder.ReturnObject && deliveryOrder.ReturnObject?.DeliveryaskID ) {
        this.instructions = new Instructions(
          deliveryOrder.ReturnObject?.DeliveryaskID,
          deliveryOrder.ReturnObject?.PickupTypeDelivery,
          deliveryOrder.ReturnObject?.PickupCodeCountry,
          deliveryOrder.ReturnObject?.PickupPhoneNumber,
          deliveryOrder.ReturnObject?.DeliveryComment,
        );
      
        this.instructions.other = deliveryOrder.ReturnObject?.DeliveryComment;
      }
      this.hasInstructions = (this.instructions?.deliverToName) ?true:false;
      if(this.storageService.getObject('adresse') === undefined){
        let adr = new BookingAddressData(
          deliveryOrder.ReturnObject.DeliveryAddress1,
          deliveryOrder.ReturnObject.DeliveryAddress2,
          deliveryOrder.ReturnObject.DeliveryTown,
          deliveryOrder.ReturnObject.DeliveryPostcode,
          this.instructions
        )
      this.storageService.saveObject('adresse', adr);
      }
      
      var A = JSON.parse(localStorage.getItem('adresse'))
      this.address = new SummaryAddress(
        A?.addressLine1,
        A?.addressLine2,         
        A?.postcode,
        A?.town,
      );
  
      localStorage.setItem('Pickup_ID',deliveryOrder.ReturnObject.PickupID);
      localStorage.setItem('Deliveryask_ID',deliveryOrder.ReturnObject.DeliveryaskID);

      const orderDetails = await this.commandService.executeCommand(CommandEnum.OrderDetailsCommand, param);
      const result = await this.particleService.getParticleGarment(orderDetails.ReturnObject.GarmentInstruction);
      localStorage.setItem('GarmentInstructionsFull',JSON.stringify(result));
      let dB = JSON.parse(orderDetails.ReturnObject.GarmentInstruction).DonateBags;
      if(dB){
        let storedData = JSON.parse(localStorage.getItem('adresse'));
        storedData.donateBags = parseInt(dB, 10); 
        localStorage.setItem('adresse', JSON.stringify(storedData));
      }
      this.services = orderDetails.GarmentInstruction;
      this.editable = orderDetails.ReturnObject.CanBeCancelled;
      this.isRecurring = orderDetails.ReturnObject.Status === 'RECURRING';

      if (this.pickupSlot && this.deliverySlot && this.pickupSlot.trim() != '-' && this.deliverySlot.trim() != '-') {
        var t1 = '';
        var t2 = '';
        t2 = this.pickupSlot.substring(8,10);
        t1 = this.pickupSlot.substring(0,2);
        if (t1.includes('08') || t1.includes('10')) {
          if (t1 == '08') {
            t1 = '8';
          }
          t1 = t1 + 'am';
        }else {
          switch (t1) {
            case '14':
              t1='2';
              break;
            case '16':
              t1='4';
              break;
            case '16':
              t1='4';
              break;
            case '18':
              t1='6';
              break;
            case '20':
              t1='8';
              break;
          }
          t1 = t1 + 'pm';
        }
        if (t2.includes('08') || t2.includes('10')) {
          if (t1 == '08') {
            t1 = '8';
          }
          t2 = t2 + 'am';
        }else {
          switch (t2) {
            case '14':
              t2='2';
              break;
            case '16':
              t2='4';
              break;
            case '16':
              t2='4';
              break;
            case '18':
              t2='6';
              break;
            case '20':
              t2='8';
              break;
          }
          t2 = t2 + 'pm';
        }
        this.pickupSlot= t1 +' - '+ t2 ;
        t2 = this.deliverySlot.substring(8,10);
        t1 = this.deliverySlot.substring(0,2);
        if (t1.includes('08') || t1.includes('10')) {
          if (t1 == '08') {
            t1 = '8';
          }
          t1 = t1 + 'am';
        }else {
          switch (t1) {
            case '14':
              t1='2';
              break;
            case '16':
              t1='4';
              break;
            case '16':
              t1='4';
              break;
            case '18':
              t1='6';
              break;
            case '20':
              t1='8';
              break;
          }
          t1 = t1 + 'pm';
        }
        if (t2.includes('08') || t2.includes('10')) {
          if (t1 == '08') {
            t1 = '8';
          }
          t2 = t2 + 'am';
        }else {
          switch (t2) {
            case '14':
              t2='2';
              break;
            case '16':
              t2='4';
              break;
            case '16':
              t2='4';
              break;
            case '18':
              t2='6';
              break;
            case '20':
              t2='8';
              break;
          }
          t2 = t2 + 'pm';
        }
        this.deliverySlot= t1 +' - '+ t2 ;
      }
      // if (this.DeliveryDate === undefined || this.DeliveryDate === null) {
      //   this.myDate = new Date("2020-01-01");
      // } else {
        // this.myDate = new Date(this.DeliveryDate.toString());
      // }

      // if (this.PickupDate == undefined || this.PickupDate === null) {
      //   this.myDate_pickup = new Date("2020-01-01");;
      // } else {
        // this.myDate_pickup = new Date(this.PickupDate.toString());
      // }

      if (this.PickupDate && this.DeliveryDate) {
        this.myDate = new Date(this.DeliveryDate.toString());
        this.myDate_pickup = new Date(this.PickupDate.toString());
      }else {
        this.myDate = (orderDetails.ReturnObject?.DateDeliveryAsk == '2020-01-01') ? '' : new Date(orderDetails.ReturnObject?.DateDeliveryAsk.toString());
        this.myDate_pickup = (orderDetails.ReturnObject?.DatePickup == '2020-01-01') ? '' : new Date(orderDetails.ReturnObject?.DatePickup.toString());
        
      }

      const current_date = new Date();
      // const timestamp_current = current_date.getTime();
      // const timestamp_delivery = this.myDate.getTime();

      // let myDate_pickup_str = this.myDate_pickup.toDateString();
      // const current_date_str = new Date().toDateString();
      const cancellationDueDate = new Date(this.myDate_pickup - 24 * 60 * 60 * 1000 );
      cancellationDueDate.setHours(15, 0, 0);
      const cancellationDueDateDelivery = new Date(this.myDate - 24 * 60 * 60 * 1000 );
      cancellationDueDateDelivery.setHours(15, 0, 0);
      if (moment(current_date).isBefore(cancellationDueDate) && !this.isRecurring) {
        this.Edit_pickup = true;
      } else {
        this.Edit_pickup = false;
      }
      if ( moment(current_date).isBefore(cancellationDueDateDelivery) && !this.isRecurring) {
        this.Edit_delivery = true;
      } else {
        this.Edit_delivery = false;
      }
      if (!this.Edit_pickup && !this.Edit_delivery) {
        this.Edit_delivery_PickupUpcoming = false;
      } else if (!this.Edit_pickup && this.Edit_delivery) {
        this.Edit_delivery_PickupUpcoming = false;
        this.Edit_delivery = true;
      } else {
        this.Edit_delivery_PickupUpcoming = true;
        this.Edit_delivery = false;
      }

      if (moment(current_date).isBefore(cancellationDueDate)|| moment(current_date).isBefore(cancellationDueDateDelivery) ){
        this.Edit_deliveryInstruction = true;
      } else {
        this.Edit_deliveryInstruction = false;
      }
      
      this.working = true;
      
    } else {
      const orderDetails = await this.commandService.executeCommand(CommandEnum.OrderDetailsCommand, param);
      this.order = orderDetails.ReturnObject;
      this.myDate = this.order.DateDeliveryAsk;
      this.myDate_pickup = this.order.DatePickup;
      this.editable = false;
      this.Edit_delivery = false;
      this.Edit_pickup = false;
      this.deliverySlot = { date: this.order.DateDeliveryAsk, from: "", to: "", ignore: false };
      this.pickupSlot = { date: this.order.DatePickup, from: "", to: "", ignore: false };
      this.hasInstructions = false;
      this.working = true;
    }
  }

  async edit(type: number) {
    const redirectUrl = Routes.ORDER.EDITFINALIZE + this.orderId + '/' + this.ordertype + '/' + type;
    const navigationExtras = { queryParams: { redirect: redirectUrl } };
    switch (type) {
      case EditEnum.Address:
        this.stepService.setStep(BookingStepsEnum.Address);
        this.router.navigate(
          [Routes.BOOKING_REGULAR.ADDRESS],
          navigationExtras
        );
        break
      case EditEnum.Pickup:
        this.slotService.setPickupSlotVisibility(true);
        this.slotService.setDeliverySlotVisibility(false);
        this.stepService.setStep(BookingStepsEnum.Pickup);
        this.router.navigate(
          [Routes.BOOKING_REGULAR.SLOTS],
          navigationExtras
        );
        break
      case EditEnum.Delivery:
      case EditEnum.PickupUpcoming:
        this.stepService.setStep(BookingStepsEnum.Delivery);
        this.slotService.setPickupSlotVisibility(false);
        this.slotService.setDeliverySlotVisibility(true);
        this.router.navigate(
          [Routes.BOOKING_REGULAR.SLOTS],
          navigationExtras
        );
        break
      case EditEnum.Instruction:
        this.stepService.setStep(BookingStepsEnum.Instructions);
        this.router.navigate(
          [Routes.BOOKING_REGULAR.Instructions],
          navigationExtras
        );
        break
    }
  }
  
}