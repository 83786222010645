import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DetailsComponent } from '../details.component';
import { CommandService } from 'src/app/services/common/command.service';
import { AlertService } from 'src/app/services/common/alert.service';

@Component({
  selector: 'order-details-desktop',
  templateUrl: './details-desktop.component.html',
  styleUrls: ['./../details.component.scss', './details-desktop.component.scss']
})
export class DetailsDesktopComponent extends DetailsComponent implements OnInit {
  cancelOrdeDesktop : string = "order-desk-cancel-booking";
  
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected commandService: CommandService,
    protected alertService: AlertService,
    protected deviceService: DeviceDetectorService,
  ) {
    super(route, router, commandService, alertService, deviceService)
  }

  async ngOnInit() {
    super.initData();
  }

}
