import { AuthorisationsFormDesktopComponent } from 'src/app/user/authorisations/authorisations-desktop/authorisations-form/authorisations-form.component';
import { StorageService } from './../../../services/common/storage.service';
import { ParticleService } from './../../../services/particle/particle.service';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { PreferencesBase } from '../preferences.base';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'user-preferences-desktop',
  templateUrl: './preferences-desktop.component.html',
  styleUrls: ['./../preferences.component.scss', './preferences-desktop.component.scss'],
})
export class PreferencesDesktopComponent extends PreferencesBase {

  public instIsValid: boolean;
  public hasInstructions: boolean;
  authChanged:boolean=false;

  constructor(
    accountSettingService: AccountSettingService,
    formBuilder: FormBuilder,
    router: Router,
    public commandService: CommandService,
    confirmModalService: ConfirmModalService,
    authService: AuthService,
    alertService: AlertService,
    public particleService: ParticleService,
    protected storageService: StorageService,
    matDialog: MatDialog,
    public deviceService: DeviceDetectorService

  ) {
    super(accountSettingService, formBuilder, router, commandService,confirmModalService, authService, alertService, particleService, storageService,matDialog,deviceService);
  }

  setOutput(output) {
    this.instIsValid = output.confirmInstruction;
    this.instClear = output.clearInstruction;
  }
  setAuthorisation(output){
    this.authChanged =output.authorisationsFormChanged;
  }
}
