import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from 'src/app/services/guards/auth-guard.service';
import { PendingChangesGuard as ChangesGuard } from '../services/guards/pending-changes-guard.service';
import { LandingGuardService as LandingGuard } from 'src/app/services/guards/landing-guard.service';
import { UserComponent } from './user.component';
import { LandingComponent } from './landing/landing.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthorisationsComponent } from './authorisations/authorisations.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { PaymentsComponent } from './payments/payments.component';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';
import { RecurringComponent } from './recurring/recurring.component';
import { HelpFeedbackComponent } from './help-feedback/help-feedback.component';
import { PreferencesStoreComponent } from '../booking/preferences-store/preferences-store.component';
import { CardConfirmationComponent } from './payments/card-confirmation/card-confirmation.component';


const routes: Routes = [
  {
    path: '',
    component: UserComponent,
    children: [
      {
        path: '',
        component: LandingComponent,
        canActivate: [LandingGuard],
      },
      {
        path: 'personal-info',
        component: PersonalInfoComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ChangesGuard],
      },
      {
        path: 'card-confirmation',
        component: CardConfirmationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ChangesGuard],
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ChangesGuard],
      },
      {
        path: 'authorisations',
        component: AuthorisationsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ChangesGuard],
      },
      
      {
        path: 'preferences',
        component: PreferencesComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ChangesGuard],
      },
      {
        path: 'payments',
        component: PaymentsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ChangesGuard],
      },
      {
        path: 'refer',
        component: ReferFriendComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'recurring',
        component: RecurringComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'help-feedback',
        component: HelpFeedbackComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }