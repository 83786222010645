import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EditComponent } from '../edit.component';
import { CommandService } from 'src/app/services/common/command.service';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { SlotService } from 'src/app/services/booking/slot.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { ParticleService } from 'src/app/services/particle/particle.service';

@Component({
  selector: 'order-edit-desktop',
  templateUrl: './edit-desktop.component.html',
  styleUrls: ['./../edit.component.scss', './edit-desktop.component.scss']
})
export class EditDesktopComponent extends EditComponent implements OnInit {

  orderDeskEditPickup : string
  orderDeskEditDelivery : string
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected commandService: CommandService,
    protected slotService: SlotService,
    protected stepService: BookingStepsService,
    protected storageService: StorageService,
    protected particleService: ParticleService,
  ) {
    super(router, route, commandService, slotService, stepService,storageService,particleService)
  }

  async ngOnInit() {
    super.ngOnInit();
    
    let verify = localStorage.getItem('first-journey')
    if( verify === 'true'){
      this.orderDeskEditPickup ="first-journey-booking-summary-editPickup";
      this.orderDeskEditDelivery = "first-journey-booking-summary-editDelivery";
    }else if(this.router.url.includes('Pick%20up%20&%20Delivery%20details')){
      this.orderDeskEditPickup ="order-desk-editPickup";
      this.orderDeskEditDelivery = "order-desk-editDelivery";
    }else{
      this.orderDeskEditPickup ="returning-user-booking-summary-editPickup";
      this.orderDeskEditDelivery = "returning-user-booking-summary-editDelivery";
    }
    
  }

}
