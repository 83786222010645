import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService as AuthGuard } from 'src/app/services/guards/auth-guard.service';

import { BookingComponent } from './booking.component';
import { SlotsComponent } from './slots/slots.component';
import { AddressComponent } from './address/address.component';
import { LandingComponent } from './instructions/landing/landing.component';
import { ServicesComponent } from './instructions/services/services.component';
import { ServiceComponent } from './instructions/services/service/service.component';
import { SummaryComponent } from './summary/summary.component';
import { PaymentComponent } from './payment/payment.component';
import { AuthorisationsComponent } from './authorisations/authorisations.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ProvisionComponent } from './provision/provision.component';
import { InstructionsModalComponent } from './address/address-mobile/instructions-modal/instructions-modal.component';
import { DonateModalComponent } from './address/address-mobile/donate-modal/donate-modal.component';
import { PreferencesStoreComponent } from './preferences-store/preferences-store.component';

const routes: Routes = [
  {
    path: '',
    component: BookingComponent,
    children: [
      {
        path: '',
        redirectTo: 'provision',
        pathMatch: 'full',
      },
      {
        path: 'provision',
        component: ProvisionComponent,
      },
      {
        path: 'provision/:postcode',
        component: ProvisionComponent,
      },
      {
        path: 'provision/:postcode/external',
        component: ProvisionComponent,
      },
      {
        path: 'instructions',
        component: InstructionsModalComponent,
      },
      {
        path: 'slots',
        component: SlotsComponent,
      },
      {
        path: 'address',
        component: AddressComponent,
      },
      {
        path: 'services/landing',
        component: LandingComponent,
      },
      {
        path: 'services',
        component: ServicesComponent,
      },
      {
        path: 'services/service',
        component: ServiceComponent,
      },
      {
        path: 'address/donate',
        component: DonateModalComponent,
      },
      {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'authorisation',
        component: AuthorisationsComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'choose-slots',
        component: SlotsComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'authorisation-store',
        component: PreferencesStoreComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'summary',
        component: SummaryComponent,
        canActivate: [ AuthGuard ]
      },
      {
        path: 'thank-you',
        component: ThankYouComponent,
        canActivate: [ AuthGuard ]
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BookingRoutingModule { }
