import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { AccountService } from 'src/app/services/user/account.service';

@Component({
  selector: 'user-email-us',
  templateUrl: './email-us.component.html',
  styleUrls: ['./email-us.component.scss']
})
export class EmailUsComponent implements OnInit {
  formSubmitted = false;

  @Output() onEmailSend: EventEmitter<boolean> = new EventEmitter<boolean>();
  error: string;
  emailForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.pattern('^(\\+?\\d{10,14}|\\d{9,12})$')]),
    message: new FormControl('', [Validators.required, Validators.maxLength(1000)])
  });

  constructor(private commandService: CommandService, private alertService: AlertService,private accountService: AccountService) { }

  ngOnInit(): void {
  }

  getFieldFromForm(fieldName: string) {
    return this.emailForm.get(fieldName);
  }

  onFormSubmit() {
    if (!this.formSubmitted) {
      this.formSubmitted = true;
    this.accountService.helpEmail(this.emailForm.value)
    .then(response => this.onEmailSend.emit(true))
    .catch((err) => {
      this.error = err.error.error;
      this.alertService.showError(this.error);
    });
  }
 }
}
