import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Routes } from 'src/app/constants/routes.constant';

import { LogoButtonEnum } from 'src/app/models/shared/enums/logo-button.enum';
import { AuthService } from 'src/app/services/user/auth.service';

@Component({
  selector: 'shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  logoTypeEnum: typeof LogoButtonEnum = LogoButtonEnum;
  isMobile: boolean;
  isAuthenticated: boolean;
  store: string;
  menuDeskSignOut: string= "menu-desk-sign-out";
  signIn : string;
  hideBtn : boolean = false;

  @Input() isBooking?: boolean;
  @Input() signBtnRedirectUrl: string;

  constructor(
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    protected route: ActivatedRoute,
    private router: Router
    
  ) 
  { 
    if(this.router.url == Routes.GUEST.SIGN_IN){
      this.hideBtn = true;
    }
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.isAuthenticated = this.authService.isLoggedIn();
    this.store = this.route.snapshot.queryParams.store;
    
    if(this.router.url == Routes.GUEST.SIGN_IN){
      this.hideBtn = true;
    }
  }

  redirectToSignIn() {
    if (this.signBtnRedirectUrl) {
      this.hideBtn = true;
      this.router.navigate(['/sign-in'], { queryParams: { redirect: this.signBtnRedirectUrl }});
      return;
    }

    this.router.navigate(['/sign-in']);
  }

  signOut(): void {
    this.authService.signout();
    localStorage.clear();
    
  }
}
