import { logging } from 'protractor';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PauseModalComponent } from '../pause-modal/pause-modal.component';
import { CancelModalComponent } from '../cancel-modal/cancel-modal.component';

import { RecurringService } from 'src/app/services/user/recurring.service';
import { Recurring } from 'src/app/models/user/recurring.model';
import { ReccurenceStateEnum } from 'src/app/models/user/enums/recurrence-state.enum';
import { ReccurenceTypeEnum } from 'src/app/models/user/enums/recurrence-type.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { InfoCustomer } from 'src/app/models/user/info-customer.model';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'user-recurring-view',
  templateUrl: './recurring-view.component.html',
  styleUrls: [
    './../recurring.component.scss',
    './recurring-view.component.scss',
  ],
})
export class RecurringViewComponent implements OnInit {
  @Input() isDesktop: boolean = false;
  isLoading: boolean = true;

  //isLoading: boolean = true;
  defaultDate: string = '0001-01-01';
  typeEnum: typeof ReccurenceTypeEnum = ReccurenceTypeEnum;
  recurring: Recurring;
  infocus: InfoCustomer;
  days: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  data: any;
  time: string;
  time2: string;
  PauseDateFrom: any;
  PauseDateTo:any;
  pickupDayAsString: string;
  pickupDayAsString2: string;
  state: ReccurenceStateEnum = ReccurenceStateEnum.Loading;
  isActive = true;
  loading = false;
  twice: boolean = false;
  pause: boolean = false;
  firstPickup: Date;
  firstPickup2: Date;
  customerDetail: any;
  passed_date: boolean;
  date1: Date = new Date();
  isMobile: boolean;
  constructor(
    private recurringService: RecurringService,
    private commandService: CommandService,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService
  ) {
      this.isMobile = this.deviceService.isMobile();
    }
  async ngOnInit() {
    this.state = ReccurenceStateEnum.View;
    this.recurringService.$recurring.subscribe((value) => {
      this.recurring = value;
    });
    await this.commandService
      .executeCommand(CommandEnum.CustomerDetail)
      .then((response) => {
        this.data = response.ReturnObject.infoCustomer;
        this.customerDetail = response.ReturnObject;
        this.PauseDateFrom = this.data.PauseDateFrom;
        this.PauseDateTo  = this.data.PauseDateTo ;
        this.passed_date = moment(this.date1).isAfter(this.PauseDateTo);
        //this.recurringService.setRecurring(response.ReturnObject.infoCustomer);
        if (this.data.DeliveryTu != '') {
          this.time = this.data.DeliveryTu;
          this.pickupDayAsString = this.days[1];
        }
        if (this.data.DeliveryTh != '') {
          this.time = this.data.DeliveryTh;
          this.pickupDayAsString = this.days[3];
        }
        if (this.data.DeliveryMon != '') {
          this.time = this.data.DeliveryMon;
          this.pickupDayAsString = this.days[0];
        }
        if (this.data.DeliveryWed != '') {
          this.time = this.data.DeliveryWed;
          this.pickupDayAsString = this.days[2];
        }
        if (this.data.DeliverySat != '') {
          this.time = this.data.DeliverySat;
          this.pickupDayAsString = this.days[5];
        }
        if (this.data.DeliveryFri != '') {
          this.time = this.data.DeliveryFri;
          this.pickupDayAsString = this.days[4];
        }
      
        if (this.data.DeliveryMon != '' && this.data.DeliveryTh != '') {
          this.time = this.data.DeliveryMon;
          this.time2 = this.data.DeliveryTh;
          this.twice = true;
          this.pickupDayAsString = this.days[0];
          this.pickupDayAsString2 = this.days[3];
        }
        if (this.data.DeliveryTu != '' && this.data.DeliveryFri != '') {
          this.time = this.data.DeliveryTu;
          this.time2 = this.data.DeliveryFri;
          this.twice = true;
          this.pickupDayAsString = this.days[1];
          this.pickupDayAsString2 = this.days[4];
        }
        if (this.data.DeliveryWed != '' && this.data.DeliverySat != '') {
          this.time = this.data.DeliveryWed;
          this.time2 = this.data.DeliverySat;
          this.twice = true;
          this.pickupDayAsString = this.days[2];
          this.pickupDayAsString2 = this.days[5];
        }

        if ( this.PauseDateFrom && this.PauseDateTo ) {
             if(this.getNextWeekday(this.recurring.pickupDay) > new Date(this.recurring.pauseStart) && this.getNextWeekday(this.recurring.pickupDay) < new Date(this.recurring.pauseEnd)){ 
              
              this.recurring.pause = true;
            }else {
              
              this.recurring.pause = false;
            }
        }
        
        if ( this.data.PauseDateFrom != null && ( new Date(this.data.PauseDateFrom) > new Date()) ) {
          
          this.recurring.hasPause = true;
          this.pause = true;
        }
        this.isLoading = false;
      })
      .catch((error) => {
        this.commandService.handleCommandTokenError(error);
      });
  }

  edit() {
    this.recurringService.setState(ReccurenceStateEnum.Form);
  }

  async resume() {
    this.loading = true;
    this.customerDetail.infoCustomer.PauseDateFrom = null;
    this.customerDetail.infoCustomer.PauseDateTo = null;
    // this.recurringService.setRecurring(this.recurring);
    this.commandService
      .executeUpdate(CommandEnum.SaveCustomer, this.customerDetail)
      .then(() => {

        this.recurringService.setState(ReccurenceStateEnum.View);
        location.reload();
      })
      .catch((error) => {
        this.commandService.handleCommandTokenError(error);
      });
  }

  openPauseDialog() {
   const dialog =  this.dialog.open(PauseModalComponent);
   dialog.afterClosed().subscribe(() => {
      location.reload();
    });
  }

  openCancelDialog() {
    this.dialog.open(CancelModalComponent);
  }
  getNextWeekday(day) {
      let daysToAdd = (day - new Date().getDay() + 7) % 7;
      let date = new Date();
      return new Date(date.setDate(date.getDate() + daysToAdd ));
  }
}
