<ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
<booking-progress-bar-mobile [step]=step></booking-progress-bar-mobile>
    <div class="content sign-up animation-fade-in">
        <header>
            <shared-logo-button [logoType]="logoTypeEnum.LogoGreen"></shared-logo-button>
        </header>

        <div class="wrapper">
            <div class="heading">
                <!-- <shared-back-button></shared-back-button> -->
                <h1>Welcome</h1>
                <p>Let's get the key details for your booking</p>
            </div>

            <form [formGroup]="signupForm" (ngSubmit)="onFormSubmit()">
                <div class="field-wrapper name">
                    <label>First Name</label>
                    <mat-form-field floatLabel="never">
                        <input matInput formControlName="firstName">
                        <mat-error *ngIf="getFieldFromForm('firstName').hasError('required')">
                            Name is required
                        </mat-error>

                        <mat-error *ngIf="getFieldFromForm('firstName').hasError('maxlength')">
                            Name can't be more than 30 symbols
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="field-wrapper surname">
                    <label>Surname</label>
                    <mat-form-field floatLabel="never">
                        <input matInput formControlName="lastName">
                        <mat-error *ngIf="getFieldFromForm('lastName').hasError('required')">
                            Surname is required
                        </mat-error>

                        <mat-error *ngIf="getFieldFromForm('lastName').hasError('maxlength')">
                            Surname can't be more than 30 symbols
                        </mat-error>
                    </mat-form-field>
                </div>

                 <div class="bloc-phone">
                        <div class="field-wrapper code">
                            <shared-country-code-picker (onPick)="setCountryCodeValue($event)"></shared-country-code-picker>
                            <mat-error *ngIf="getFieldFromForm('countryCode').value =='' && getFieldFromForm('phone').touched">
                                Country code is required
                            </mat-error>  
                        </div>
                        <div class="field-wrapper phone">
                            <label>Mobile phone</label>
                            <mat-form-field floatLabel="never">
                                <input matInput formControlName="phone" type="tel"  inputmode="numeric">
                                <mat-error *ngIf="getFieldFromForm('phone').hasError('required')">
                                    Mobile phone is required
                                </mat-error>
    
                                <mat-error *ngIf="getFieldFromForm('phone').hasError('pattern')">
                                    Mobile phone must be between 9 and 12 digits
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                <div class="field-wrapper email">
                    <label>Email</label>
                    <mat-form-field floatLabel="never">
                        <input matInput type="email" formControlName="email">
                        <mat-error *ngIf="getFieldFromForm('email').hasError('email') && !getFieldFromForm('email').hasError('required')">
                            Please enter a valid email address
                        </mat-error>

                        <mat-error *ngIf="getFieldFromForm('email').hasError('required')">
                            Email is required
                        </mat-error>
                    </mat-form-field>

                    <span class="note">If you have used our services before but don’t have an online account yet, please use the same email address you used in store.</span>
                </div>

                <div class="field-wrapper password-wrapper">
                    <label>Create password</label>
                    <mat-form-field floatPlaceholder="never" floatLabel="never">
                        <input matInput formControlName="password" [type]="isPasswordVisible ? 'text' : 'password'" name="password" autocomplete="current-password">
                        <mat-error *ngIf="getFieldFromForm('password').hasError('required')">
                            Password is required
                        </mat-error>
                        <mat-error *ngIf="getFieldFromForm('password').hasError('minlength')">
                            Password must be at least 6 symbols
                        </mat-error>
                    </mat-form-field>

                    <a (click)="showPassword()" [className]="isPasswordVisible ? 'shown' : '' "></a>
                </div>
                <div class="margin-wrapper" >
                    <span>Already have an account? </span>
                    <div class="btn-href-wrapper">
                        <a class="btn href smallest green" (click)="redirectToLogin()"> Login</a>
                    </div>
                </div>
                <div class="field-wrapper checkboxes">
                    <div class="checkbox-wrapper">
                        <mat-checkbox formControlName="conditions">
                            I accept BLANC’s <a target="_blanc" (click)="openDialogTermsandConditions()">Terms and Conditions</a>
                        </mat-checkbox>
                    </div>

                    <div class="checkbox-wrapper">
                        <mat-checkbox formControlName="PrivacyPolicy">
                            I accept BLANC’s <a target="_blanc" (click)="openDialogPrivacyPolicy()">Privacy Policy</a>
                        </mat-checkbox>
                    </div>

                    <div class="checkbox-wrapper">
                        <mat-checkbox formControlName="newsletter">
                            Join our monthly newsletter to get eco lifestyle inspiration, cleaning tips and exclusive partner offers straight to your inbox!
                        </mat-checkbox>
                    </div>
                </div>

                <div class="error">
                    <div *ngIf="error" class="mat-error">{{error}}</div>
                </div>

                <button [disabled]="!valid && !working || working && !disabled" class="btn primary bottom-fixed with-arrow input btn-resize" type="submit" [id]="SignUpConitnue">
            <ng-container *ngIf="!working">Continue</ng-container>
            <shared-spinner *ngIf="working && !disabled" color="green"></shared-spinner>
          </button>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #isDesktop>
    <booking-progress-bar-desktop [step]="step"></booking-progress-bar-desktop>   

    <div class="content sign-up animation-fade-in" style="margin-top: -378px;">

        <!-- <div class="media">
            <img src="assets/icons/tshirt-stained.svg" alt="blanc tshirt" class="tshirt-img">
        </div> -->

        <div class="wrapper">
            <div class="heading">
                <!-- <shared-back-button></shared-back-button> -->
                <h1>Welcome</h1>
                <p>Let's get the key details for your booking</p>
            </div>

            <form [formGroup]="signupForm" (ngSubmit)="onFormSubmit()" class="box"     style="background-color: #FFf;">
                <div class="left-side">
                    <div class="field-wrapper name">
                        <label>First name</label>
                        <mat-form-field floatLabel="never">
                            <input matInput formControlName="firstName">
                            <mat-error *ngIf="getFieldFromForm('firstName').hasError('required')">
                                Name is required
                            </mat-error>

                            <mat-error *ngIf="getFieldFromForm('firstName').hasError('maxlength')">
                                Name can't be more than 30 symbols
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="field-wrapper surname">
                        <label>Last name</label>
                        <mat-form-field floatLabel="never">
                            <input matInput formControlName="lastName">
                            <mat-error *ngIf="getFieldFromForm('lastName').hasError('required')">
                                Surname is required
                            </mat-error>

                            <mat-error *ngIf="getFieldFromForm('lastName').hasError('maxlength')">
                                Surname can't be more than 30 symbols
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="bloc-phone">
                        <div class="field-wrapper code">
                            <shared-country-code-picker (onPick)="setCountryCodeValue($event)"></shared-country-code-picker>
                            <mat-error *ngIf="getFieldFromForm('countryCode').value =='' && getFieldFromForm('phone').touched">
                                Country code is required
                            </mat-error>  
                        </div>
                        <div class="field-wrapper phone">
                            <label>Mobile phone</label>
                            <mat-form-field floatLabel="never">
                                <input matInput formControlName="phone" type="tel"  inputmode="numeric">
                                <mat-error *ngIf="getFieldFromForm('phone').hasError('required')">
                                    Mobile phone is required
                                </mat-error>
    
                                <mat-error *ngIf="getFieldFromForm('phone').hasError('pattern')">
                                    Mobile phone must be between 9 and 12 digits
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="field-wrapper checkboxes">
                        <div class="checkbox-wrapper">
                            <mat-checkbox formControlName="conditions">
                                I accept BLANC’s <a target="_blanc" (click)="openDialogTermsandConditions()">Terms and Conditions</a>
                            </mat-checkbox>
                        </div>

                        <div class="checkbox-wrapper">
                            <mat-checkbox formControlName="PrivacyPolicy">
                                I accept BLANC’s <a target="_blanc" (click)="openDialogPrivacyPolicy()">Privacy Policy</a>
                            </mat-checkbox>
                        </div>

                        <div class="checkbox-wrapper">
                            <mat-checkbox formControlName="newsletter">
                                Join our monthly newsletter to get eco lifestyle inspiration, cleaning tips and exclusive partner offers straight to your inbox!
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div class="right-side">
                    <div class="field-wrapper email">
                        <label>Email</label>
                        <mat-form-field floatLabel="never">
                            <input matInput type="email" formControlName="email">
                            <mat-error *ngIf="getFieldFromForm('email').hasError('email') && !getFieldFromForm('email').hasError('required')">
                                Please enter a valid email address
                            </mat-error>

                            <mat-error *ngIf="getFieldFromForm('email').hasError('required')">
                                Email is required
                            </mat-error>

                            <mat-error *ngIf="isUsed">
                                Email is used
                            </mat-error>
                        </mat-form-field>

                        <span class="note">If you have used our services before but don’t have an online account yet, please use the same email address you used in store.</span>
                    </div>

                    <div class="field-wrapper password-wrapper">
                        <label>Create password</label>
                        <mat-form-field floatPlaceholder="never" floatLabel="never">
                            <input matInput formControlName="password" [type]="isPasswordVisible ? 'text' : 'password'" name="password" autocomplete="current-password">
                            <mat-error *ngIf="getFieldFromForm('password').hasError('required')">
                                Password is required
                            </mat-error>
                            <mat-error *ngIf="getFieldFromForm('password').hasError('minlength')">
                                Password must be at least 6 symbols
                            </mat-error>
                        </mat-form-field>

                        <a (click)="showPassword()" [className]="isPasswordVisible ? 'shown' : '' "></a>
                    </div>
                    <div class="margin-wrapper" style="margin-left: 28%;">
                        <span>Already have an account? </span>
                        <div class="btn-href-wrapper">
                            <a class="btn href smallest green" (click)="redirectToLogin()"> Login</a>
                        </div>
                    </div>
                    <div class="error">
                        <div *ngIf="error" class="mat-error">{{error}}</div>
                    </div>
                </div>

            <button [disabled]="!valid && !working ||  working && !disabled" class="btn primary absolute" [ngClass]="{'with-arrow': !working}"   type="submit" [id]="SignUpConitnue">
            <ng-container *ngIf="!working">Continue</ng-container>
            <shared-spinner *ngIf="working && !disabled" color="green"></shared-spinner>
          </button>
            </form>
        </div>
    </div>
</ng-template>
