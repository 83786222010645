import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommandResponse } from '../../models/common/command-response.model';
import { CookieService } from '../common/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class PostCodeService {

    constructor(private http: HttpClient, private cookieService: CookieService)
    {}

    verifyPostCode(postCode: string) {
        const body = {
            postCode
        };
        return this.http.post('postCodeVerify', body).toPromise();
    }

    slotsByPeriod(body) {
        body['sessionId'] = this.cookieService.getCookie('blanc_session_id');
        return this.http.post<CommandResponse>('slotsByPeriod', body).toPromise();
    }

    firstAvailableSlot(body) {
        body['sessionId'] = this.cookieService.getCookie('blanc_session_id');
        return this.http.post('firstAvailableSlot', body).toPromise();
    }

    slotsByDay(postCode: string) {
        return this.http.post('slotsByDay', {postCode}).toPromise();
    }

    getHolidays() {
        return this.http.get('getHolidays').toPromise();
    }
    getBanner() {
        return this.http.post('getBanner', {}).toPromise();
    }
}
