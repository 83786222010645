import { SetPasswordNewUserComponent } from './set-password-new-user/set-password-new-user.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { GuestComponent } from './guest.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignComponent } from './sign/sign.component';
import { IsSignedInGuardService } from '../services/guards/is-signed-in-guard.service';
import { SignInUpComponent } from '../booking/sign-in-up/sign-in-up.component';
import { SignUpStoreComponent } from './sign-up-store/sign-up-store.component';
import { SetCodeVerificationNewUserComponent } from './set-code-verification-new-user/set-code-verification-new-user.component';


const routes: Routes = [
  {
    path: '',
    component: GuestComponent,
    children: [
      {
        path: 'welcome',
        component: SignComponent,
        canActivate: [IsSignedInGuardService],
      },
      {
        path: 'signinup',
        component: SignInUpComponent,
      },
      {
        path: 'sign-in',
        component: SignInComponent,
        canActivate: [IsSignedInGuardService],
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
        canActivate: [IsSignedInGuardService],
      },
      {
        path: 'sign-up/:referralCode',
        component: SignUpComponent,
        canActivate: [IsSignedInGuardService],
      },
      {
        path: 'reset-password',
        component: SignInComponent,
        canActivate: [IsSignedInGuardService],
      },
      {
        path: 'confirm-email',
        component: ConfirmEmailComponent,
        canActivate: [IsSignedInGuardService],
      },
      {
        path: 'order-store',
        component: SetPasswordNewUserComponent,
      },
      {
        path: 'code-verification',
        component: SetCodeVerificationNewUserComponent,
      },
      {
        path: 'deliveryonly',
        component: SetPasswordNewUserComponent,
      },
      {
        path: 'payments-info',
        component: SetPasswordNewUserComponent,
      },
      {
        path: 'sign-up-store',
        component: SignUpStoreComponent,
        canActivate: [IsSignedInGuardService],
      },
    ]
  
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GuestRoutingModule { }
