import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DetailsComponent } from '../details.component';
import { CommandService } from 'src/app/services/common/command.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { Routes } from 'src/app/constants/routes.constant';
import { OrderStatusEnum } from 'src/app/models/order/enums/order-status.enum';

@Component({
  selector: 'order-details-mobile',
  templateUrl: './details-mobile.component.html',
  styleUrls: ['./../details.component.scss', './details-mobile.component.scss']
})
export class DetailsMobileComponent extends DetailsComponent implements OnInit {
  orderEditRoute = Routes.ORDER.EDIT;
  statusEnum: typeof OrderStatusEnum = OrderStatusEnum;
  type : string;
  cancelOrdeMobile : string = "order-mobile-cancel-booking";
  orderMobilePickupAndDeliveryDetails : string = "order-mobile-pickup-and-delivery-details";
  
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected commandService: CommandService,
    protected alertService: AlertService,
    protected deviceService: DeviceDetectorService,
  ) {
    super(route, router, commandService, alertService, deviceService)
  }

  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get('type');
    super.initData();
  }

}
