<div class="schedule-container animation-fade-in" [ngClass]="{'disabled': type === stepsEnum.Delivery && !loading }" style="position: relative;">
  <div class="overlay"></div>
  <div class="arrow left-arrow" (click)="moveBackward()"></div>
  <div class="arrow right-arrow" (click)="moveForward()"></div>
  <div class="divider"></div>
  <ng-container *ngIf="type =='pickup' " >
    <div  class="date" *ngFor="let date of dates | paginate:{from: offset, to: offset + daysToShow}; let i = index"
      [style.grid-column-start]="i + 2" [style.grid-column-end]="i + 2">
      {{formatDate(date)}}
    </div>
  </ng-container>
  <ng-container *ngIf="type == stepsEnum.Delivery">
    <div  class="date" *ngFor="let date of dates_delivery | paginate:{from: offset, to: offset + daysToShow}; let i = index"
      [style.grid-column-start]="i + 2" [style.grid-column-end]="i + 2">
      {{formatDate(date)}}
    </div>
  </ng-container>
  <ng-container *ngIf="type =='pickup'">
    <div  class="slots" *ngFor="let day of period.days | paginate:{from: offset, to: offset + daysToShow}; let i = index"
      [style.grid-column-start]="i + 2" [style.grid-column-end]="i + 2">
      <div class="day">
        <div *ngFor="let slot of day.slots" class="slot" [class.available]="slot.available"
          [class.selected]="selected(day.date, slot)" (click)="select(day.date, slot)">
          {{slot.format}}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container  *ngIf="type == stepsEnum.Delivery">
    <div class="slots"
      *ngFor="let day of period_delivery.days | paginate:{from: offset, to: offset + daysToShow}; let i = index"
      [style.grid-column-start]="i + 2" [style.grid-column-end]="i + 2">
      <div class="day">
        <div *ngFor="let slot of day.slots" class="slot" [class.available]="slot.available"
          [class.selected]="selected(day.date, slot)" (click)="select(day.date, slot)">
          {{slot.format}}
        </div>
      </div>
    </div>
  </ng-container>
  <!--_isIos  -->
  <shared-spinner *ngIf="(loadingPickup && loading && check) || (!loadingPickup && !loading && check)" style="height:auto;position: absolute;" class="spinner-wrapper" ></shared-spinner>
</div>
