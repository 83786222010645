import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { Routes } from 'src/app/constants/routes.constant';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
  isMobile: boolean;
  store: string;
  showModal: boolean;
  modalSubscription: Subscription;
  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmModalService: ConfirmModalService,
    public matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
     this.store = this.route.snapshot.queryParams.store;
     this.modalSubscription = this.confirmModalService.$showModal.subscribe((value) => {
      this.showModal = value;
    });
    if (!this.isMobile) {
      this.router.navigate([Routes.USER.AUTHORISATIONS]);
    }
  }
  canDeactivate(): boolean {
    
    if (this.showModal) {
      console.log('bom yes')
      this.matDialog.open(ConfirmModalComponent);
      return false;
    }
    return true;
  } 
  
  ngOnDestroy() {
    this.confirmModalService.setShowModal(false);
    this.confirmModalService.setSubmitForm(false);
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
