<shared-header></shared-header>

<div class="content animation-fade-in">
  <shared-back-button customPath="booking/address"></shared-back-button>
  <header *ngIf="!store && paymentStripe == 'false'">
      <h3>Your payment information</h3>
      <p>Please note that you will not be charged now. We will only charge your card after we have collected your items and inspected them in our Atelier.</p>
      <br>
      <span>You will then be able to access your e-receipt and view your itemised bill.</span>
      <br>
      <br>
      <div class="confirm">
        <span>Voucher codes can be redeemed at checkout, before confirming the booking.</span>
      </div>
  </header>
  <header *ngIf="store && paymentStripe == 'false'">
    <booking-progress-bar-mobile [step]=step></booking-progress-bar-mobile>

    <h3>Your payment information</h3>
    
    <p class="text-store" *ngIf="cardState !== cardStateEnum.ViewCard">Please note that we are a cashless business. We require your card details so you can drop off in store without queuing,
      or organise a home collection & delivery. We do not see your card details.</p>
    <p class="text-store confirm" *ngIf="cardState === cardStateEnum.ViewCard">Your card will only be charged once we have processed your items. We will then send you an e-receipt with an itemised
      bill.</p>
  </header>
  <header *ngIf="paymentStripe == 'true'">
    <!-- <h3>Your payment information</h3> -->
    <h3>Confirm with card</h3>
    <!-- <p>Please enter your card details below.</p> -->
    <p class="confirm" style="color: #5C5C5C;">You won't be charged now, payment will be collected when we start processing your items</p>
    <!-- <p>Please enter your card details below.</p> -->
    <!-- <br>
    <div class="info-payment">
      <img  src="assets/icons/info-green.svg">
      <span>We have moved to Stripe payment system, one of the world’s best and safest payment systems. We need you to enter your details again so they can be safely encrypted with Stripe. For security reasons, we do not have access to your payment details so we cannot do this for you.</span>
    </div> -->
  </header>
  <app-card-wrapper></app-card-wrapper>

  <!-- <div *ngIf="(cardState === cardStateEnum.Default) || (cardState === cardStateEnum.AddCard)|| (cardState !== cardStateEnum.InProcess)" class="bloc-skip" >
      <span class="skip" *ngIf="store" (click)="skipStep()">Skip this step</span>
  </div> -->

  <!-- <div *ngIf="cardState === cardStateEnum.ViewCard" class="bloc-skip" style="bottom: 60px;">
    <span class="skip" *ngIf="store" (click)="skipStep()">Skip this step</span>
  </div> -->

  <ng-container *ngIf="cardState === cardStateEnum.ViewCard">
    <!-- <booking-voucher-field></booking-voucher-field> -->
    <button (click)="submitPaymentInfo()" class="btn primary bottom-fixed with-arrow btn-resize" [id]="Proceed">Proceed</button> 

  </ng-container>
</div>