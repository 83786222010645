import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { TimeSlot } from 'src/app/models/booking/time-slot.model';
import { TimeSlots } from 'src/app/models/booking/time-slots.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { SlotService } from 'src/app/services/booking/slot.service';
import { CommandService } from 'src/app/services/common/command.service';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { BookingBase } from '../booking.base';
import { Subscription } from 'rxjs';
import { Routes } from 'src/app/constants/routes.constant';
import { AddressBase } from '../address/base/address.base';
import { BookingAddressData } from 'src/app/models/booking/address-data.model';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { Constant } from 'src/app/models/common/constant.model';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { EditEnum } from 'src/app/models/booking/enums/edit.enum';

@Component({
  selector: 'booking-slots',
  templateUrl: './slots.component.html',
  styleUrls: ['./slots.component.scss'],
})
export class SlotsComponent extends BookingBase implements OnInit, OnDestroy {
  stepsEnum: typeof BookingStepsEnum = BookingStepsEnum;
  step: BookingStepsEnum;
  isReady: boolean;
  redirectUrl: string;
  redirectUrlAdresse: string;
  redirectUrlPayment: string;
  pickupSlot: TimeSlot;
  deliverySlot: TimeSlot;

  pickupSlotVisibilty: boolean;
  deliverySlotVisibilty: boolean;
  pickupSubscription: Subscription;
  deliveryslotSubscription: Subscription;
  bookingStepsSubscription: Subscription;
  deliverToOptions: Constant[] = [];
  isAuthenticated: boolean;
  isSaving = false;

  orderMobileEditPickupNext : string;
  orderEditPickupNext : string;
 

  orderMobileEditDeliveryNext : string;
  orderEditDeliveryNext : string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bookingStepsService: BookingStepsService,
    private commandService: CommandService,
    private blancCommandService: BlancCommandService,
    private slotService: SlotService,
    protected deviceService: DeviceDetectorService,
    public adresse: AddressBase,
    protected accountSettingService: AccountSettingService,
    protected storageService: StorageService,
    protected particleService: ParticleService,
    protected authService: AuthService,

  ) {
    super(deviceService);
  }
  ngOnDestroy(): void {
    this.slotService.setPickupSlot(null);
    this.slotService.setDeliverySlot(null);
    this.slotService.setDeliveryFromSlot(null);
    this.pickupSubscription.unsubscribe();
    this.deliveryslotSubscription.unsubscribe();
    this.bookingStepsSubscription.unsubscribe();
  }
  async ngOnInit() {
    
    this.isAuthenticated = this.authService.isLoggedIn();
    this.pickupSlotVisibilty = this.slotService.getPickupSlotVisibility();
    this.deliverySlotVisibilty = this.slotService.getDeliverySlotVisibility();
    this.slotService.deliverySlotVisibility.subscribe((slotVisibility) => {
      this.deliverySlotVisibilty = slotVisibility;
    });
    this.isMobile = this.deviceService.isMobile();

    this.initStep();
    await this.initSlots();
    
    this.initRedirectUrl();
    await this.initDeliverToOptions();
    this.slotService.deliverySlot.subscribe(slot => {
      if (slot === null){
        this.isReady = false;
      }else{
        this.isReady = true;
      }
    });

    console.log(this.router.url)
    var firstName = localStorage.getItem('firstName');
    let smsMarketing = localStorage.getItem('smsMarketing')
    if(smsMarketing){
      if(this.router.url.split('/')[2] === 'slots') {
        this.orderEditDeliveryNext ="sms-marketing-slots-next";
      }else{
      this.orderEditPickupNext ="sms-marketing-booking-redirectToSummary-editPickup-next";
      this.orderEditDeliveryNext = "sms-marketing-booking-redirectToSummary-editDelivery-next";
      }
    }else if (firstName ){
      if(this.router.url.split('/')[2] === 'slots?redirect=%2Fbooking%2Fsummary'){
        this.orderEditPickupNext ="returning-user-booking-redirectToSummary-editPickup-next";
        this.orderEditDeliveryNext = "returning-user-booking-redirectToSummary-editDelivery-next";
       }else if (this.router.url.split('/')[2].includes('%2Forder%2Fedit%2Ffinilize')){
        if(this.isMobile){
          this.orderEditPickupNext ="order-mobile-editPickup-next";
          this.orderEditDeliveryNext = "order-mobile-editDelivery-next";
        }else{
          this.orderEditPickupNext ="order-desk-editPickup-next";
          this.orderEditDeliveryNext = "order-desk-editDelivery-next";
        }
        
       }else{
        this.orderEditDeliveryNext ="returning-user-slots-next";
       }
          
        
      
    }else if (this.router.url.split('/')[2] === 'slots?redirect=%2Fbooking%2Fsummary'){
      this.orderEditPickupNext ="first-journey-booking-redirectToSummary-editPickup-next";
      this.orderEditDeliveryNext = "first-journey-booking-redirectToSummary-editDelivery-next";
    }else{
      this.orderEditDeliveryNext = "first-journey-slots-next";
    }
    
  }
  initStep() {
    // TODO: Need better way to handle validation.
    this.bookingStepsSubscription = this.bookingStepsService.step.subscribe(
      (value) => {
        this.step = value;
        if (this.pickupSlotVisibilty && this.deliverySlotVisibilty) {
          this.isReady = this.step === BookingStepsEnum.SlotsSummary;
        } else {
          this.isReady = true;
        }
      }
    );
  }

  async initSlots() {
    

    const response = this.storageService.getObject('order_response');
    if (response && response.DeliveryDate) {
      const deliveryObject = {
        date: response.DeliveryDate,
        from: response.DeliveryTimeSlot.split('-')[0].substr(0, 5),
        to: response.DeliveryTimeSlot.split('-')[1].substr(1, 6),
        ignore : true
      };
      const pickupObject = {
        date: response.PickupDate,
        from: response.PickupTimeSlot.split('-')[0].substr(0, 5),
        to: response.PickupTimeSlot.split('-')[1].substr(1, 6),
        ignore : true
      };
      this.deliverySlot = deliveryObject;
      this.pickupSlot = pickupObject;
    }

    if (this.pickupSlot) {
      this.slotService.setPickupSlot(this.pickupSlot);
    }
    if (this.deliverySlot) {
      this.slotService.setDeliverySlot(this.deliverySlot);
    }
    this.slotService.deliverySlot.subscribe((deliverySlot) => {
      this.deliverySlot = deliverySlot;
    });
    if (this.pickupSlot && this.deliverySlot) {
      this.bookingStepsService.setStep(BookingStepsEnum.SlotsSummary);
    }

    this.pickupSubscription = this.slotService.pickupSlot.subscribe(
      async (slot) => (this.pickupSlot = slot)
    );
    this.deliveryslotSubscription = this.slotService.deliverySlot.subscribe(
      async (slot) => {
        this.deliverySlot = slot;
      }
    );

  }

  async submit() {
    this.isSaving = true;
    const address = await this.adresse.initData();
    if (address && address.addressLine1) {
      if (this.isAuthenticated){
        this.storageService.saveObject('delivery', this.deliverySlot);
        this.storageService.saveObject('pickup', this.pickupSlot);
        if (
          this.route.snapshot.queryParams['redirect'] !==
          '/booking/summary'
          ) {
          if (address.instructions.deliverToName === '') {

            this.accountSettingService
              .GetDeliveryPreferences()
              .subscribe(
                (response: any) => {
                  if (response.ReturnObject) {
                    if (response.ReturnObject.TypeDelivery && response.ReturnObject.TypeDelivery.length > 0) {

                      address.instructions.countryCode =
                        response.ReturnObject.CodeCountry;
                      address.instructions.deliverToName =
                        response.ReturnObject.TypeDelivery;
                      address.instructions.phoneNumber =
                        response.ReturnObject.PhoneNumber;
                      address.instructions.other =
                        response.ReturnObject.OtherInstruction;
                      address.instructions.deliverToId =
                        this.deliverToOptions.find(
                          (option) =>
                            option.name ===
                            address.instructions.deliverToName
                        ).id;
                      this.commandService
                        .executeCommand(
                          CommandEnum.OrderAddressInstructionsSaveCommand,
                          address.instructions
                        )
                        .then((rep) => {
                          this.storageService.saveObject(
                            'adresse',
                            address
                          );
                        })
                        .catch((err) => console.log(err));
                    }
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        }
      }
      this.commandService.executeCommand(CommandEnum.CustomerDetail).then((resp) => {
        this.commandService.executeCommand(CommandEnum.GetCustomerCard )
        .then((response) => {
          if(!response.ReturnObject.card && resp.ReturnObject.infoCustomer.OnAccount == 0 || (response.ReturnObject.card && response.ReturnObject.card?.Actif == '0' && resp.ReturnObject.infoCustomer.OnAccount == 0)){
            this.router.navigate([this.redirectUrlPayment], {
              queryParams: { paymentStripe: 'add' },
            });
          } else {
            this.router.navigate([this.redirectUrl]);
          }
        })
        .catch((err) => console.log(err));
      }).catch((err) => console.log(err));
    } else {
      if (
        this.route.snapshot.queryParams['redirect'] !== '/booking/summary'
      ) {
        if (address.instructions.deliverToName === '') {
          if (this.isAuthenticated){
            this.storageService.saveObject('delivery', this.deliverySlot);
            this.storageService.saveObject('pickup', this.pickupSlot);
            this.accountSettingService.GetDeliveryPreferences().subscribe(
              (response: any) => {
                if (response.ReturnObject) {
                  if (response.ReturnObject.TypeDelivery.length > 0) {
                    address.instructions.countryCode =
                      response.ReturnObject.CodeCountry;
                    address.instructions.deliverToName =
                      response.ReturnObject.TypeDelivery;
                    address.instructions.phoneNumber =
                      response.ReturnObject.PhoneNumber;
                    address.instructions.other =
                      response.ReturnObject.OtherInstruction;
                    address.instructions.deliverToId =
                      this.deliverToOptions.find(
                        (option) =>
                          option.name === address.instructions.deliverToName
                      ).id;
                    this.commandService
                      .executeCommand(
                        CommandEnum.OrderAddressSaveCommand,
                        address
                      )
                      .then((rep) => {
                        this.storageService.saveObject('adresse', address);
                        this.router.navigate([this.redirectUrlAdresse]);
                      })
                      .catch((err) => console.log(err));
                  } else {
                    this.router.navigate([this.redirectUrlAdresse]);
                  }
                }else {
                  this.router.navigate([this.redirectUrlAdresse]);
                }
              },
              (error) => {
                console.log(error);
              }
            );

            this.commandService.executeCommand(CommandEnum.CustomerDetail).then((resp) => {
              if(resp.ReturnObject.infoCustomer.OnAccount == 0){
                console.log("im here");
                this.router.navigate([Routes.BOOKING_REGULAR.SUMMARY]);
              }
            }).catch((err) => console.log(err));
            /////////
            this.commandService.executeCommand(CommandEnum.GetCustomerCard )
              .then((response) => {
            
                
                if(response.ReturnObject.card?.Actif == '0' ){
                  this.router.navigate([this.redirectUrlPayment]);
                }
              
              })
              .catch((err) => console.log(err));

          }else{
            this.storageService.saveObject('delivery', this.deliverySlot);
            this.storageService.saveObject('pickup', this.pickupSlot);
            this.storageService.saveObject('adresse', address);
            this.router.navigate(['/signinup']);
          }
        }
      }

    }
  }

  initRedirectUrl() {
    const redirectQueryParam = this.route.snapshot.queryParams['redirect'];
    this.redirectUrlAdresse = redirectQueryParam
      ? redirectQueryParam
      : Routes.BOOKING_REGULAR.ADDRESS;
    this.redirectUrlPayment = redirectQueryParam
      ? redirectQueryParam
      : Routes.BOOKING_REGULAR.PAYMENT;
    this.redirectUrl = redirectQueryParam
      ? redirectQueryParam
      : Routes.BOOKING_REGULAR.SUMMARY;
  }
  async initDeliverToOptions() {
    const response = await this.particleService
      .getParticle(ParticleTypeEnum.DeliverTo)
      .toPromise();
    this.deliverToOptions = response;
  }

}
