import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { SpinnerComponent } from './spinner/spinner.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { HeaderComponent } from './header/header.component';
import { LogoButtonComponent } from './logo-button/logo-button.component';
import { NavigationMainComponent } from './navigation-main/navigation-main.component';
import { NavigationMainDesktopComponent } from './navigation-main/navigation-main-desktop/navigation-main-desktop.component';
import { NavigationMainMobileComponent } from './navigation-main/navigation-main-mobile/navigation-main-mobile.component';
import { NavigationTopComponent } from './navigation-top/navigation-top.component';
import { CountryCodePickerComponent } from './country-code-picker/country-code-picker.component';
import { PostcodeModalComponent } from './postcode-modal/postcode-modal.component';
import { PersonalInfoModalComponent } from './personal-info-modal/personal-info-modal.component';
import { NotSupportedComponent } from './not-supported/not-supported.component';
import { FooterComponent } from './footer/footer.component';
import { BackgroundRectangleComponent } from './background-rectangle/background-rectangle.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PwaPopupComponent } from './pwa-popup/pwa-popup.component';
import { AddressPopupComponent } from './address-popup/address-popup.component';
import { UpdateReleaseModalComponent } from './update-release/update-release-modal.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckCardModalComponent } from './check-card-modal/check-card-modal.component';

@NgModule({
  declarations: [
    SpinnerComponent,
    BackButtonComponent,
    ConfirmModalComponent,
    HeaderComponent,
    LogoButtonComponent,
    NavigationMainComponent,
    NavigationMainDesktopComponent,
    NavigationMainMobileComponent,
    NavigationTopComponent,
    CountryCodePickerComponent,
    PostcodeModalComponent,
    PersonalInfoModalComponent,
    NotSupportedComponent,
    FooterComponent,
    BackgroundRectangleComponent,
    PwaPopupComponent,
    AddressPopupComponent,
    UpdateReleaseModalComponent,
    CheckCardModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    
  ],
  
  exports: [
    CommonModule,
    SpinnerComponent,
    BackButtonComponent,
    ConfirmModalComponent,
    HeaderComponent,
    LogoButtonComponent,
    NavigationMainComponent,
    NavigationMainDesktopComponent,
    NavigationMainMobileComponent,
    NavigationTopComponent,
    CountryCodePickerComponent,
    PostcodeModalComponent,
    NotSupportedComponent,
    PersonalInfoModalComponent,
    FooterComponent,
    PwaPopupComponent,
    BackgroundRectangleComponent,
    UpdateReleaseModalComponent,
  ],
  
})
export class SharedModule {}
