import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/user/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BookingBase } from '../../booking.base';
import { Routes } from 'src/app/constants/routes.constant';
import { CommandService } from 'src/app/services/common/command.service';
import { CardService } from 'src/app/services/user/card.service';
import { PaymentMethodEnum } from 'src/app/models/booking/enums/payment-method.enum';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CardResponse } from 'src/app/models/user/card-response.model';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { AccountService } from 'src/app/services/user/account.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent extends BookingBase implements OnInit {
  card: CardResponse;
  constructor(
    private router: Router,
    private authService: AuthService,
    public deviceService: DeviceDetectorService,
    public accountService: AccountService,
    protected commandService: CommandService,
    protected cardService: CardService,
  ) {
    super(deviceService);
  }

  ngOnInit(): void {}

  navigateToServices() {
    this.router.navigate([Routes.BOOKING_REGULAR.SERVICES]);
  }

  navigateToNext() {
  
  }

}
