import { PersonalInfoModalComponent } from 'src/app/shared/personal-info-modal/personal-info-modal.component';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { UserRoleEnum } from 'src/app/models/user/enums/user-role.enum';
import { PostcodeModalComponent } from '../../postcode-modal/postcode-modal.component';
import { Routes } from 'src/app/constants/routes.constant';
import { filter } from 'rxjs/operators';
import { BlancCommandService } from 'src/app/services/common/blanc-command.service';
import { AddressPopupComponent } from '../../address-popup/address-popup.component';
import * as moment from 'moment';

@Component({
  selector: 'shared-navigation-main-mobile',
  templateUrl: './navigation-main-mobile.component.html',
  styleUrls: ['./navigation-main-mobile.component.scss'],
})
export class NavigationMainMobileComponent implements OnInit {
  orderListRoute = Routes.ORDER.LIST;
  isLoading: boolean = true;
  isShown: boolean;
  isAdmin: boolean;
  greetings: string;
  currentUrl: string;
  showBadgeNew: boolean;

  menuMobileSignout : string ="menu-mobile-signout";
  menuMobileRecurring: string ="menu-mobile-recurring";
  menuMobilePersonnalInfo : string ="menu-mobile-personnal-info";
  menuMobilePreferences: string ="menu-mobile-preferences";
  menuMobilePayments: string ="menu-mobile-payments";
  menuMobileHelpFeedback: string ="menu-mobile-helf-feedback";
  menuMobileHome : string ="menu-mobile-home";
  menuMobileMyOrders: string ="menu-mobile-my-orders";
  menuMobileBooking: string ="menu-mobile-booking";

  constructor(
    private authService: AuthService,
    private commandService: CommandService,
    private blancCommandService: BlancCommandService,
    private alertService: AlertService,
    private router: Router,
    private accountSettingService: AccountSettingService,
    private storage: StorageService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url
        if (this.currentUrl === '/?showMenu=true') {
          this.initUserInfo();
          this.isShown = true

        }
      });
  }

  async ngOnInit() {
   // if (this.router.url === '/?showMenu=true') { this.isShown = true; }
    this.initUserInfo();
    this.showBadgeNew = this.accountSettingService.getLoginCount() > 3 ? false : true;
    this.isLoading = false;
  }

  initUserInfo() {
    const firstName = this.storage.getObject('firstName');
    if (firstName) {
      this.greetings = `Welcome ${firstName},`;
    } else {
      this.commandService
      .executeCommand(CommandEnum.UserShortDetailsCommand)
      .then((resp) => {
        const user = resp.ReturnObject.infoCustomer;
        this.greetings = `Welcome ${user.FirstName},`;
        this.isAdmin = user?.claims?.role === UserRoleEnum.Admin;
        this.storage.saveObject('firstName', user.FirstName);
        if (resp.ReturnObject.infoCustomer.SignupDateOnline === '2000-01-01') {
          resp.ReturnObject.infoCustomer.SignupDateOnline = moment(new Date()).format('YYYY-MM-DD');
          this.commandService.executeUpdate(CommandEnum.SaveCustomer,resp.ReturnObject)
          .then((resp) => {
          })
        } else {
          console.log(' ')
        }
      });
    }
    this.isLoading = false;
  }

  toggleShown() {
    this.isShown = !this.isShown;
  }

  redirectTo(redirectUrl: string) {
    this.router.navigate([redirectUrl]);
    this.isShown = false;
  }

  redirectToBooking() {
    localStorage.removeItem('order_response');
    this.blancCommandService.executeCommand(CommandEnum.CustomerDetail)
    .then(response => {
      const address = response['ReturnObject']['address'];
      if (address.length !== 0) {
        if (address[0]['postcode'] === '') {
          this.dialog.open(PostcodeModalComponent);
        } else {
          this.storage.savePostcode(address[0]['postcode']);
          this.router.navigate([Routes.BOOKING_REGULAR.PROVISION]);
          this.isShown = false;
        }
      } else {
        this.dialog.open(PostcodeModalComponent);
      }
    });
  }
  redirectToRecurring() {
    this.blancCommandService.executeCommand(CommandEnum.CustomerDetail)
    .then(response => {
      const address = response['ReturnObject']['address'];
      if (address.length !== 0) {
        if (address[0]['postcode'] === '') {
          this.dialog.open(PersonalInfoModalComponent);
        } else {
          this.storage.savePostcode(address[0]['postcode']);
          this.router.navigate([Routes.USER.RECURRING]);
          this.isShown = false;
        }
      } else {
        this.storage.saveObject('fromRecurring','recurring');
        this.dialog.open(PostcodeModalComponent);
      }
    });
  }
  signOut() {
    this.authService.signout();
  }

  updatePreferencePercentage(){
    this.accountSettingService
      .updatePreferencePercentage()
      .then(
        response => {
        },
        (error) => {
        }
      );
  }
  redirectToPayment() {
    this.accountSettingService.getPersonalInfo().subscribe((response) => {
      if (response.postalCode === '') {
        this.dialog.open(AddressPopupComponent);
      } else {
        this.storage.savePostcode(response.postalCode);
        this.router.navigate([Routes.USER.PAYMENTS]);
        this.isShown = false;
      }
    });
  }

}
