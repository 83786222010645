<div class="h-padding v-padding">
  <h1>Order Search</h1>
  <form (ngSubmit)="reset()">
    <ng-container [formGroup]="searchDates">
      <mat-form-field>
        <mat-label>Creation Date</mat-label>
        <input matInput [matDatepicker]="creationDatePicker" formControlName="creationDate">
        <mat-datepicker-toggle matSuffix [for]="creationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #creationDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Collection Date</mat-label>
        <input matInput [matDatepicker]="collectionDatePicker" formControlName="collectionDate">
        <mat-datepicker-toggle matSuffix [for]="collectionDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #collectionDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Delivery Date</mat-label>
        <input matInput [matDatepicker]="deliveryDatePicker" formControlName="deliveryDate">
        <mat-datepicker-toggle matSuffix [for]="deliveryDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #deliveryDatePicker></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <button class="button" type="submit">
      <span *ngIf="!working">Refresh</span>
      <shared-spinner *ngIf="working" color="white"></shared-spinner>
    </button>
  </form>
  <div *ngIf="error" class="mat-error">{{error}}</div>

  <div>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia">
    </mat-form-field>
    <button class="button outline h-padding" style="margin-right: 20px" (click)="filter('CollectionAndDelivery')">Pickup & Delivery</button>
    <button class="button outline h-padding" style="margin-right: 20px" (click)="filter('DeliveryOnly')" >Delivery Only</button>
    <button class="button outline h-padding" (click)="filter('')" >All</button>

    <div class="mat-elevation-z8" style="margin-top:20px">
      <table mat-table #matTable [dataSource]="dataSource" matSort multiTemplateDataRows>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let row"> {{row.order.id}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
          <td mat-cell *matCellDef="let row"> {{row.order.createdAt}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let row"> {{row.order.userName}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Full name </th>
          <td mat-cell *matCellDef="let row"> {{row.user.firstName}} {{row.user.lastName}}</td>
        </ng-container>

        <ng-container matColumnDef="collectionDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Col Date </th>
          <td mat-cell *matCellDef="let row"> {{row.order.collection.slot.date}} </td>
        </ng-container>

        <ng-container matColumnDef="collectionTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Col Time </th>
          <td mat-cell *matCellDef="let row"> {{row.order.collection.slot.from}} </td>
        </ng-container>

        <ng-container matColumnDef="deliveryDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Del Date </th>
          <td mat-cell *matCellDef="let row"> {{row.order.delivery.slot.date}} </td>
        </ng-container>

        <ng-container matColumnDef="deliveryTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Del Time </th>
          <td mat-cell *matCellDef="let row"> {{row.order.delivery.slot.from}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let row"> {{row.order.type == 'DeliveryOnly' ? '-D' : 'PD'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let row"> {{row.order.status}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
            <div class="expanding-row" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
              <div class="expaned h-padding">
                <p class="address" style="white-space: pre;">
                  {{row.address | json}}
                </p>
                <p class="user" style="white-space: pre;">
                  {{row.user | json}}
                </p>
                <p class="order-detail" style="white-space: pre;">
                  {{row.order | json}}
                </p>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row">
        </tr>
        <tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>