// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  testing: true,
  production: true,
  //api: 'https://blancappdev.vpc-direct-service.com/public/api/',
  api: 'https://blancappstage.vpc-direct-service.com/public/api/',
  // api: 'http://127.0.0.1:8000/api/',
  //blancApi: 'https://blancpreprod.vpc-direct-service.com/api',
  blancApi: 'https://blancapistage.vpc-direct-service.com/api',
  spotApi: 'https://api.mydrycleaner.com/q',
  // blancAccountKey: 'APITESTVPCPROD',
  blancAccountKey: 'BLNAPIKEY',
  spotAccountKey: 'BLANCDEPROVENCEUK',
  itemsAdditionalInstructionsMaxLength: 100,
  serverless: {
    defaultKey: '',
    updateUserAddressAndOrderPaymentFunction: '',
    updateOrderInvoiceRelationsFunction: '',
    updateOrderAddressAndUserIdFunction: '',
    blancSignUpAllUsersFunction: '',
    blancSignUpIfNotExistingFunction: ''
  },
  qrCode: 'assets/qrs/app-ios.png',
  postcodeList:"https://cdn.shopify.com/s/files/1/2074/0733/files/postcode_data.json"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
