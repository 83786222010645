import { PwaService } from './../../services/common/pwa.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/user/auth.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { PostcodeModalComponent } from 'src/app/shared/postcode-modal/postcode-modal.component';
import { Routes } from 'src/app/constants/routes.constant';
import { PersonalInfoModalComponent } from 'src/app/shared/personal-info-modal/personal-info-modal.component';
import { NewModalComponent } from '../new-modal/new-modal.component';
import { PwaPopupComponent } from 'src/app/shared/pwa-popup/pwa-popup.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonConstants } from 'src/app/constants/common';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { CookieService } from 'ngx-cookie-service';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { BookingStepsEnum } from 'src/app/models/booking/enums/booking-steps.enum';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { UpdateReleaseModalComponent } from 'src/app/shared/update-release/update-release-modal.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  orderListRoute = Routes.ORDER.LIST;
  isLoading = true;
  // greetings: string;
  percentage: number;
  pendingOrders: any[] = [];
  settingsSubscription: Subscription;
  accountSubscription: Subscription;
  spinner = false;
  isMobile: boolean;
  showBannerRate: boolean;
  paramsRelease : string;
  showUpdateRelease = false;
  version: any;
  email: string;
  check_app: number;
  close_popup_check: number;
  menuMobileYourOrders: string;
  menuMobileNewBooking: string;
  hasCancelledOrder: boolean = false;
  type_stop : string;

  greetings = new BehaviorSubject<string>('');
  constructor(
    private commandService: CommandService,
    private alertService: AlertService,
    private accountSettingService: AccountSettingService,
    private authService: AuthService,
    private storage: StorageService,
    private router: Router,
    public dialog: MatDialog,
    protected particleService: ParticleService,
    private deviceService: DeviceDetectorService,
    public dialogRef: MatDialogRef<PwaPopupComponent>,
    private _pwaService: PwaService,
    protected stepService: BookingStepsService,
    private cookieService: CookieService
  ) { }

  async ngOnInit() {
    //: void
    await this.initGreetings();
    let fulfilledOrderCount;
    this.paramsRelease = localStorage.getItem('version');
    this.storage.removeItem("paged");
    this.storage.removeItem("paged_delivery");
    this.storage.removeItem("selected_pickup_slot");
    this.storage.removeItem("period_pickup");
    this.storage.removeItem("period_delivery");
    this.storage.removeItem("order_response");
    this.storage.removeItem("adresse");
    this.storage.removeItem("delivery");
    this.storage.removeItem("pickup");
    this.storage.removeItem("GarmentInstructionsFull");
    if ((this.accountSettingService.getLoginCount()) <= 1 && sessionStorage.getItem('isLogin') === 'true') {
      this.spinner = true;
      const dialogRef = this.dialog.open(NewModalComponent, {
        panelClass: 'new-modal-desktop-bl',
      });
      dialogRef.afterClosed().subscribe(() => {
        sessionStorage.setItem('isLogin', 'false');
      });
    }
    
    await this.initPendingSettings();

    this.initPendingOrders();
    
    this.isMobile = this.deviceService.isMobile();
    var login_count = localStorage.getItem('login_count'); 
    if (parseInt(login_count) < 2) {
      if( !localStorage.getItem('Show_popup') ){
        this._pwaService.BehaviordeferredPrompt.subscribe(value => {
          if (this.deviceService.isMobile() && !this._pwaService.isOpenedInApp() && 
            ((value === true && this.deviceService.os !== CommonConstants.DEVICES_OS.iOS)
           //|| (value === false && this.deviceService.os === CommonConstants.DEVICES_OS.iOS)
          )) {
            this.dialog.open(PwaPopupComponent);
            localStorage.setItem('Show_popup', 'true');
          }
        })
      }
    } 
    this.commandService.executeCommand(CommandEnum.CustomerDetail)
      .then(data => {
       
        fulfilledOrderCount = data.ReturnObject.infoCustomer.FulfieldOrderCount;
        if ( fulfilledOrderCount == 5 ) {
          this.showBannerRate = true;
        } else if( fulfilledOrderCount == 20 ) {
          this.showBannerRate = true;
        } else {
          this.showBannerRate = false;
        }
      })
    if (this.deviceService.os === CommonConstants.DEVICES_OS.iOS) {
      this.initUpdateReleaseVersion();
    }

    this.menuMobileNewBooking="menu-mobile-new-booking";
    this.menuMobileYourOrders="menu-mobile-your-orders";
    
    this.commandService.executeCommand(CommandEnum.GetLastDriverCancelledOrderForCustomer).then(data => {
      if(data.ReturnObject !== null ){
        const currentDate = new Date();
        // Obtenez la date et l'heure de created_at
        const createdAtDate = new Date(data.ReturnObject.created_at);
        // Définissez la limite à 23:59 du même jour que created_at
        const limitDate = new Date(createdAtDate.getFullYear(), createdAtDate.getMonth(), createdAtDate.getDate(), 23, 59, 0);

        console.log('limitDate',limitDate);
        console.log('currentDate',currentDate);
        console.log(data.ReturnObject)
      
        if (currentDate <= limitDate) {
          this.hasCancelledOrder = true;
          this.type_stop = data.ReturnObject.type_stop ? data.ReturnObject.type_stop  : "";
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

  async initGreetings() {
    await this.commandService
      .executeCommand(CommandEnum.UserShortDetailsCommand)
      .then((resp) => {
        const user = resp.ReturnObject.infoCustomer;
        this.email = user.EmailAddress;
        this.greetings.next('Welcome ' + user.FirstName + ',');
          this.isLoading = false;
      })
      .catch((err) => this.alertService.showError(err.error.error))
      .finally(() => (this.isLoading = false));
  }

  async initPendingSettings() {
    await this.accountSettingService
      .getPercentage(this.cookieService.get('blanc_session_id'))
      .then(    
        (response) => { 
          this.percentage = Number(response?.acceptMarketing);
          this.check_app = Number(response?.check_app);
          this.close_popup_check = Number(response?.close_popup_check);
        },
        (error) => {
        }
      );
  } 

  get hasPendingSettings() {
    return this.percentage < 100;
  }

  initPendingOrders() {
    // TODO: Implement
  }
  get showBannerRateUs() {
    return this.showBannerRate;
   }
  get hasPendingOrders() {
    return this.pendingOrders.length > 0;
  }

  redirectToBooking() {
    localStorage.removeItem('order_response');

    this.settingsSubscription = this.accountSettingService
      .getPersonalInfo()
      .subscribe((response) => {
        const address = response.ReturnObject.address;
        if (address.length !== 0) {
          if (address[0].postcode === '') {
            this.dialog.open(PostcodeModalComponent);
          } else {
            this.stepService.setStep(BookingStepsEnum.Pickup);
            this.storage.savePostcode(response.postalCode);
            this.router.navigate([Routes.BOOKING_REGULAR.PROVISION]);
          }
        } else {
          this.dialog.open(PostcodeModalComponent);
        }
    });
  }

  redirectToRecurring() {
    this.accountSettingService.getPersonalInfo().subscribe((response) => {
      const address = response.ReturnObject.address;
      if (address.length !== 0) {
        if (address[0].postcode === '') {
          this.dialog.open(PersonalInfoModalComponent);
        } else {
          this.storage.savePostcode(response.postalCode);
          this.router.navigate([Routes.USER.RECURRING]);
        }
      } else {
        this.dialog.open(PostcodeModalComponent);
      }
    });
  }

  initUpdateReleaseVersion() {
    let response = this.particleService.getParticle(ParticleTypeEnum.VersionRelease)
      .toPromise()
      .then((data) => { 
        
        this.version = data[0]?.value
        if (!isNaN(parseInt(this.paramsRelease)) && this.version != this.paramsRelease) {
          this.showUpdateRelease = true;
          this.dialog.open(UpdateReleaseModalComponent, {
            disableClose: true,
            data: { 
              version: this.version,
              email: this.email
            }
          });
          
        } else {
          if ((this.check_app == 0) && (this.close_popup_check == 0)) {
            this.showUpdateRelease = true;
            this.dialog.open(UpdateReleaseModalComponent, {
              disableClose: true,
              data: {
                
                email: this.email,
                version: '',
              }
            });
          }
        }
      
    });
    
  }
  
}
