import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EditComponent } from '../edit.component';
import { CommandService } from 'src/app/services/common/command.service';
import { BookingStepsService } from 'src/app/services/booking/booking-steps.service';
import { SlotService } from 'src/app/services/booking/slot.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { ParticleService } from 'src/app/services/particle/particle.service';

@Component({
  selector: 'order-edit-mobile',
  templateUrl: './edit-mobile.component.html',
  styleUrls: ['./../edit.component.scss', './edit-mobile.component.scss']
})
export class EditMobileComponent extends EditComponent implements OnInit {
  //title: string = 'Pick-up & Delivery details';
  isShown: boolean;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected commandService: CommandService,
    protected slotService: SlotService,
    protected stepService: BookingStepsService,
    protected storageService: StorageService,
    protected particleService: ParticleService,
  ) {
    super(router, route, commandService, slotService, stepService, storageService,particleService)
  }

  async ngOnInit() {
    super.ngOnInit();
  }
  toggleShown() {
    this.isShown = !this.isShown;
  }
}
