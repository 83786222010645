import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AccountService } from 'src/app/services/user/account.service';
import { ChangePasswordFieldType } from 'src/app/models/user/enums/change-password-field-type.enum';
import { ChangePasswordRequest } from 'src/app/models/user/change-password-request.model';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { CommandService } from 'src/app/services/common/command.service';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';

@Component({
  selector: 'user-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
  isCurrentPasswordVisible: boolean;
  isNewPasswordVisible: boolean;
  isConfirmPasswordVisible: boolean;
  isMobile: boolean;
  btnCssClasses: string;


  changePasswordForm = new FormGroup({
    currentPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  constructor(
    private accountService: AccountService,
    private router: Router,
    public alertService: AlertService,
    private deviceService: DeviceDetectorService,
    private confirmModalService: ConfirmModalService,
    private commandService: CommandService
  ) { }

  ngOnInit(): void {
    //  this.isMobile = this.deviceService.isMobile();
    // this.btnCssClasses = this.isMobile ? ' small fixed-top' : ' absolute'; 
    /*   this.confirmModalService.$submitForm.subscribe(value => {
     if (value) {
         this.changePassword();
       }
     });*/
    /*  this.changePasswordForm.statusChanges.subscribe((status) => {
        let isValid = status === FormStatusEnum.Valid && this.changePasswordForm.dirty;
        this.confirmModalService.setShowModal(isValid);
      });*/
  }


  changePassword() {
    // let request = new ChangePasswordRequest();
    const passwordField = this.getFieldFromForm(ChangePasswordFieldType.CurrentPassword);
    const passwordconfirmField = this.getFieldFromForm(ChangePasswordFieldType.ConfirmPassword);

    this.commandService
      .executeCommand(CommandEnum.CustomerDetail)
      .then((resp) => {
        var req = {
          currentPassword: this.getFieldFromForm(ChangePasswordFieldType.CurrentPassword).value,
          newPassword: this.getFieldFromForm(ChangePasswordFieldType.NewPassword).value,
        }
        const newPasswordField = this.getFieldFromForm(ChangePasswordFieldType.NewPassword);
        const currentPassword = this.getFieldFromForm(ChangePasswordFieldType.CurrentPassword);

        this.accountService.changePassword(req)
          .then(resp => {
            if (currentPassword.value === newPasswordField.value) {
              passwordconfirmField.setErrors({ 'same': true });
            } else {
              this.alertService.showSuccess('Password changed!');
              this.router.navigate(['/']);
            }
          })
          .catch(err => {
            if (err.error.error == "Current password incorrect") {
              passwordField.setErrors({ 'incorrect': true });
            }
          })

      });
    //this.confirmModalService.setShowModal(false);
  }

  getFieldFromForm(fieldName: ChangePasswordFieldType | string) {
    return this.changePasswordForm.get(fieldName.toString());
  }

  showPassword(password: string): void {
    if (password === 'current') {
      this.isCurrentPasswordVisible = !this.isCurrentPasswordVisible;
    } else if (password === 'new') {
      this.isNewPasswordVisible = !this.isNewPasswordVisible;
    } else {
      this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
    }
  }

  async checkPasswordValidiy() {
    const passwordField = this.getFieldFromForm(ChangePasswordFieldType.CurrentPassword);

    if (passwordField.errors) {
      return;
    }

    const isPasswordValid = await this.accountService.checkPassword(passwordField.value);

    if (!isPasswordValid) {
      passwordField.setErrors({ 'incorrect': true });
      return;
    }

    passwordField.setErrors(null);
  }

  checkPasswordMatching(): void {
    const newPasswordField = this.getFieldFromForm(ChangePasswordFieldType.NewPassword);
    const confirmPasswordField = this.getFieldFromForm(ChangePasswordFieldType.ConfirmPassword);

    if (confirmPasswordField.errors) {
      return;
    }

    if (newPasswordField.value !== confirmPasswordField.value) {
      confirmPasswordField.setErrors({ 'incorrect': true });
      return;
    }

    confirmPasswordField.setErrors(null);
  }


}
