import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BookingServiceAddBtnStateEnum } from 'src/app/models/booking/enums/booking-service-add-btn-state.enum';
import { BookingServiceItemStateEnum } from 'src/app/models/booking/enums/booking-service-item-state.enum';
import { ParticleTypeEnum } from 'src/app/models/particle/enums/particle-type.enum';
import { ParticleItem } from 'src/app/models/particle/particle-item.model';
import { ServiceItem } from 'src/app/models/particle/service-item.model';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { ServiceComponent } from '../service.component';
import { InstructionsListModalComponent } from './instructions-list-modal/instructions-list-modal.component';
import { StorageService } from 'src/app/services/common/storage.service';
import { timingSafeEqual } from 'crypto';

@Component({
  selector: 'booking-service-desktop',
  templateUrl: './service-desktop.component.html',
  styleUrls: ['./service-desktop.component.scss']
})
export class ServiceDesktopComponent extends ServiceComponent {

  items: ParticleItem[] = [];
  addBtnStateEnum: typeof BookingServiceAddBtnStateEnum = BookingServiceAddBtnStateEnum;
  itemStateEnum: typeof BookingServiceItemStateEnum = BookingServiceItemStateEnum;

  @ViewChild("instructionsSelect") instructionsSelectDiv: ElementRef;

  saveInst : string;

  constructor(
    router: Router,
    route: ActivatedRoute,
    particleService: ParticleService,
    dialog: MatDialog,
    commandService: CommandService,
    alertService: AlertService,
    storageService: StorageService,
    deviceDetectorService: DeviceDetectorService
  ) {
    super(router, route, particleService, dialog, commandService, alertService, storageService, deviceDetectorService);
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.addItem = this.addItem.bind(this);

    var firstName = localStorage.getItem('firstName');
    let smsMarketing = localStorage.getItem('smsMarketing')
    if(smsMarketing){
      this.saveInst="sms-marketing-booking-redirectToSummary-editGarmentIns-save";
    }
    else if (firstName){
      this.saveInst="returning-user-booking-redirectToSummary-editGarmentIns-save";
    }else{
      this.saveInst="first-journey-booking-redirectToSummary-editGarmentIns-save";
    }
  }

  removeItem(item: ServiceItem) {
    if (this.serviceModel.items.length === 1) {
      this.serviceModel.areAddAndSaveBtnVisible = false;
    }

    this.serviceModel.items.splice(this.serviceModel.items.indexOf(item), 1);
  }

  updateItemWithBrand(updatedItem: ServiceItem) {
    let item = this.serviceModel.items.find(x => x.selected.id == updatedItem.selected.id)[0];
    item = updatedItem;

    this.changeItemToInstructionsSelectState(item);
  }

  changeItemToInstructionsSelectState(item: ServiceItem) {
    item.selectedState = this.itemStateEnum.ItemWithInstructionsCard;
    item.selectedInstructionIds = [];

    this.setAvailbleInstructionOptionsForItem(item);
    this.setAdditionalInstructionForItem(item);
  }

  addItem(itemToAdd: ParticleItem) {
    if (itemToAdd !== null) {
      let item = new ServiceItem();
      item.selected = itemToAdd;
      item.selectedState = this.itemStateEnum.ItemWithBrandCard;

      this.serviceModel.items.push(item);
      this.serviceModel.addItemBtnState = this.addBtnStateEnum.AddItemBtn;
      this.serviceModel.areAddAndSaveBtnVisible = false;
    }
  }

  setAvailbleInstructionOptionsForItem(item: ServiceItem) {
    this.particleService.getParticle(ParticleTypeEnum.Instruction, item.selected.id).subscribe(
      (response) => {
        item.availableInstructionOptions = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  openInstructionsModal(item: ServiceItem) {
    const dialogRef = this.dialog.open(InstructionsListModalComponent, {
      panelClass: 'instructions-select-modal',
      data: {
        trigger: this.instructionsSelectDiv,
        instructions: item.availableInstructionOptions.filter(i => !item.selectedInstructionIds.includes(i.id)),
        selectedInstructionIds: item.selectedInstructionIds
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.addInstructionsToItem(item);
    });
  }

  setAdditionalInstructionForItem(item: ServiceItem) {
    this.fetchAdditionalInstructions(item.selected)
      .then(additionalInstructions => item.additionalInstructions = additionalInstructions);
  }

  addInstructionsToItem(item: ServiceItem) {
    let selectedInstructions = [];

    for (const instructionId of item.selectedInstructionIds) {
      const instruction = item.availableInstructionOptions.filter(i => i.id === instructionId)[0];
      selectedInstructions.push(instruction);
    }

    item.instructions = selectedInstructions;
    this.serviceModel.areAddAndSaveBtnVisible = true;
  }

  changeAddBtnStateAndItemsViewToFinal() {
    this.serviceModel.addItemBtnState = this.addBtnStateEnum.AddItemCard;
    this.serviceModel.areAddAndSaveBtnVisible = false;

    for (const item of this.serviceModel.items) {
      item.selectedState = this.itemStateEnum.ItemWithEditCard;
    }
  }

  displayItemInstructionsEditState(item: ServiceItem) {
    item.selectedState = this.itemStateEnum.ItemWithInstructionsCard;
    this.setAvailbleInstructionOptionsForItem(item);
  }

  getInstructionNameById(item, instructionId) {
    let instruction = item.availableInstructionOptions?.filter(i => i.id === instructionId)[0];
    return instruction?.name;
  }

  removeInstruction(item: ServiceItem, instructionId) {
    let old = this.serviceModel.items.find(x => x === item);
    old.selectedInstructionIds.splice(old.selectedInstructionIds.indexOf(instructionId), 1);
    old.instructions.splice(old.instructions.indexOf(old.instructions.find(x => x.id === instructionId)), 1);
  }
}
