import { StorageService } from './../../../services/common/storage.service';
import { ParticleService } from './../../../services/particle/particle.service';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { PreferencesBase } from '../preferences.base';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';

@Component({
  selector: 'user-preferences-mobile',
  templateUrl: './preferences-mobile.component.html',
  styleUrls: ['./../preferences.component.scss', './preferences-mobile.component.scss']
})
export class PreferencesMobileComponent extends PreferencesBase {
  title = 'Preferences';
  lessons: boolean;
  public instIsValid: boolean;
  hasInstructions: boolean;
  authChanged:boolean=false;
  constructor(
    accountSettingService: AccountSettingService,
    formBuilder: FormBuilder,
    router: Router,
    public commandService: CommandService,
    confirmModalService: ConfirmModalService,
    authService: AuthService,
    alertService: AlertService,
    public particleService: ParticleService,
    protected storageService: StorageService,
    matDialog: MatDialog,
    public deviceService: DeviceDetectorService
  ) {
    super(accountSettingService, formBuilder, router, commandService,confirmModalService, authService, alertService, particleService, storageService,matDialog,deviceService);
  }
  onAddInstruction() {
    this.lessons = true;
  }
  setOutput(output) {
    this.instIsValid = output.confirmInstruction;
    this.instClear = output.clearInstruction;
  }
  setAuthorisation(output){
    this.authChanged = output.authorisationsFormChanged;
  }
}
