<footer *ngIf="isAuthenticated" style ="position:relative;">
  <ul>
    <li>
      <a href="https://blancliving.co/pages/privacy-policy" target="_blank">Privacy</a>
    </li>
    <li>
      <a href="https://blancliving.co/pages/cookie-policy" target="_blank">Cookies</a>
    </li>
    <li>
      <a href="https://blancliving.co/pages/terms-conditions" target="_blank">T&Cs</a>
    </li>
    <li>
      <a href="https://blancliving.co/pages/faq" target="_blank">FAQ</a>
    </li>
  </ul>
  
  <img *ngIf="isDesktop" class="qr-code" src={{qrCode}} />
  <div class="download-info info">
    <p>Scan to download our iOS app</p>
  </div>
  
  <div class="contact-info info">
    <p class="green">Need to talk to us?</p>
    <p>call +44 (0)20 8004 2630</p>
  </div>
</footer>

<footer *ngIf="!isAuthenticated">
  <ul>
    <li>
      <a href="https://blancliving.co/pages/privacy-policy" target="_blank">Privacy</a>
    </li>
    <li>
      <a href="https://blancliving.co/pages/cookie-policy" target="_blank">Cookies</a>
    </li>
    <li>
      <a href="https://blancliving.co/pages/terms-conditions" target="_blank">T&Cs</a>
    </li>
    <li>
      <a href="https://blancliving.co/pages/faq" target="_blank">FAQ</a>
    </li>
  </ul>

  <img *ngIf="isDesktop" class="qr-code" src={{qrCode}} />
  <div class="download-info info">
    <p>Scan to download our iOS app</p>
  </div>

  <div class="contact-info info">
    <p class="green">Need to talk to us?</p>
    <p>call +44 (0)20 8004 2630</p>
  </div>
</footer>