import { FromQrCodeModalComponent } from './from-qr-code-modal/from-qr-code-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { LogoButtonEnum } from 'src/app/models/shared/enums/logo-button.enum';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss'],
})
export class SignComponent implements OnInit {
  isMobile: boolean;
  logoTypeEnum: typeof LogoButtonEnum = LogoButtonEnum;
  redirectUrl: string;
  redirect_sign: boolean;
  returnUrl: string;
  signIn: string = "returning-welcome-user-sign-in";
  postcodeBtn: string = "enter-your-postcode";

  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,

  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.redirectUrl = this.route.snapshot.queryParams['redirect'];
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    if(this.redirectUrl === "/booking/address" || this.redirectUrl === "/booking/authorisations"|| this.redirectUrl === "/booking/payment"){
      this.redirect_sign = true;
    }
    if (this.route.snapshot.queryParams['qrcode'] === "true") {
      this.dialog.open(FromQrCodeModalComponent);
    }
  }

  goToSignIn() {
    if (this.redirectUrl) {
      this.router.navigate(['/sign-in'], {
        queryParams: { redirect: this.redirectUrl },
      });
    } else if (this.returnUrl) { 
      this.router.navigate(['/sign-in'], {
        queryParams: { redirect: this.returnUrl},
      });
    } else {
      this.router.navigate(['/sign-in']);
    }
  }

  goToSignUp() {
    if (this.redirectUrl) {
      this.router.navigate(['/sign-up'], {
        queryParams: { redirect: this.redirectUrl },
      });
    } else {
      this.router.navigate(['/enter-your-postcode']);
    }
  }
}
