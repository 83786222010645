import { Component, OnInit } from '@angular/core';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { CommandService } from 'src/app/services/common/command.service';

@Component({
  selector: 'app-rate-us-banner',
  templateUrl: './rate-us-banner.component.html',
  styleUrls: ['./rate-us-banner.component.scss']
})
export class RateUsBannerComponent implements OnInit {

  showBanner: boolean = false;
  clickRate: any;
  constructor(
    private commandService: CommandService,
  ) { }

  ngOnInit(): void {
    
  }

}
