import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BookingAddressData } from 'src/app/models/booking/address-data.model';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { AlertService } from 'src/app/services/common/alert.service';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';

import { DonateBase } from '../../base/donate.base';

@Component({
  selector: 'app-donate-modal',
  templateUrl: './donate-modal.component.html',
  styleUrls: ['./donate-modal.component.scss']
})
export class DonateModalComponent extends DonateBase implements OnInit {

  public address: BookingAddressData = new BookingAddressData();
  isMobile: any;
  AddDonate : string;
  constructor(
    public commandService: CommandService,
    private Router: Router,
    public alertService: AlertService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    protected deviceService: DeviceDetectorService

  ) {
    super();
    this.commandService = commandService;
    this.route = route;
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.bagsCount = Number(this.route.snapshot.queryParams.bagsCount);
    this.address = this.storageService.getObject('adresse');

    var firstName = localStorage.getItem('firstName');
    var smsMarketing = localStorage.getItem('smsMarketing');
    if(smsMarketing){
      this.AddDonate="sms-marketing-booking-redirectToSummary-editGarmentIns-add";
    }
    else if (firstName){
      this.AddDonate="returning-user-booking-redirectToSummary-editGarmentIns-add";
    }else{
      this.AddDonate="first-journey-booking-redirectToSummary-editGarmentIns-add";
    }
  }
  

  async AddDonateClothes(bags) {
    this.address.donateBags = bags;
    this.storageService.saveObject('adresse', this.address);
    this.Router.navigate(['/booking/services']);
  }


}
