<div *ngIf="isMobile" class="content">
  <div class="heading">
    <h4>Garment instructions  <a (click)="redirectTo()" class="btn href green before-edit" [id]="EditGarmentIns"><p>Edit</p></a> </h4>
    <a (click)="toggleShown()" class="icon arrow-down" id="toggle-button"></a>
  </div>
  <div class="more" class="more animation-slide-down" [ngClass]="{'shown': isShown}">
   
    <ul *ngIf="services.length > 0 && verif_length" class="services">
      <li *ngFor="let service of services" class="service">
        <div class="heading">
          <p class="name">{{service.parentItem.name}}</p>
          <h2 *ngIf= "service.itemsCount !== 0" class="count" >{{service.itemsCount}}</h2>
          <h2 *ngIf= "service.bagCount !== 0" class="count" >{{service.bagCount}}</h2>
        </div>
        <div class="subheading">
          <p class="left grey-3">Item</p>
          <p class="right grey-3">Instructions</p>
        </div>

        <ul *ngIf="service.items.length > 0" class="items" id="items">
          <li *ngFor="let item of service.items" class="item">
            <div class="left">
              <p class="item-name black">{{item.selected.name}}</p>
              <span *ngIf="item.brand && item.brand.name != ''" class="item-text grey-1">{{item.brand.name}}</span>
            </div>
            <div class="right">
              <ul *ngIf="item.instructions.length > 0" class="instrustions" id="instructions">
                <li *ngFor="let instruction of item.instructions" class="instruction">
                  <p class="instruction-name grey-1">{{instruction.name}}</p>
                </li>
              </ul>
            </div>
            <ul *ngIf="item.additionalInstructions.length > 0" class="instrustions" id="instructions">
              <li *ngFor="let instruction of item.additionalInstructions" class="instruction">
                <p *ngIf="instruction.parsedValue.isChecked == true" class="grey-3">Other Instructions: </p>
                <p class="instruction-name grey-1">{{instruction.parsedValue.text}}</p>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <div *ngIf="services.length == 0 || !verif_length">
      <p>You haven't added any garment instructions.</p>
    </div>
  </div>
</div>


<div *ngIf="!isMobile" class="content">
  <div class="heading">
    <h4>Garment instructions</h4>
    <a (click)="toggleShown()" class="icon arrow-down" id="toggle-button"></a>
  </div>
  <!-- routerLink="/booking/services" [queryParams]="{'redirect': '/booking/summary'}" -->
  <a (click)="redirectTo()"  class="btn href green" [id]="EditGarmentIns"><p>Edit</p></a>
  <div class="more" class="more animation-slide-down" [ngClass]="{'shown': isShown}">
   
    <ul *ngIf="services.length !== 0 && verif_length" class="services">
      <li *ngFor="let service of services" class="service">
        <div class="heading">
          <p class="name">{{service.parentItem.name}}</p>
          <h2 *ngIf= "service.itemsCount !== 0" class="count" >{{service.itemsCount}}</h2>
          <h2 *ngIf= "service.bagCount !== 0" class="count" >{{service.bagCount}}</h2>
        </div>
        <div class="subheading">
          <p class="left grey-3">Item</p>
          <p class="right grey-3">Instructions</p>
          <p class="right grey-3">Other Instructions</p>
        </div>

        <ul *ngIf="service.items.length > 0" class="items" id="items">
          <li *ngFor="let item of service.items" class="item">
            <div class="left">
              <p class="item-name black">{{item.selected.name}}</p>
              <span *ngIf="item.brand && item.brand.name != '' " class="item-text grey-1">{{item.brand.name}}</span>
            </div>
            <div class="right">
              <ul *ngIf="item.instructions.length > 0" class="instrustions" id="instructions">
                <li *ngFor="let instruction of item.instructions" class="instruction">
                  <p class="instruction-name grey-1">{{instruction.name}}</p>
                </li>
              </ul>
            </div>
            <div class="right">
              <ul *ngIf="item.additionalInstructions.length > 0" class="instrustions" id="instructions">
                <li *ngFor="let instruction of item.additionalInstructions" class="instruction">
                  <p class="instruction-name grey-1">{{instruction.parsedValue.text}}</p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <div *ngIf="services.length == 0 || !verif_length">
      <p>You haven't added any garment instructions.</p>
    </div>
  </div>
</div>
