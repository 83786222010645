import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { ErrorPostalModalComponent } from './error-postal-modal/error-postal-modal.component';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';
import { CommandService } from 'src/app/services/common/command.service';
import { PersonalInfo } from 'src/app/models/user/personal-info.model';
import { HttpStatusCode } from 'src/app/models/common/enums/http-status-code';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { FormStatusEnum } from 'src/app/models/common/enums/form-status.enum';
import { ConfirmModalService } from 'src/app/services/shared/confirm-modal.service';
import { Routes } from 'src/app/constants/routes.constant';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/user/account.service';
import { NotSupportedComponent } from 'src/app/shared/not-supported/not-supported.component';
import { StorageService } from 'src/app/services/common/storage.service';
import { PersonalInfoService } from '../personal_info.service';
import { NewModalComponent } from '../../new-modal/new-modal.component';
import { AuthService } from 'src/app/services/user/auth.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { AuthorisationsBase } from '../../authorisations/authorisations.base';
import { Console } from 'console';
import { InfoCustomer } from 'src/app/models/user/info-customer.model';
import { PostCodeService } from 'src/app/services/shared/postCode.service';
import { stringify } from '@angular/compiler/src/util';
import { ftruncate } from 'fs';
import { threadId } from 'worker_threads';


@Component({
  selector: 'user-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  styleUrls: ['./personal-info-form.component.scss']
})
export class PersonalInfoFormComponent implements OnInit, OnDestroy {
  isLoading = true;
  isDesktop: boolean;
  isMobile: boolean;
  personalInfo: PersonalInfo;
  //personalInfoForm: FormGroup;
  error: string;
  isValid: boolean;
  submitSubscription: Subscription;
  accountSubscription: Subscription;
  formStatusSubscription: Subscription;
  isShown = true;
  isSaving = false;
  showBadgeNew: boolean;
  // spinner: boolean = false;
  user: any;
  add: any;
  countryCode: string;
  codeisvalide: boolean = false;
  personalInfoForm = new FormGroup({
    FirstName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    LastName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    EmailAddress: new FormControl('', [Validators.required, Validators.email]),
    address1: new FormControl('', [Validators.maxLength(50)]),
    address2: new FormControl('', [Validators.maxLength(50)]),
    postcode: new FormControl('', [Validators.maxLength(20)]),
    town: new FormControl('', [Validators.maxLength(50)]),
    countryCode: new FormControl('', [Validators.pattern(`^(\\+?\\d{1,4}|\\d{1,5})$`)]),
  });
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountSettingService: AccountSettingService,
    private accountService: AccountService,
    private commandService: CommandService,
    private deviceService: DeviceDetectorService,
    private confirmModalService: ConfirmModalService,
    private storageService: StorageService,
    public dialog: MatDialog,
    public toast: ToastrService,
    private personalInfoService: PersonalInfoService,
    private auth: AuthService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private postCodeService: PostCodeService,

  ) { }

  async ngOnInit() {

    this.isDesktop = this.deviceService.isDesktop();
    //this.isMobile = this.deviceService.isMobile();
    await this.initUserInfo();

    //this.isLoading = false;

    this.submitSubscription = this.confirmModalService.$submitForm
      .subscribe(value => {
        if (value) {
          this.onFormSubmit();
        }
      });

  }

  ngOnDestroy(): void {
    if (this.submitSubscription) {
      this.submitSubscription.unsubscribe();
    }
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.formStatusSubscription) {
      this.formStatusSubscription.unsubscribe();
    }
  }
  setCountryCodeValue(value) {
    const countryCodeField = this.personalInfoForm.get('countryCode');
    countryCodeField.setValue(value);
    countryCodeField.markAsDirty();

    this.isValid = true;
    this.confirmModalService.setShowModal(this.isValid);
  }
  async initUserInfo() {
    const resp = await this.commandService.executeCommand(CommandEnum.CustomerDetail);
    this.user = resp.ReturnObject.infoCustomer;
    this.add = resp.ReturnObject.address;
    const str = this.user.Phone[this.user.Phone.length - 1];

    const strsplit = str.split('|');
    const strsplitsplit = strsplit[0].split('+');

    this.countryCode = strsplitsplit[0];
     this.personalInfoForm = new FormGroup({
      FirstName: new FormControl(this.user.FirstName, [Validators.required, Validators.maxLength(30)]),
      LastName: new FormControl(this.user.LastName, [Validators.required, Validators.maxLength(30)]),
      EmailAddress: new FormControl(this.user.EmailAddress, [Validators.required, Validators.email]),
      Phone: new FormControl(strsplit[1], [Validators.required, Validators.pattern('^ *(?:[0-9] *){9,12}$')]),
      address1: new FormControl('', [Validators.maxLength(50)]),
      address2: new FormControl('', [Validators.maxLength(50)]),
      postcode: new FormControl('', [Validators.required,Validators.maxLength(20)]),
      town: new FormControl('', [Validators.maxLength(50)]),
      countryCode: new FormControl(this.countryCode, [Validators.pattern(`^(\\+?\\d{1,4}|\\d{1,5})$`)]),
    });

    this.personalInfoForm.get('postcode').valueChanges.subscribe(value => {
      if (value.length > 0) {
        this.personalInfoForm.get('address1').setValidators([Validators.required, Validators.maxLength(50)]);
        this.personalInfoForm.get('address1').updateValueAndValidity();

      } else {
        this.personalInfoForm.get('postcode').setValidators([Validators.required, Validators.maxLength(20)]);
        this.personalInfoForm.get('address1').setValidators([Validators.maxLength(50)]);
        this.personalInfoForm.get('address1').updateValueAndValidity();

      }
    });
    this.formStatusSubscription = this.personalInfoForm.statusChanges.subscribe((status) => {
      //console.log(status);
      this.error = null;
      this.isValid = status === FormStatusEnum.Valid && this.personalInfoForm.dirty;
      this.confirmModalService.setShowModal(this.isValid);
    });

    if ( this.add && (this.add.length > 0)) {
      if(this.add[0]){
        if (this.add[0].address1 && this.add[0].address1.length > 0) {
          this.personalInfoForm.get('address1').setValue(this.add[0].address1);
        }
        if (this.add[0].address2 && this.add[0].address2.length > 0) {
          this.personalInfoForm.get('address2').setValue(this.add[0].address2);
        }
        if (this.add[0].Town && this.add[0].Town.length > 0) {
          this.personalInfoForm.get('town').setValue(this.add[0].Town);
        } else {
          this.personalInfoForm.get('town').setValue('London');
        }
        if (this.add[0].postcode && this.add[0].postcode.length > 0) {
          this.personalInfoForm.get('postcode').setValue(this.add[0].postcode)
        }
      } else {
        console.log('bug')
      }
    }

    this.isLoading = false;
  }

  getFormField(fieldName: string) {
    return this.personalInfoForm.get(fieldName.toString());
  }

  async verifyPostCode() {
    const pst = await this.postCodeService.verifyPostCode(this.personalInfoForm.get('postcode').value)
      .then(resp => {
        this.codeisvalide = true;
      })
      .catch(err => {
        if (err.status === HttpStatusCode.BadRequest) {
          this.codeisvalide = false;
          this.personalInfoForm.get('postcode').setErrors({ unsupported: true });
          const dialogRef = this.dialog.open(NotSupportedComponent, {
            data: {
              postcode: this.personalInfoForm.get('postcode').value,
              isInIframe: false
            }
          });
          dialogRef.afterClosed().subscribe(() => {
            this.isSaving = false;
          });
        }
      });
  }

  async onFormSubmit() {
    // let codecountry = this.personalInfoForm.get('countryCode');
    this.isSaving = true;
    const phone = [];
    let codecountry;
    const customerDetails = await this.commandService.executeCommand(CommandEnum.CustomerDetail);
    let verifying
    // if (this.personalInfoForm.get('postcode').value) {
      verifying = await this.verifyPostCode();
    // } else {
    //   this.codeisvalide = true;
    // }
    if ((this.personalInfoForm.get('postcode').value.length === 0) &&
      (this.personalInfoForm.get('address1').value.length === 0) &&
      (this.personalInfoForm.get('address2').value.length === 0)) {
      customerDetails.ReturnObject.address = [];
    }
    else {
      const T = [{
        AddressID: '',
        address1: this.personalInfoForm.get('address1').value,
        address2: this.personalInfoForm.get('address2').value,
        postcode: this.personalInfoForm.get('postcode').value,
        longitude: '',
        Latitude: '',
        County: 'London',
        Country: 'GB',
        Town: this.personalInfoForm.get('town').value.length !== 0 ? this.personalInfoForm.get('town').value : 'London',
        name: ''
      }];
      customerDetails.ReturnObject.address = T;
    }
    codecountry = this.personalInfoForm.get('countryCode').value.split('+');

    customerDetails.ReturnObject.infoCustomer.FirstName = this.personalInfoForm.get('FirstName').value;
    customerDetails.ReturnObject.infoCustomer.LastName = this.personalInfoForm.get('LastName').value;
    if (codecountry[0].length === 0) {
      phone.push(codecountry[1] + '|' + this.personalInfoForm.get('Phone').value);
    }
    else {
      phone.push(codecountry[0] + '|' + this.personalInfoForm.get('Phone').value);
    }
    customerDetails.ReturnObject.infoCustomer.Phone = phone;

    if (this.codeisvalide === true) {
      this.commandService.executeUpdate(CommandEnum.SaveCustomer, customerDetails.ReturnObject).then((resp) => {
        this.storageService.saveObject('firstName', this.personalInfoForm.get('FirstName').value);
        this.isSaving = false;
        this.isLoading = false;
        this.confirmModalService.setShowModal(false);
        this.navigateNext();
      })
      .catch((error) => {
        this.isSaving = false;
        this.isLoading = false;
        this.confirmModalService.setShowModal(false);
      });
    }

  }

  navigateNext() {
    const redirectUrl = this.route.snapshot.queryParams.redirect;

    if (redirectUrl) {
      if (redirectUrl === Routes.USER.PERSONAL_INFO){
        window.location.href = Routes.USER.PERSONAL_INFO;
      } else {
        this.router.navigate([redirectUrl]);
      }
    } else {
      this.router.navigate(['/'], { queryParams: { showMenu: true } })
    }
  }

}
