import { AuthService } from './../../../../services/user/auth.service';
import { FormBuilder } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { InstructionsBase } from '../../base/instructions.base';
import { ParticleService } from 'src/app/services/particle/particle.service';
import { Instructions } from 'src/app/models/booking/instructions.model';
import { CommandService } from 'src/app/services/common/command.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { SaveInstructionModalComponent } from '../../save-instruction-modal/save-instruction-modal.component';
import { CommandEnum } from 'src/app/models/common/enums/command.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountSettingService } from 'src/app/services/user/account-setting.service';


@Component({
  selector: 'booking-address-instructions-desktop',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss'],
})
export class InstructionsComponent extends InstructionsBase implements OnInit {
  isFormShown: boolean;
  isSummaryShown: boolean;
  redirectUrl: string;
  instClear: boolean = false;
  code: string;
  store: string;
  @Input() instructions: Instructions;
  @Output() onInstructionsSubmit: EventEmitter<Instructions> = new EventEmitter<Instructions>();

  saveAddressInstructions: string;

  constructor(
    public accountSettingService: AccountSettingService,
    particleService: ParticleService,
    storageService: StorageService,
    commandService: CommandService,
    public dialog: MatDialog,
    protected router: Router,
    public formBuilder: FormBuilder,
    protected authService: AuthService,
    public route: ActivatedRoute,

  ) {
    super(accountSettingService, particleService, commandService, storageService, router, formBuilder, authService);
  }

  async ngOnInit() {
   
    this.store = this.route.snapshot.queryParams.store;
    await super.ngOnInit();
    setTimeout(() => {
      this.showOrHideSummary();
    }, 1000);
    this.code = this.address.instructions.countryCode.split('+')[1];
    let verify = localStorage.getItem('first-journey');
    let smsMarketing = localStorage.getItem('smsMarketing');
    if(verify === 'true'){
      if (this.router.url === '/booking/address'){
        this.saveAddressInstructions ="first-journey-booking-Address-save";
      }else{
        this.saveAddressInstructions ="first-journey-booking-redirectToSummary-editInstructions-save";
      }
      
    }else if( smsMarketing){
      this.saveAddressInstructions ="sms-marketing-booking-redirectToSummary-editInstructions-save";
    }else{
      this.saveAddressInstructions ="returning-user-booking-redirectToSummary-editInstructions-save";
    }

   
  }

  toggleShown(): void {
    this.isFormShown = !this.isFormShown;
    this.showOrHideSummary();
  }

  showOrHideSummary(): void {
    if (this.isFormShown) {
      this.isSummaryShown = false;
      return;
    }

    if (this.hasInstructions && this.isValid) {
      this.isSummaryShown = true;
      return;
    }

    if (!this.hasInstructions) {
      this.isSummaryShown = false;
      return;
    }

    // this.isSummaryShown = true;
  }

  submitForm(): void {

    if (!this.isValid) {
      return;
    }
    this.instructions = this.instructionsForm.value;
    this.instructions.deliverToName = this.deliverToOptions.find(option => option.id === this.instructions.deliverToId).name;
    if (this.store) {
      this.openInstructionsDialogStore(this.instructions);
    } else {
      this.openInstructionsDialog(this.instructions);
    }
    this.isFormShown = false;
    this.emitInstructions();
      // if order only
    this.address.instructions = this.instructions;
    this.storageService.saveObject('adresse', this.address);
    this.ngOnInit();
    this.isSummaryShown = true;
    
  }

  openInstructionsDialog(instructions) {
    this.isFormShown = false;
    this.emitInstructions();
    const dialogRef = this.dialog.open(SaveInstructionModalComponent, {
      data: instructions
    });
    dialogRef.afterClosed().subscribe((preference) => {
      // if preference
      if (preference) {
        this.storageService.saveString('Instruction' , 'PREFERENCES');
        const data = {
          TypeDelivery: this.instructions.deliverToName,
          CodeCountry: this.instructions.countryCode,
          PhoneNumber: this.instructions.phoneNumber,
          OtherInstruction: this.instructions.other,
        };
        if (this.isAuthenticated){
          this.accountSettingService.updateDeliveryPreferences(data).then(
            (response) => {
              super.ngOnInit();
            },
            (error) => {
            }
          );
        }
      }
      // if order only
      this.address.instructions = this.instructions;
      this.storageService.saveObject('adresse', this.address);
      const data = {
        TypeDelivery: this.instructions.deliverToName,
        CodeCountry: this.instructions.countryCode,
        PhoneNumber: this.instructions.phoneNumber,
        OtherInstruction: this.instructions.other,
      };
      this.ngOnInit();
     // this.accountSettingService.updateDeliveryPreferences(data);
      this.isSummaryShown = true;
    });
  }

  openInstructionsDialogStore(instructions) {
    if (instructions) {
        this.storageService.saveString('Instruction' , 'PREFERENCES');
        const data = {
          TypeDelivery: this.instructions.deliverToName,
          CodeCountry: this.instructions.countryCode,
          PhoneNumber: this.instructions.phoneNumber,
          OtherInstruction: this.instructions.other,
        };
        if (this.isAuthenticated){
          this.accountSettingService.updateDeliveryPreferences(data).then(
            (response) => {
              super.ngOnInit();
              
            },
            (error) => {
            }
          );
        }
      }
  }
  
  clearInstruction() {
    this.instructions = new Instructions();
    this.address.instructions = new Instructions();
    this.commandService
      .executeCommand(CommandEnum.OrderAddressSaveCommand, this.address)
      .then((response) => {
        this.storageService.saveObject('adresse', this.address);
        this.ngOnInit();
      })
      .catch((error) => {
        this.commandService.handleCommandTokenError(error);
      });
    this.instClear = true;
    this.isSummaryShown = false;
}


  emitInstructions(): void {
    this.onInstructionsSubmit.emit(this.instructions);
  }
}
