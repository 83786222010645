<ng-template [ngIf]="isMobile" [ngIfElse]="isDesktop">
    <ng-container [ngSwitch]="step">
        <div *ngSwitchCase="stepsEnum.Pickup" class="box not-selected animation-fade-in" id="not-selected">
            <div class="heading">
                <p>Please choose a pick up slot</p>
                <span>Our driver will pick up your bag of dry cleaning, tailoring and/or wash & fold.</span>
            </div>
            <booking-schedule [type]="slotType">
            </booking-schedule>
        </div>
        <div *ngSwitchCase="7" class="box not-selected animation-fade-in" id="not-selected">
            <div class="heading">
                <p>Please choose a pick up slot</p>
                <span>Our driver will pick up your bag of dry cleaning, tailoring and/or wash & fold.</span>
            </div>
            <booking-schedule [type]="slotType">
            </booking-schedule>
        </div>
        <div *ngSwitchDefault id="selected" class="box selected animation-fade-in">
            <div class="heading">
                <p>Your pick up slot </p>
                <div (click)="edit()" id="edit" class="btn href green">Edit</div>
            </div>
            <div class="selected-slot">
                <p class="date">{{pickupSlot?.date | toMoment | dateFormat:dateFormat}}</p>
                <p class="hours">{{pickupSlot?.from | hourFormatPms}} - {{pickupSlot?.to | hourFormatPms}}</p>
            </div>
        </div>
    </ng-container>
</ng-template>


<ng-template #isDesktop>
    <div id="not-selected" class="box not-selected animation-fade-in">
        <div class="heading">
            <p>Please choose a pick up slot</p>
            <span>Our driver will pick up your bag of dry cleaning, tailoring and/or wash & fold.</span>
        </div>
        <booking-schedule [type]="slotType">
        </booking-schedule>
    </div>
</ng-template>
