<shared-header></shared-header>

<div class="content services-mobile animation-fade-in">
    <shared-back-button [customPath]="backRoute"></shared-back-button>

    <ul>
        <li *ngFor="let service of services" class="box" [ngClass]="{'has-count' : getCount(service) > 0}">
            <div class="description">
                <img src="assets/icons/{{service.parsedValue.css}}.svg" alt="service">
                <div class="text">
                    <h2>{{service.name}}</h2>
                    <p>{{service.parsedValue.priceDescription}}</p>
                    <span>{{service.parsedValue.description}}</span>
                </div>
            </div>
            <div *ngIf="getCount(service) === 0" class="actions empty">
                <a [routerLink]="['/booking/services/service']" [queryParams]="{id: service.id}" queryParamsHandling="merge" [id]="AddInst" class="btn secondary secondary-neutral">Add instructions</a>
            </div>
            <div *ngIf="getCount(service) > 0" class="actions">
                <div class="edit">
                    <p>{{getText(service)}}</p>
                    <a [routerLink]="['/booking/services/service']" [queryParams]="{id: service.id}" queryParamsHandling="merge" class="btn href green" [id]="EditInst">Edit</a>
                </div>
            </div>
        </li>
        <li class="box" [ngClass]="{'has-bags' : address.donateBags > 0}" *ngIf="show_donate">
            <div class="donate">
                <div class="description">
                    <div class="media">
                        <img src="assets/icons/donate-blue.svg">
                    </div>
                    <div class="text">
                        <p>Donate clothes </p>
                        <p>to charity</p>
                    </div>
                </div>
                <div *ngIf="donateBags=== 0" class="actions empty">
                    <a [routerLink]="['/booking/address/donate']" [queryParams]="{bagsCount: donateBags}" [id]="AddDonation" class="btn secondary secondary-neutral donate-button">Add donation bag</a>
                </div>
                <div *ngIf="donateBags > 0" class="actions">
                    <div class="edit">
                        <p>{{address.donateBags}} donation bag(s) added</p>
                        <a [routerLink]="['/booking/address/donate']" [queryParams]="{bagsCount: donateBags}" class="btn href donate-blue">Edit</a>
                    </div>
                </div>
            </div>
        </li>
        <button (click)="navigateToNext()" [disabled]="isSaving" class="btn primary bottom-fixed with-arrow btn-resize" [id]="saveInstNext" *ngIf="show_donate" > 
            <ng-container *ngIf="!isSaving">Next</ng-container>
            <shared-spinner *ngIf="isSaving" color="white"></shared-spinner>
        </button>
    </ul>

</div>